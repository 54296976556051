import React from "react";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import $ from "jquery";
import {Helpers} from "../../../../config/Helpers";
import {toast} from "react-toastify";
import Select from 'react-select';
import {Connection} from "../../../../config/Connection";

class JenisInventaris extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],

            id: '',
            kode_jenis_inventaris: '',
            deskripsi_jenis_inventaris: '',
            umur_ekonomis: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getData();
        }
    }

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'kode-jenis-inventaris?limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataSource: res.data
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    selectData = (obj) => {
        this.setState({
            id: obj.id,
            kode_jenis_inventaris: obj.kode_jenis_inventaris,
            deskripsi_jenis_inventaris: obj.deskripsi,
            umur_ekonomis: obj.umur_ekonomis
        });
    };

    deleteData = (obj) => {
        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + 'kode-jenis-inventaris/'+obj.id, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            }
        }).then((res) => res.json()).then((json) => {
            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });

        this.getData();
    };

    submitData = () => {
        if(this.state.id){
            this.saveData('PATCH','kode-jenis-inventaris/'+this.state.id);
        }else{
            this.saveData('POST','kode-jenis-inventaris');
        }
    };

    clearData = () => {
        this.setState({
            id: '',
            kode_jenis_inventaris: '',
            deskripsi_jenis_inventaris: '',
            umur_ekonomis: ''
        });
    };

    saveData = (method,endpoint) => {
        let urlencoded = new URLSearchParams();
        urlencoded.append("kode_jenis_inventaris", this.state.kode_jenis_inventaris);
        urlencoded.append("deskripsi_jenis_inventaris", this.state.deskripsi_jenis_inventaris);
        urlencoded.append("umur_ekonomis", this.state.umur_ekonomis);
        urlencoded.append("kode_perk", "");
        urlencoded.append("kode_perk_penyusutan", "");
        urlencoded.append("kode_perk_pendapatan", "");
        urlencoded.append("kode_perk_biaya", "");
        urlencoded.append("kode_perk_biaya_kerugian", "");

        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => res.json()).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        toast.success(Helpers.MSG_SUCCESS);

                        this.getData();
                        this.clearData();
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Akuntansi</li>
                                        <li className="breadcrumb-item">Inventaris</li>
                                        <li className="breadcrumb-item">Setting</li>
                                        <li className="breadcrumb-item active">Jenis Inventaris</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Jenis Inventaris</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3">
                                    <div className="mb-1">
                                        <label className="form-label">Kode</label>
                                        <input type="text" className="form-control" value={this.state.kode_jenis_inventaris} onChange={e => this.setState({kode_jenis_inventaris: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-1">
                                        <label className="form-label">Deskripsi</label>
                                        <input type="text" className="form-control" value={this.state.deskripsi_jenis_inventaris} onChange={e => this.setState({deskripsi_jenis_inventaris: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="mb-1">
                                        <label className="form-label">Umur Ekonomis (Bulan)</label>
                                        <input type="number" className="form-control" value={this.state.umur_ekonomis} onChange={e => this.setState({umur_ekonomis: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-12"></div>
                                <div className="col-6">
                                    <a className="btn btn-primary waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                        <i className="fe-save me-1"></i> Simpan
                                    </a>
                                    <a className="btn btn-warning waves-effect waves-light mr-5" onClick={() => {this.clearData()}}>
                                        <i className="fe-trash me-1"></i> Bersihkan
                                    </a>
                                </div>
                                <div className="dropdown-divider"></div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'Kode Jenis Inventaris',
                                                        field: 'kode_jenis_inventaris',
                                                    },
                                                    {
                                                        label: 'Deskripsi',
                                                        field: 'deskripsi',
                                                    },
                                                    {
                                                        label: 'Umur Ekonomis (Bulan)',
                                                        field: 'umur_ekonomis',
                                                    },
                                                    {
                                                        label: 'Action',
                                                        field: 'action',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    return {
                                                        ...row,
                                                        action: (
                                                            <div>
                                                                <button type="button" className="btn btn-info btn-rounded btn-sm" onClick={() => {this.selectData(row)}}>
                                                                    Edit
                                                                </button>
                                                                <button type="button" className="btn btn-danger btn-rounded btn-sm" onClick={() => {this.deleteData(row)}}>
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JenisInventaris;