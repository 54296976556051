import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {Document, Page} from "react-pdf";

class Transaksi extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: '',
            urlParam: queryString.parse(window.location.search),

            my_kode_trans: '',
            jenis_pembayaran: '',
            kode_transaksi: '',
            tgl_trans: '',
            id_rekening: '',
            nama_nasabah: '',
            alamat: '',
            no_rekening: '',
            jumlah: '0.00',
            jumlah_nett: '0.00',
            materai: '0.00',
            pokok: '0.00',
            nominal: '0.00',
            pajak: '0.00',
            saldo_penutupan: '0.00',
            available_interest: '0.00',
            nominal_deposito: '0.00',
            nominal_fee: '0.00',
            kuitansi: '',
            keterangan: '',
            no_rek_ob: '',
            nama_nasabah_ob: '',
            alamat_ob: '',
            id_col: '',
            kolektor: '',
            bilyet: '',
            dataRekening: [],
            dataTabungan: [],
            dataUsers: [],
            dataKodeTransaksiDeposito: JSON.parse(ReactSession.get("bprsess_dataKodeTransaksiDeposito")),
            dataKodeJenisTransaksiDeposito: JSON.parse(ReactSession.get("bprsess_dataKodeJenisTransaksiDeposito")),
            dataKodeJenisPembayaran: JSON.parse(ReactSession.get("bprsess_dataKodeJenisPembayaran")),
            isShowKodeTransaksi: false,
            isShowOB: false,
            multiValue: [],
            multiRekeningDeposito: [],

            isModalOpen: false,
            reportStream: null,
            pdfWidth: 0
        };
        this.handleMultiChange = this.handleMultiChange.bind(this);
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getMasterData('rekening-tabungan?limit=0','dataTabungan','id',['no_rekening','nasabah|nama']);
            this.getMasterData('rekening-deposito?limit=0','dataRekening','id',['no_rekening','nasabah|nama']);
            this.getMasterData('users?search=is_backoffice:1&limit=0','dataUsers','userid',['userid','name']);
        }
    }

    getMasterData = (endpoint,state,value,label) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                });
            }
        });
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value });

        if(state === 'my_kode_trans') {
            this.setState({
                ket_jenis: event.label,
                jumlah: (event.value === '300') ? this.state.saldo_penutupan : (event.value === '101' || event.value === '311') ? this.state.available_interest.total_interest : (this.state.my_kode_trans === '302') ? this.state.nominal_fee : this.state.nominal_deposito,
                pajak: '0.00',
                kode_transaksi: (event.value === '100' && this.state.jenis_pembayaran === '10') ? '01' : (event.value === '300' && this.state.jenis_pembayaran === '10') ? '02' : (event.value === '311' && this.state.jenis_pembayaran === '10') ? '08' : '',
            }, () => {
                this.sumNominal();

                this.setState({
                    keterangan: this.state.ket_jenis + ': ' + this.state.ket_pembayaran + ' - ' + this.state.no_rekening + ' - ' + this.state.nama_nasabah,
                });

                if(event.value === '100'){
                    this.getMasterData('rekening-deposito?search=status_rekening:10&searchFields=status_rekening:in&limit=0','dataRekening','id',['no_rekening','nasabah|nama']);
                }else if(event.value === '300'){
                    this.getMasterData('rekening-deposito?search=status_rekening:10,20&searchFields=status_rekening:in&limit=0','dataRekening','id',['no_rekening','nasabah|nama']);
                }else if(event.value === '311'){
                    this.getMasterData('rekening-deposito?search=status_rekening:10,20;alokasi_bunga:10,30&searchFields=status_rekening:in;alokasi_bunga:in&searchJoin=and&limit=0','dataRekening','id',['no_rekening','nasabah|nama']);
                }else if(event.value === '321'){
                    this.getMasterData('rekening-deposito?search=status_rekening:10,20;alokasi_bunga:20,21&searchFields=status_rekening:in;alokasi_bunga:in&searchJoin=and&limit=0','dataRekening','id',['no_rekening','nasabah|nama']);
                }else{
                    this.getMasterData('rekening-deposito?search=status_rekening:20&searchFields=status_rekening:in&limit=0','dataRekening','id',['no_rekening','nasabah|nama']);
                }
            });
        }else if(state === 'jenis_pembayaran'){
            this.setState({
                ket_pembayaran: event.label,
                kode_transaksi: (this.state.my_kode_trans === '100' && event.label === 'Tunai') ? '01' : (this.state.my_kode_trans === '300' && event.label === 'Tunai') ? '02' : ((this.state.my_kode_trans === '311' || this.state.my_kode_trans === '321') && event.label === 'Tunai') ? '08' : '',

                //kode_transaksi: (event.label === 'Tunai') ? '01' : '',
                no_rek_ob: (event.label === 'OB Tabungan/Giro') ? this.state.no_rek_ob : '',
                isShowOB: (event.label === 'OB Tabungan/Giro') ? true : false,
            }, () => {
                this.setState({
                    keterangan: this.state.ket_jenis + ': ' + this.state.ket_pembayaran + ' - ' + this.state.no_rekening + ' - ' + this.state.nama_nasabah,
                });
            });
        }else if(state === 'id_rekening'){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'rekening-deposito/'+event.value).then(res => {
                if(res.data){
                    this.setState({
                        dataSource: res.data,
                        nama_nasabah: res.data.nasabah.nama,
                        alamat: res.data.nasabah.alamat,
                        no_rekening: res.data.no_rekening,
                        kolektor: res.data.id_ao,
                        bilyet: res.data.no_bilyet,
                        jumlah: (this.state.my_kode_trans === '300') ? res.data.saldo_penutupan : (this.state.my_kode_trans === '101' || this.state.my_kode_trans === '311' || this.state.my_kode_trans === '321') ? res.data.available_interest.total_interest : res.data.nominal_deposito,
                        pajak: (this.state.my_kode_trans === '300') ? res.data.nominal_fee_pajak : (this.state.my_kode_trans === '101' || this.state.my_kode_trans === '311' || this.state.my_kode_trans === '321') ? res.data.available_interest.total_tax : (this.state.my_kode_trans === '302') ? res.data.nominal_fee_pajak : res.data.nominal_fee_pajak,
                        //tgl_trans: res.data.tgl_registrasi,
                        tgl_trans: (this.state.my_kode_trans === '100' && this.state.jenis_pembayaran === '10') ? res.data.tgl_registrasi : this.state.tgl_trans,

                        saldo_penutupan: res.data.saldo_penutupan,
                        available_interest: res.data.available_interest,
                        nominal_deposito: res.data.nominal_deposito,

                        jumlah_nett: res.data.nominal_fee_nett
                    }, () => {
                        this.sumNominal();

                        this.setState({
                            keterangan: this.state.ket_jenis + ': ' + this.state.ket_pembayaran + ' - ' + this.state.no_rekening + ' - ' + this.state.nama_nasabah,
                        });
                    });
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }else if(state === 'jenis_pembayaran'){
            this.getMasterData('kode-transaksi-deposito?limit=0&search=jenis_pembayaran:'+event.value,'dataKodeTransaksiDeposito','real_id','deskripsi');
        }else if(state === 'id_rekening_ob'){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'rekening-tabungan/'+event.value).then(res => {
                if(res.data){
                    this.setState({
                        nama_nasabah_ob: res.data.nasabah.nama,
                        alamat_ob: res.data.nasabah.alamat,
                        no_rek_ob: res.data.no_rekening
                    });
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }
    };

    handleMultiChange(option) {
        this.setState({
            multiValue: option,
            multiRekeningDeposito: [],
            jumlah: '0',
            pajak: '0',
            saldo_penutupan: '0',
            available_interest: '0',
            nominal_deposito: '0',
            nominal_fee: '0',

            jumlah_nett: '0'
        }, () => {
            if(this.state.multiValue.length > 0){
                $(".loader-wrapper").css("display", "");
                let countData = 1;
                this.state.multiValue.forEach((v, i) => {
                    Helpers.getMasterData(this.state.sessToken,'rekening-deposito/'+v.value).then(res => {
                        if(res.data){
                            let newStateArray = this.state.multiRekeningDeposito.slice();
                            newStateArray.push(res.data.no_rekening);

                            let jumlah = (this.state.jumlah === '0.00' || this.state.jumlah === '0') ? 0 : this.state.jumlah;
                            let newJumlah = (this.state.my_kode_trans === '300') ? res.data.saldo_penutupan : (this.state.my_kode_trans === '101' || this.state.my_kode_trans === '311') ? res.data.available_interest.total_interest : (this.state.my_kode_trans === '302') ? res.data.nominal_fee : res.data.nominal_deposito;
                            let jumlah_new = jumlah+newJumlah;

                            let n_pajak = (this.state.pajak === '0.00' || this.state.pajak === '0') ? 0 : this.state.pajak;
                            //let newPajak = res.data.nominal_fee_pajak;
                            let newPajak = (this.state.my_kode_trans === '300') ? res.data.nominal_fee_pajak : (this.state.my_kode_trans === '101' || this.state.my_kode_trans === '311') ? res.data.available_interest.total_tax : (this.state.my_kode_trans === '302') ? res.data.nominal_fee_pajak : res.data.nominal_fee_pajak;
                            let n_pajak_new = n_pajak+newPajak;

                            let saldo_penutupan = (this.state.saldo_penutupan === '0.00' || this.state.saldo_penutupan === '0') ? 0 : this.state.saldo_penutupan;
                            let new_saldo_penutupan = res.data.saldo_penutupan;
                            let saldo_penutupan_new = saldo_penutupan+new_saldo_penutupan;

                            let available_interest = (this.state.available_interest === '0.00' || this.state.available_interest === '0') ? 0 : this.state.available_interest;
                            let new_available_interest = res.data.available_interest;
                            let available_interest_new = available_interest+new_available_interest;

                            let nominal_deposito = (this.state.nominal_deposito === '0.00' || this.state.nominal_deposito === '0') ? 0 : this.state.nominal_deposito;
                            let new_nominal_deposito = res.data.nominal_deposito;
                            let nominal_deposito_new = nominal_deposito+new_nominal_deposito;

                            let nominal_fee = (this.state.nominal_fee === '0.00' || this.state.nominal_fee === '0') ? 0 : this.state.nominal_fee;
                            let new_nominal_fee = res.data.nominal_deposito;
                            let nominal_fee_new = nominal_fee+new_nominal_fee;

                            let nominal_fee_nett = (this.state.jumlah_nett === '0.00' || this.state.jumlah_nett === '0') ? 0 : this.state.jumlah_nett;
                            let new_nominal_fee_nett = res.data.nominal_fee_nett;
                            let nominal_fee_nett_new = nominal_fee_nett+new_nominal_fee_nett;

                            this.setState({
                                multiRekeningDeposito: newStateArray,
                                jumlah: jumlah_new,
                                pajak: n_pajak_new,
                                saldo_penutupan: saldo_penutupan_new,
                                available_interest: available_interest_new,
                                nominal_deposito: nominal_deposito_new,
                                nominal_fee: nominal_fee_new,

                                jumlah_nett: nominal_fee_nett_new
                            }, () => {
                                this.sumNominal();
                            });
                        }else{
                            toast.error(Helpers.MSG_ERROR);
                        }

                        if(countData === this.state.multiValue.length){
                            $(".loader-wrapper").css("display", "none");
                        }

                        countData++;
                    })
                });
            }else{
                this.setState({
                    multiRekeningDeposito: [],
                    jumlah: '0',
                    pajak: '0',
                    saldo_penutupan: '0',
                    available_interest: '0',
                    nominal_deposito: '0',
                    nominal_fee: '0',

                    jumlah_nett: '0'
                }, () => {
                    this.sumNominal();
                });
            }
        });
    }

    onTextChange = (event,state) => {
        this.setState({ [state]: event }, () => {
            this.sumNominal();
        });
    };

    sumNominal = () => {
        let n_jumlah = parseFloat(this.state.jumlah).toFixed(2);
        let n_materai = parseFloat(this.state.materai).toFixed(2);
        let n_pokok = parseFloat(this.state.pokok).toFixed(2);
        let n_pajak = parseFloat(this.state.pajak).toFixed(2);
        let n_total = parseFloat(n_jumlah) + parseFloat(n_materai) + parseFloat(n_pokok) - parseFloat(n_pajak);

        this.setState({ nominal: parseFloat(n_total).toFixed(2) });

        /*let n_jumlah = parseFloat(this.state.jumlah_nett).toFixed(2);
        let n_total = parseFloat(n_jumlah);

        this.setState({ nominal: parseFloat(n_total).toFixed(2) });*/
    };

    toggleCheckbox = (state) => {
        this.setState({ [state]: (this.state[state] === 0) ? 1 : 0 });
    };

    submitData = () => {
        this.saveNasabah('POST','deposito-transaction');
    };

    saveNasabah = (method,endpoint) => {
        let norek = '';
        if(this.state.my_kode_trans === '302'){
            norek = this.state.multiRekeningDeposito[0];
        }else{
            norek = this.state.no_rekening;
        }

        let urlencoded = new URLSearchParams();
        urlencoded.append("tgl_trans", this.state.tgl_trans);
        urlencoded.append("nominal", this.state.nominal);
        urlencoded.append("my_kode_trans", this.state.my_kode_trans);
        urlencoded.append("jenis_pembayaran", this.state.jenis_pembayaran);
        urlencoded.append("kode_transaksi", (this.state.ket_pembayaran === 'OB GL') ? Helpers.castEmpty(this.state.kode_transaksi) : '');
        urlencoded.append("no_rekening", norek);
        urlencoded.append("kuitansi", Helpers.castEmpty(this.state.kuitansi));
        urlencoded.append("keterangan", Helpers.castEmpty(this.state.keterangan));
        urlencoded.append("no_rek_ob", Helpers.castEmpty(this.state.no_rek_ob));
        urlencoded.append("id_col", this.state.id_col);
        urlencoded.append("nominal_pajak", this.state.pajak);
        urlencoded.append("list_norek_fee_marketing", this.state.multiRekeningDeposito.map((row,index) => {
            return row;
        }));

        $(".loader-wrapper").css("display", "");
        let getResponseCode = null;
        fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => {
            getResponseCode = res.status;
            return res.json();
        }).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        if(getResponseCode !== 200){
                            toast.error(json.message);
                        }else{
                            toast.success(Helpers.MSG_SUCCESS);

                            this.setState({
                                trans_id: json.data.trans_id
                            });
                        }

                        /*setTimeout(function() {
                            window.location.href = './teller-dep-transaksi';
                        }, 1000);*/
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    handleItemPrint = () => {
        if(this.state.trans_id){
            $(".loader-wrapper").css("display", "");
            Helpers.getReportPDF(this.state.sessToken,'deposito-transaction/report/transaction/print/'+this.state.trans_id+'?format=pdf').then(res => {
                this.setState({
                    reportStream: res
                }, () => {
                    this.handleModal();
                });

                $(".loader-wrapper").css("display", "none");
            });
        }else{
            toast.error('Anda belum melakukan transaksi!');
        }
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleDownload = () => {
        const uid = (new Date().getTime());
        Helpers.download(this.state.reportStream, 'kuitansi_transaksi_deposito_'+uid);
    };

    render() {
        const animatedComponents = makeAnimated();

        return (
            <div className="content">

                <Modal
                    size={'lg'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}>
                    <ModalBody>
                        <Document file={this.state.reportStream}>
                            <Page
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={this.state.pdfWidth}
                            />
                        </Document>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {this.handleDownload()}}>Download</Button>
                        <Button onClick={() => {this.handleModal()}}>Close</Button>
                    </ModalFooter>
                </Modal>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Teller</li>
                                        <li className="breadcrumb-item">Deposito</li>
                                        <li className="breadcrumb-item active">Transaksi Deposito</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Transaksi Deposito</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                        <i className="fe-save me-1"></i> Simpan
                                    </a>
                                    <a className="btn btn-danger waves-effect waves-light mr-5" onClick={() => {window.location.reload()}}>
                                        <i className="fe-trash me-1"></i> Bersihkan
                                    </a>
                                    <a className="btn btn-info waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                        <i className="fe-printer me-1"></i> Print Buku Tabungan
                                    </a>
                                    <a className="btn btn-info waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                        <i className="fe-printer me-1"></i> Print Validasi
                                    </a>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-bordered">
                                        <li className="nav-item">
                                            <a href="#form-1" data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                                Teller Deposito
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="form-1">
                                            <div className={'row'}>
                                                <div className={'col-5'}>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Transaksi <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataKodeJenisTransaksiDeposito}
                                                            value={this.state.dataKodeJenisTransaksiDeposito.filter((item) => item.value === this.state.my_kode_trans)}
                                                            onChange={event => this.handleChange(event,'my_kode_trans')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Pembayaran <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataKodeJenisPembayaran}
                                                            value={this.state.dataKodeJenisPembayaran.filter((item) => item.value === this.state.jenis_pembayaran)}
                                                            onChange={event => this.handleChange(event,'jenis_pembayaran')} />
                                                    </div>
                                                    {
                                                        (this.state.ket_pembayaran === 'OB GL') && (
                                                            <div className="mb-1">
                                                                <label className="form-label">Kode Transaksi</label>
                                                                <Select
                                                                    options={this.state.dataKodeTransaksiDeposito}
                                                                    value={this.state.dataKodeTransaksiDeposito.filter((item) => item.value === this.state.kode_transaksi)}
                                                                    onChange={event => this.handleChange(event,'kode_transaksi')} />
                                                            </div>
                                                        )
                                                    }

                                                    <div className="dropdown-divider mt-3 mb-2"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tgl Transaksi <code>(required)</code></label>
                                                        <input type="date" className="form-control" value={this.state.tgl_trans} onChange={e => this.setState({tgl_trans: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">No. Rekening</label>
                                                        {
                                                            (this.state.my_kode_trans !== '302') && (
                                                                <Select
                                                                    options={this.state.dataRekening}
                                                                    value={this.state.dataRekening.filter((item) => item.value === this.state.id_rekening)}
                                                                    onChange={event => this.handleChange(event,'id_rekening')} />
                                                            )
                                                        }
                                                        {
                                                            (this.state.my_kode_trans === '302') && (
                                                                <Select
                                                                    closeMenuOnSelect={false}
                                                                    components={animatedComponents}
                                                                    isMulti
                                                                    options={this.state.dataRekening}
                                                                    value={this.state.multiValue}
                                                                    onChange={this.handleMultiChange}
                                                                />
                                                            )
                                                        }

                                                    </div>
                                                    {/*<div className="mb-1">*/}
                                                        {/*<label className="form-label">No Bilyet</label>*/}
                                                        {/*<input type="text" readOnly={true} className="form-control" value={this.state.bilyet} onChange={e => this.setState({bilyet: e.target.value})} />*/}
                                                    {/*</div>*/}
                                                    {/*<div className="mb-1">*/}
                                                        {/*<label className="form-label">Nama</label>*/}
                                                        {/*<input type="text" readOnly={true} className="form-control" value={this.state.nama_nasabah} onChange={e => this.setState({nama_nasabah: e.target.value})} />*/}
                                                    {/*</div>*/}
                                                    {/*<div className="mb-1">*/}
                                                        {/*<label className="form-label">Alamat</label>*/}
                                                        {/*<input type="text" readOnly={true} className="form-control" value={this.state.alamat} onChange={e => this.setState({alamat: e.target.value})} />*/}
                                                    {/*</div>*/}

                                                    <div className="dropdown-divider mt-3 mb-2"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kolektor</label>
                                                        <Select
                                                            options={this.state.dataUsers}
                                                            value={this.state.dataUsers.filter((item) => item.value === this.state.kolektor)}
                                                            onChange={event => this.handleChange(event,'kolektor')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kuitansi</label>
                                                        <input type="text" className="form-control" value={this.state.kuitansi} onChange={e => this.setState({kuitansi: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Keterangan</label>
                                                        <textarea className="form-control" rows="3" value={this.state.keterangan} onChange={e => this.setState({keterangan: e.target.value})}></textarea>
                                                    </div>

                                                    {
                                                        this.state.isShowOB && (
                                                            <>
                                                                <div className="dropdown-divider mt-3 mb-2"></div>
                                                                <div className="mb-1">
                                                                    <label className="form-label">No. Rekening {this.state.ob_title}</label>
                                                                    <Select
                                                                        options={this.state.dataTabungan}
                                                                        value={this.state.dataTabungan.filter((item) => item.value === this.state.id_rekening_ob)}
                                                                        onChange={event => this.handleChange(event,'id_rekening_ob')} />
                                                                </div>
                                                                <div className="mb-1">
                                                                    <label className="form-label">Nama</label>
                                                                    <input type="text" readOnly={true} className="form-control" value={this.state.nama_nasabah_ob} onChange={e => this.setState({nama_nasabah_ob: e.target.value})} />
                                                                </div>
                                                                <div className="mb-1">
                                                                    <label className="form-label">Alamat</label>
                                                                    <input type="text" readOnly={true} className="form-control" value={this.state.alamat_ob} onChange={e => this.setState({alamat_ob: e.target.value})} />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div className={'col-7'}>
                                                    <table width="100%">
                                                        <thead>
                                                        <tr>
                                                            <th width="30%">Deskripsi</th>
                                                            <th width="70%">Nominal</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>Jumlah <code>(required)</code></td>
                                                            <td>
                                                                <input type="number" className="form-control txt-right" value={this.state.jumlah} onChange={e => this.onTextChange(e.target.value, 'jumlah')} readOnly={true} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Materai</td>
                                                            <td>
                                                                <input type="number" className="form-control txt-right" value={this.state.materai} onChange={e => this.onTextChange(e.target.value, 'materai')} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pokok Materai</td>
                                                            <td>
                                                                <input type="number" className="form-control txt-right" value={this.state.pokok} onChange={e => this.onTextChange(e.target.value, 'pokok')} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pajak</td>
                                                            <td>
                                                                <input type="number" className="form-control txt-right" value={this.state.pajak} onChange={e => this.onTextChange(e.target.value, 'pajak')} readOnly={true} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}><div className="dropdown-divider"></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total</td>
                                                            <td>
                                                                <input type="number" readOnly={false} className="form-control txt-right" value={this.state.nominal} onChange={e => this.setState({nominal: e.target.value})} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}><div className="dropdown-divider"></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <a className="btn btn-info btn-sm waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                                                    <i className="fe-printer me-1"></i> Print Template
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {/*<Select*/}
                                                                    {/*options={this.state.dataKodepos}*/}
                                                                    {/*value={this.state.dataKodepos.filter((item) => item.value === this.state.kodepos)}*/}
                                                                    {/*onChange={event => this.handleChange(event,'kodepos')} />*/}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <a className="btn btn-info btn-sm waves-effect waves-light mr-5" onClick={() => {this.handleItemPrint()}}>
                                                                    <i className="fe-printer me-1"></i> Cetak Kuitansi
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {/*<Select*/}
                                                                    {/*options={this.state.dataKodepos}*/}
                                                                    {/*value={this.state.dataKodepos.filter((item) => item.value === this.state.kodepos)}*/}
                                                                    {/*onChange={event => this.handleChange(event,'kodepos')} />*/}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Transaksi;