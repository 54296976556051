import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {ReactSession} from "react-client-session";
import {MDBDataTableV5} from "mdbreact";
//import {Quixote} from "./RenderPDF";
import {
    Document,
    Page,
    Text,
    Font,
    View,
    Image,
    StyleSheet,
    PDFViewer,
} from "@react-pdf/renderer";
import {encode as base64_encode} from "base-64";
import $ from "jquery";
import {Helpers} from "../../../../config/Helpers";
import {toast} from "react-toastify";
import {Table, TableHeader, TableCell, TableBody, DataTableCell} from "@david.kucsai/react-pdf-table";

import Invoice from './components/Invoice';

class ModalReport extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            isModalOpen: this.props.modalRef,
            dataSource: JSON.parse(ReactSession.get("bprsess_report")),
        }
    }

    componentDidMount() {
        //this.getData();
    }

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'tabungan-transaction?tgl_trans_start=2022-08-01&tgl_trans_end=2022-09-05&limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataSource: res.data
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    render() {
        return (
            <Modal
                size={'xl'}
                isOpen={this.state.isModalOpen}
                toggle={() => {this.handleModal()}}>
                <ModalBody>
                    <PDFViewer style={styles.viewer}>
                        <Invoice invoice={this.state.dataSource}/>
                    </PDFViewer>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {this.handleModal()}}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
    viewer: {
        width: '100%',
        height: '475px',
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: 'Oswald'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: 'Oswald'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    page: { flexDirection: "column", padding: 25 },
    table: {
        fontSize: 10,
        width: 550,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35
    },
    cell: {
        borderColor: "#cc0000",
        borderStyle: "solid",
        borderWidth: 2,
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
        alignSelf: "stretch"
    },
    headerText: {
        fontSize: 11,
        fontWeight: 1200,
        color: "#1a245c",
        margin: 8
    },
    tableText: {
        margin: 10,
        fontSize: 10,
        color: 'black'
    }
});

export default ModalReport;