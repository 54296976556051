import { Connection } from './Connection';

export const CONF_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'id',
    'Cache-Control': 'no-cache',
    //'Access-Control-Allow-Origin': '*'
}

export class Helpers {
    static TITLE_SUCCESS = 'Success';
    static TITLE_ERROR = 'Alert';

    static MSG_SUCCESS = 'Success';
    static MSG_ERROR = 'Error, please contact administrator for more information.';

    static MSG_ERROR_FORM = 'Form tidak boleh kosong';

    static MSG_SUCCESS_LOGIN = 'Login berhasil';
    static MSG_ERROR_LOGIN = 'Akun tidak ditemukan atau username/password tidak sesuai';

    static OBJ_STATUS = 'status';
    static OBJ_ERROR = 'errors';

    static DEF_COMPANY = 'BPR PRABU MITRA';
    static DEF_COMPANY_CODE = '001';

    static optionsEmpty = [];

    static optionsPick = [
        { value: '1', label: 'Ya' },
        { value: '0', label: 'Tidak' }
    ];

    static optionsJenisProduk = [
        { value: '1', label: 'Sejenis (Non ABA/Non ABA, ABA/ABA)' },
        { value: '2', label: 'Berbeda (Non ABA/ABA)' }
    ];

    static optionsKeterkaitan = [
        { value: '10', label: 'Terkait' },
        { value: '20', label: 'Tidak Terkait' },
        { value: '12', label: 'Terkait Lainnya' }
    ];

    static optionsRestricted = [
        { value: 'Restricted', label: 'Restricted' },
        { value: 'UnRestricted', label: 'UnRestricted' }
    ];

    static optionsKolektibilitas = [
        { value: '0', label: '0-Default' },
        { value: '1', label: '1-Lancar' },
        { value: '2', label: '2-Dalam Perhatian Khusus' },
        { value: '3', label: '3-Kurang Lancar' },
        { value: '4', label: '4-Diragukan' },
        { value: '5', label: '5-Macet' }
    ];

    static optionsPeriodeTopup = [
        { value: '1-Upload Data', label: '1-Upload Data' },
        { value: '2-Per Hari Max Saldo', label: '2-Per Hari Max Saldo' },
        { value: '3-Per Hari Jumlah Topup', label: '3-Per Hari Jumlah Topup' },
        { value: '4-Per Bulan Max Saldo', label: '4-Per Bulan Max Saldo' },
        { value: '5-Per Hari Jumlah Topup', label: '5-Per Hari Jumlah Topup' }
    ];

    static optionsJangkaWaktu = [
        { value: '1-Harian', label: '1-Harian' },
        { value: '2-Pasaran', label: '2-Pasaran' },
        { value: '3-Mingguan', label: '3-Mingguan' },
        { value: '4-Bulanan', label: '4-Bulanan' }
    ];

    static optionsBasedFee = [
        { value: '1', label: 'Reguler' },
        { value: '2', label: 'Rata Rata Bulanan' },
        { value: '3', label: 'Rata Rata Tahunan' }
    ];

    static optionsAlokasiBunga = [
        { value: '10', label: 'Ambil Tunai/Transfer Bank' },
        { value: '20', label: 'Ke Pokok Saat JT Bunga' },
        { value: '21', label: 'Ke Pokok Saat JT Deposito' },
        { value: '30', label: 'Ke Tabungan' }
    ];

    static optionsCetak = [
        { value: '10', label: 'Maker' },
        { value: '20', label: 'Approval' },
        { value: '30', label: 'Lembaga' },
        { value: '40', label: 'Maker Approval' },
        { value: '50', label: 'Maker Checker Approval' },
        { value: '60', label: 'Maker Checker Audit Approval' }
    ];

    static optionsJenisKas = [
        { value: '10101', label: 'Kas Besar' },
        { value: '10103', label: 'Kas Teller' },
        { value: '10102', label: 'Kas Kecil' }
    ];

    static optionsJenisTransaksi = [
        { value: '100', label: 'Penerimaan Modal' },
        { value: '110', label: 'Penerimaan Kas' },
        { value: '300', label: 'Pengeluaran Kas' },
        { value: '390', label: 'Pengeluaran Modal' }
    ];

    static optionsPenyusutan = [
        { value: 'Otomatis', label: 'Otomatis' },
        { value: 'Manual', label: 'Manual' }
    ];

    static optionsJenisTransaksiInventaris = [
        { value: '100', label: 'Pembelian' },
        { value: '300', label: 'Penjualan' },
        { value: '400', label: 'Penyusutan Manual' }
    ];

    static optionsJenisTransaksiRRA = [
        { value: '100', label: 'Penambahan' },
        { value: '300', label: 'Penyusutan Manual' }
    ];

    static optionsRekapBy = [
        { value: '1', label: 'User Id' }
    ];

    static optionsJenisKas = [
        { value: '1', label: 'Kas Teller' }
    ];

    static optionsJenisLaporan = [
        { value: '1', label: 'Rinci' }
    ];

    static optionsReportSign = [
        { value: '1', label: 'Maker' }
    ];

    static optionsJenisLaporanNeraca = [
        { value: '1', label: '3301/00 - Neraca' },
        { value: '2', label: '3302/00 - Laba Rugi' },
        { value: '3', label: '3303/00 - Administratif' },
        { value: '4', label: '3304/00 - Neraca Rekap' },
        { value: '5', label: '3305/00 - Laba Rugi Rekap' },
        { value: '6', label: '3306/00 - Administratif Rekap' }
    ];

    static arrMasterData = [
        { endpoint: 'kode-jenis-nasabah?limit=0', name: 'Jenis Nasabah', state: 'dataJenisNasabah', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-kota?limit=0', name: 'Kota', state: 'dataKota', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-pos?limit=0', name: 'Kodepos', state: 'dataKodepos', value: 'real_id', label: ['kode_pos','kelurahan'] },
        { endpoint: 'kode-sektor-ekonomi?limit=0', name: 'Bidang Usaha', state: 'dataBidangUsaha', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-negara?limit=0', name: 'Negara', state: 'dataNegara', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-lokasi?limit=0', name: 'Lokasi', state: 'dataLokasi', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-gol-debitur?limit=0', name: 'Gol Debitur', state: 'dataGolDebitur', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-pemilik?limit=0', name: 'Pemilik', state: 'dataPemilik', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-gol-kreditur?limit=0', name: 'Gol Kreditur', state: 'dataGolKreditur', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-slik-pihak-tiga?limit=0', name: 'Slik Pihak Tiga', state: 'dataSlikPihakTiga', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-slik-hubungan-ljk?limit=0', name: 'Slik Hubungan', state: 'dataSlikHubungan', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-bank?limit=0', name: 'Bank', state: 'dataBank', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-jenis-identitas?limit=0', name: 'Jenis Identitas', state: 'dataJenisIdentitas', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-jenis-kelamin?limit=0', name: 'Jenis Kelamin', state: 'dataJenisKelamin', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-status-perkawinan?limit=0', name: 'Status Perkawinan', state: 'dataStatusPerkawinan', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-agama?limit=0', name: 'Agama', state: 'dataAgama', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-status-rumah?limit=0', name: 'Status Rumah', state: 'dataStatusRumah', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-pekerjaan?limit=0', name: 'Pekerjaan', state: 'dataPekerjaan', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-sumber-penghasilan?limit=0', name: 'Sumber Penghasilan', state: 'dataSumberPenghasilan', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-jenis-tabungan?limit=0', name: 'Jenis Tabungan', state: 'dataJenisTabungan', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'manajemen-kelompok?limit=0', name: 'Kelompok', state: 'dataKelompok', value: 'real_id', label: ['real_id','deskripsi','id_col'] },
        { endpoint: 'kode-status-rekening?limit=0', name: 'Status Rekening', state: 'dataStatusRekening', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-sumber-dana?limit=0', name: 'Sumber Dana', state: 'dataSumberDana', value: 'deskripsi', label: 'deskripsi' },
        { endpoint: 'kode-tujuan-pembukaan?limit=0', name: 'Tujuan Pembukaan', state: 'dataTujuanPembukaan', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-sumber-dana-rekening?limit=0', name: 'Sumber Dana Rekening', state: 'dataSumberDanaRekening', value: 'deskripsi', label: 'deskripsi' },
        { endpoint: 'kode-lembaga-rating?limit=0', name: 'Lembaga Rating', state: 'dataLembagaRating', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-jenis-deposito?limit=0', name: 'Jenis Deposito', state: 'dataJenisDeposito', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-transaksi-tabungan?limit=0', name: 'Kode Transaksi Tabungan', state: 'dataKodeTransaksiTabungan', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-jenis-transaksi-tabungan?limit=0', name: 'Kode Jenis Transaksi Tabungan', state: 'dataKodeJenisTransaksiTabungan', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-jenis-pembayaran?limit=0', name: 'Kode Jenis Pembayaran', state: 'dataKodeJenisPembayaran', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-perkiraan?limit=0', name: 'Kode Perkiraan', state: 'dataKodePerkiraan', value: 'kode_perk', label: 'nama_perk' },
        { endpoint: 'kode-transaksi-deposito?limit=0', name: 'Kode Transaksi Deposito', state: 'dataKodeTransaksiDeposito', value: 'real_id', label: 'deskripsi' },
        { endpoint: 'kode-jenis-transaksi-deposito?limit=0', name: 'Kode Jenis Transaksi Deposito', state: 'dataKodeJenisTransaksiDeposito', value: 'real_id', label: 'deskripsi' },
    ];

    static getLoginData(username,password){
        return fetch(Connection.API_URL + 'oauth/token', {
            method: 'POST',
            headers: CONF_HEADERS,
            body: JSON.stringify({
                username: username,
                password: password,
                client_id: Connection.CLIENT_ID,
                client_secret: Connection.CLIENT_SECRET,
                grant_type: 'password'
            })
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getDataUser(token,id){
        CONF_HEADERS["Authorization"] = 'Bearer ' + token;

        return fetch(Connection.API_URL + 'users/' + id, {
            method: 'GET',
            headers: CONF_HEADERS
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getDataNasabah(token){
        CONF_HEADERS["Authorization"] = 'Bearer ' + token;

        return fetch(Connection.API_URL + 'nasabah?limit=0', {
            method: 'GET',
            headers: CONF_HEADERS
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getDataNasabahDetail(token,id){
        CONF_HEADERS["Authorization"] = 'Bearer ' + token;

        return fetch(Connection.API_URL + 'nasabah/' + id, {
            method: 'GET',
            headers: CONF_HEADERS
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMasterData(token,endpoint){
        CONF_HEADERS["Authorization"] = 'Bearer ' + token;

        return fetch(Connection.API_URL + endpoint, {
            method: 'GET',
            headers: CONF_HEADERS
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static saveNasabah(token,method,endpoint,param){
        CONF_HEADERS["Authorization"] = 'Bearer ' + token;

        return fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: CONF_HEADERS,
            body: param
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static saveTabungan(token,method,endpoint,param){
        CONF_HEADERS["Authorization"] = 'Bearer ' + token;

        return fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: CONF_HEADERS,
            body: param
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static saveDeposito(token,method,endpoint,param){
        CONF_HEADERS["Authorization"] = 'Bearer ' + token;

        return fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: CONF_HEADERS,
            body: param
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getReportPDF(token,endpoint){
        CONF_HEADERS["Authorization"] = 'Bearer ' + token;

        return fetch(Connection.API_URL + endpoint, {
            method: 'GET',
            headers: CONF_HEADERS
        }).then((res) => res.blob()).then((blob) => {
            return window.URL.createObjectURL(blob);
        }).catch((e) => {
            console.log(e);
        });
    }

    static customError(errors){
        var msg = '';

        Object.values(errors).map((key) => {
            for (let obj of key) {
                msg = msg + '- ' + obj + '\n'
            }
        });

        return msg;
    }

    static castEmpty(myVar){
        let msg = "";

        if (myVar === undefined) {
            msg = "";
        } else if (myVar === null) {
            msg = "";
        } else {
            msg = myVar;
        }

        return msg;
    }

    static download(url, filename) {
        //const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

}