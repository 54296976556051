import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";
import ModalReport from "../../../components/pdf/laporan/tabungan/ModalReport";
import ModalKodepos from "../../../components/ModalKodepos";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {Document, Page} from "react-pdf";

class PosisiKas extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],
            dataUser: [],
            dataJenisPembayaran: [],
            setModal: '',
            rekapBy: '',
            userId: '',
            startDate: '',
            endDate: '',
            jenisPembayaran: '',
            jenisKas: '',
            jenisLaporan: '',
            reportSign: '',
            saldoAwal: 0,

            isModalOpen: false,
            reportStream: null,
            pdfWidth: 0
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getMasterData('users?limit=0','dataUser','userid','name');
            this.getMasterData('kode-jenis-pembayaran?limit=0','dataJenisPembayaran','real_id','deskripsi');
        }
    }

    getMasterData = (endpoint,state,value,label) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                }, () => {
                    let prevArr = this.state[state];

                    prevArr.push({
                        value: '',
                        label: 'Please Select'
                    });

                    this.setState({
                        [state]: prevArr
                    });
                });
            }
        })
    };

    getData = () => {
        if(this.state.startDate && this.state.endDate){
            $(".loader-wrapper").css("display", "");

            let set_url = 'teller-transaction/report/transaction?tgl_trans_start='+this.state.startDate+'&tgl_trans_end='+this.state.endDate;
            if(this.state.userId){
                set_url = set_url+'&userid='+this.state.userId;
            }
            if(this.state.jenisPembayaran){
                set_url = set_url+'&jenis_transaksi='+this.state.jenisPembayaran;
            }

            Helpers.getMasterData(this.state.sessToken,set_url+'&limit=0').then(res => {
                if(res.data){
                    this.setState({
                        dataSource: res.data,
                        saldoAwal: res.meta.custom.saldo_awal_formatted
                    });

                    /*ReactSession.set("bprsess_report", JSON.stringify(
                        {
                            "report_type": "transaksi_tabungan",
                            "invoice_no": "001-Kantor Pusat",
                            "start_date": this.state.startDate,
                            "end_date": this.state.endDate,
                            "items": res.data,
                            "meta": res.meta
                        }
                    ));*/
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }else{
            toast.error('Anda belum memilih periode');
        }
    };

    handleChange = (event,state) => {
        this.setState({
            [state]: event.value
        });
    };

    handleItemPrint = () => {
        if(this.state.dataSource.length > 0){
            $(".loader-wrapper").css("display", "");

            let set_url = 'teller-transaction/report/transaction/print?tgl_trans_start='+this.state.startDate+'&tgl_trans_end='+this.state.endDate;
            if(this.state.userId){
                set_url = set_url+'&userid='+this.state.userId;
            }
            if(this.state.jenisPembayaran){
                set_url = set_url+'&jenis_transaksi='+this.state.jenisPembayaran;
            }

            Helpers.getReportPDF(this.state.sessToken,set_url+'&format=pdf&limit=0').then(res => {
                this.setState({
                    reportStream: res
                }, () => {
                    this.handleModal();
                });

                $(".loader-wrapper").css("display", "none");
            });
        }else{
            toast.error('Mohon untuk proses data terlebih dahulu');
        }
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleDownload = () => {
        const uid = (new Date().getTime());
        Helpers.download(this.state.reportStream, 'kuitansi_penerimaan_bunga_deposito_'+uid);
    };

    render() {
        return (
            <div className="content">
                {/*{ this.state.setModal }*/}

                <Modal
                    size={'lg'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}>
                    <ModalBody>
                        <Document file={this.state.reportStream}>
                            <Page
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={this.state.pdfWidth}
                            />
                        </Document>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {this.handleDownload()}}>Download</Button>
                        <Button onClick={() => {this.handleModal()}}>Close</Button>
                    </ModalFooter>
                </Modal>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Laporan</li>
                                        <li className="breadcrumb-item">Teller</li>
                                        <li className="breadcrumb-item active">Posisi Kas</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Posisi Kas</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label">Rekap Berdasarkan</label>
                                            <Select
                                                options={Helpers.optionsRekapBy}
                                                value={Helpers.optionsRekapBy.filter((item) => item.value === this.state.rekapBy)}
                                                onChange={event => this.handleChange(event,'rekapBy')} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">User Id</label>
                                            <Select
                                                options={this.state.dataUser}
                                                value={this.state.dataUser.filter((item) => item.value === this.state.userId)}
                                                onChange={event => this.handleChange(event,'userId')} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Tanggal</label>
                                            <input type="date" className="form-control" value={this.state.startDate} onChange={e => this.setState({startDate: e.target.value})} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Ke</label>
                                            <input type="date" className="form-control" value={this.state.endDate} onChange={e => this.setState({endDate: e.target.value})} />
                                        </div>

                                        <div className="col-12 mt-1"></div>

                                        <div className="col-3">
                                            <label className="form-label">Jenis Transaksi</label>
                                            <Select
                                                options={this.state.dataJenisPembayaran}
                                                value={this.state.dataJenisPembayaran.filter((item) => item.value === this.state.jenisPembayaran)}
                                                onChange={event => this.handleChange(event,'jenisPembayaran')} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Jenis Kas</label>
                                            <Select
                                                options={Helpers.optionsJenisKas}
                                                value={Helpers.optionsJenisKas.filter((item) => item.value === this.state.jenisKas)}
                                                onChange={event => this.handleChange(event,'jenisKas')} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Jenis Laporan</label>
                                            <Select
                                                options={Helpers.optionsJenisLaporan}
                                                value={Helpers.optionsJenisLaporan.filter((item) => item.value === this.state.jenisLaporan)}
                                                onChange={event => this.handleChange(event,'jenisLaporan')} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Report Sign</label>
                                            <Select
                                                options={Helpers.optionsReportSign}
                                                value={Helpers.optionsReportSign.filter((item) => item.value === this.state.reportSign)}
                                                onChange={event => this.handleChange(event,'reportSign')} />
                                        </div>

                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.getData()}}>
                                                Proses
                                            </a>
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.handleItemPrint()}}>
                                                Cetak
                                            </a>
                                        </div>
                                        <div className="col-5"></div>
                                        <div className="col-2" style={{'text-align': 'right'}}>
                                            <label className="form-label" style={{'margin-top': '35px'}}>Saldo Awal</label>
                                        </div>
                                        <div className="col-3">
                                            <input type="text" className="form-control" value={this.state.saldoAwal} readOnly={true} style={{'margin-top': '28px'}} />
                                        </div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'Tanggal',
                                                        field: 'tgl_trans',
                                                    },
                                                    {
                                                        label: 'Kuitansi',
                                                        field: 'kuitansi',
                                                    },
                                                    {
                                                        label: 'Keterangan',
                                                        field: 'keterangan',
                                                    },
                                                    {
                                                        label: 'OB Debet',
                                                        field: 'ob_debet_formatted_view',
                                                    },
                                                    {
                                                        label: 'OB Kredit',
                                                        field: 'ob_kredit_formatted_view',
                                                    },
                                                    {
                                                        label: 'Terima',
                                                        field: 'debet_formatted_view',
                                                    },
                                                    {
                                                        label: 'Keluar',
                                                        field: 'kredit_formatted_view',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    return {
                                                        ...row,
                                                        ob_debet_formatted_view: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.ob_debet}</label>
                                                        ),
                                                        ob_kredit_formatted_view: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.ob_kredit}</label>
                                                        ),
                                                        debet_formatted_view: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.kas_pemasukan}</label>
                                                        ),
                                                        kredit_formatted_view: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.kas_pengeluaran}</label>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PosisiKas;