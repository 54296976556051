import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";

class DataRekeningForm extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: '',
            urlParam: queryString.parse(window.location.search),
            jn_kantor_kode: Helpers.DEF_COMPANY_CODE,
            jn_kantor_nama: Helpers.DEF_COMPANY,

            deskripsi_inventaris: '',
            jenis_inventaris: '',
            jenis_inventaris_pick: '',
            nominal_inventaris: '',
            nilai_residu: '',
            tgl_registrasi: moment(new Date()).format("YYYY-MM-DD"),
            umur_ekonomis: '0',
            tgl_jatuh_tempo: '',
            status_rekening: '',
            nilai_buku: '',
            supplier: '',
            metode_penyusutan: '',

            dataJenisInventaris: [],
            dataStatusRekening: JSON.parse(ReactSession.get("bprsess_dataStatusRekening"))
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.setState({
                getIdRekening: (this.state.urlParam.ref) ? this.state.urlParam.ref : '',
            }, () => {
                this.getMasterData('kode-jenis-inventaris?limit=0','dataJenisInventaris','id',['kode_jenis_inventaris','deskripsi']);

                if(this.state.getIdRekening){
                    this.getData();
                }
            });
        }
    }

    getMasterData = (endpoint,state,value,label) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                });
            }
        })
    };

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'rekening-inventaris/'+this.state.getIdRekening).then(res => {
            if(res.data){
                const data = res.data;
                this.setState({
                    dataSource: data,

                    deskripsi_inventaris: data.deskripsi_inventaris,
                    jenis_inventaris: data.jenis_inventaris.id,
                    jenis_inventaris_pick: data.jenis_inventaris.kode,
                    nominal_inventaris: data.nominal_inventaris,
                    nilai_residu: data.nilai_residu,
                    tgl_registrasi: data.tgl_registrasi,
                    umur_ekonomis: data.umur_ekonomis,
                    tgl_jatuh_tempo: data.tgl_jatuh_tempo,
                    status_rekening: data.status_rekening,
                    nilai_buku: data.nilai_buku,
                    supplier: data.supplier,
                    metode_penyusutan: data.metode_penyusutan,
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    handleChange = (event,state) => {
        this.setState({
            [state]: event.value
        }, () => {
            if(state === 'jenis_inventaris'){
                Helpers.getMasterData(this.state.sessToken,'kode-jenis-inventaris/'+event.value).then(res => {
                    this.setState({
                        umur_ekonomis: res.data.umur_ekonomis,
                        jenis_inventaris_pick: res.data.kode_jenis_inventaris,
                        tgl_jatuh_tempo: moment(this.state.tgl_registrasi).add(res.data.umur_ekonomis, 'months').format("YYYY-MM-DD")
                    });
                })
            }

            if(state === 'tgl_registrasi'){
                this.setState({
                    tgl_jatuh_tempo: moment(this.state.tgl_registrasi).add(this.state.umur_ekonomis, 'months').format("YYYY-MM-DD")
                });
            }

            if(state === 'umur_ekonomis'){
                this.setState({
                    tgl_jatuh_tempo: moment(this.state.tgl_registrasi).add(this.state.umur_ekonomis, 'months').format("YYYY-MM-DD")
                });
            }
        });
    };

    submitData = () => {
        if(this.state.getIdRekening){
            this.saveData('PATCH','rekening-inventaris/'+this.state.getIdRekening);
        }else{
            this.saveData('POST','rekening-inventaris');
        }
    };

    saveData = (method,endpoint) => {
        let urlencoded = new URLSearchParams();
        urlencoded.append("deskripsi_inventaris", this.state.deskripsi_inventaris);
        urlencoded.append("jenis_inventaris", this.state.jenis_inventaris_pick);
        urlencoded.append("nominal_inventaris", this.state.nominal_inventaris);
        urlencoded.append("nilai_residu", Helpers.castEmpty(this.state.nilai_residu));
        urlencoded.append("tgl_registrasi", this.state.tgl_registrasi);
        urlencoded.append("umur_ekonomis", this.state.umur_ekonomis);
        urlencoded.append("tgl_jatuh_tempo", this.state.tgl_jatuh_tempo);
        urlencoded.append("status_rekening", this.state.status_rekening);
        urlencoded.append("nilai_buku", Helpers.castEmpty(this.state.nilai_buku));
        urlencoded.append("supplier", Helpers.castEmpty(this.state.supplier));
        urlencoded.append("metode_penyusutan", this.state.metode_penyusutan);

        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => res.json()).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        toast.success(Helpers.MSG_SUCCESS);

                        setTimeout(function() {
                            window.location.href = './data-rekening-inventaris';
                        }, 1000);
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Akuntansi</li>
                                        <li className="breadcrumb-item">Inventaris</li>
                                        <li className="breadcrumb-item"><a href="./data-rekening-inventaris">Data Rekening Inventaris</a></li>
                                        <li className="breadcrumb-item active">Form</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Data Rekening Inventaris Form</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className={'row'}>
                                        <div className={'col-6'}>
                                            <div className="row mb-1">
                                                <div className="col-md-4">
                                                    <label className="form-label">Cabang</label>
                                                    <input type="text" className="form-control" readOnly={true} value={this.state.jn_kantor_kode} onChange={e => this.setState({jn_kantor_kode: e.target.value})} />
                                                </div>
                                                <div className="col-md-8">
                                                    <label className="form-label">&nbsp;</label>
                                                    <input type="text" className="form-control" readOnly={true} value={this.state.jn_kantor_nama} onChange={e => this.setState({jn_kantor_nama: e.target.value})} />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <label className="form-label">Deskripsi <code>(required)</code></label>
                                                <textarea className="form-control" rows="3" value={this.state.deskripsi_inventaris} onChange={e => this.setState({deskripsi_inventaris: e.target.value})}></textarea>
                                            </div>
                                            <div className="mb-1">
                                                <label className="form-label">Jenis Inventaris <code>(required)</code></label>
                                                <Select
                                                    options={this.state.dataJenisInventaris}
                                                    value={this.state.dataJenisInventaris.filter((item) => item.value === this.state.jenis_inventaris)}
                                                    onChange={event => this.handleChange(event,'jenis_inventaris')} />
                                            </div>
                                            <div className="mb-1">
                                                <label className="form-label">Tanggal Beli <code>(required)</code></label>
                                                <input type="date" className="form-control" value={this.state.tgl_registrasi} onChange={e => this.handleChange(e.target,'tgl_registrasi')} />
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-md-6">
                                                    <label className="form-label">Umur Ekonomis (Bulan) <code>(required)</code></label>
                                                    <input type="number" className="form-control" value={this.state.umur_ekonomis} onChange={e => this.handleChange(e.target,'umur_ekonomis')} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">Residu</label>
                                                    <input type="number" className="form-control" value={this.state.nilai_residu} onChange={e => this.setState({nilai_residu: e.target.value})} />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <label className="form-label">Tanggal Jatuh Tempo <code>(required)</code></label>
                                                <input type="date" className="form-control" value={this.state.tgl_jatuh_tempo} onChange={e => this.setState({tgl_jatuh_tempo: e.target.value})} />
                                            </div>
                                            <div className="mb-1">
                                                <label className="form-label">Jumlah/Harga Beli <code>(required)</code></label>
                                                <input type="number" className="form-control" value={this.state.nominal_inventaris} onChange={e => this.setState({nominal_inventaris: e.target.value})} />
                                            </div>
                                            <div className="mb-1">
                                                <label className="form-label">Supplier</label>
                                                <input type="text" className="form-control" value={this.state.supplier} onChange={e => this.setState({supplier: e.target.value})} />
                                            </div>
                                            <div className="mb-1">
                                                <label className="form-label">Metode Penyusutan <code>(required)</code></label>
                                                <Select
                                                    options={Helpers.optionsPenyusutan}
                                                    value={Helpers.optionsPenyusutan.filter((item) => item.value === this.state.metode_penyusutan)}
                                                    onChange={event => this.handleChange(event,'metode_penyusutan')} />
                                            </div>
                                            <div className="mb-1">
                                                <label className="form-label">Soh/Nilai Buku</label>
                                                <input type="number" className="form-control"
                                                       value={this.state.nilai_buku}
                                                       onChange={e => this.setState({nilai_buku: e.target.value})}
                                                       readOnly={(this.state.getIdRekening) ? true : false}/>
                                            </div>
                                            <div className="mb-1">
                                                <label className="form-label">Status Rekening <code>(required)</code></label>
                                                <Select
                                                    options={this.state.dataStatusRekening}
                                                    value={this.state.dataStatusRekening.filter((item) => item.value === this.state.status_rekening)}
                                                    onChange={event => this.handleChange(event,'status_rekening')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="dropdown-divider"></div>
                                    <a className="btn btn-primary waves-effect waves-light" onClick={() => {this.submitData()}}>
                                        <i className="fe-save me-1"></i> Simpan
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DataRekeningForm;