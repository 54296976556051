import React from "react";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import $ from "jquery";
import {Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import {Connection} from "../../../config/Connection";

class ARO extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],
            startDate: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }
    }

    getData = () => {
        if(this.state.startDate){
            $(".loader-wrapper").css("display", "");
            //Helpers.getMasterData(this.state.sessToken,'rekening-deposito?search=status_rekening:20;tgl_jatuh_tempo:'+this.state.startDate+';aro:Ya&searchFields=status_rekening:in;tgl_jatuh_tempo:<&searchJoin=and&limit=0').then(res => {
            Helpers.getMasterData(this.state.sessToken,'rekening-deposito/filter/aro?tgl_jatuh_tempo='+this.state.startDate).then(res => {
                if(res.data){
                    this.setState({
                        dataSource: res.data
                    });
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }else{
            toast.error('Anda belum memilih periode');
        }
    };

    prosesData = () => {
        let urlencoded = new URLSearchParams();
        urlencoded.append("tgl_trans", this.state.startDate);

        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + 'cron-job/deposito/aro', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => res.json()).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        toast.success(Helpers.MSG_SUCCESS);

                        setTimeout(function() {
                            window.location.reload();
                        }, 1000);
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="..">Home</a></li>
                                        <li className="breadcrumb-item">Back Office</li>
                                        <li className="breadcrumb-item">Deposito</li>
                                        <li className="breadcrumb-item active">ARO</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">ARO</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label">Tanggal</label>
                                            <input type="date" className="form-control" value={this.state.startDate} onChange={e => this.setState({startDate: e.target.value})} />
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.getData()}}>
                                                Filter
                                            </a>
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.prosesData()}}>
                                                Proses
                                            </a>
                                        </div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'No. Rekening',
                                                        field: 'no_rekening',
                                                    },
                                                    {
                                                        label: 'No Bilyet',
                                                        field: 'no_bilyet',
                                                    },
                                                    {
                                                        label: 'Nama Nasabah',
                                                        field: 'nama_nasabah',
                                                    },
                                                    {
                                                        label: 'Alamat',
                                                        field: 'alamat_nasabah',
                                                    },
                                                    {
                                                        label: 'Bank',
                                                        field: 'deskripsi_jenis_deposito'
                                                    },
                                                    {
                                                        label: 'Saldo',
                                                        field: 'saldo_akhir'
                                                    },
                                                    {
                                                        label: 'Tanggal Registrasi',
                                                        field: 'tgl_registrasi'
                                                    },
                                                    {
                                                        label: 'Tanggal Jatuh Tempo',
                                                        field: 'tgl_jatuh_tempo'
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    return {
                                                        ...row,
                                                        nama_nasabah: row.nasabah.nama,
                                                        alamat_nasabah: row.nasabah.alamat,
                                                        deskripsi_jenis_deposito: row.jenis_deposito.deskripsi_jenis_deposito
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ARO;