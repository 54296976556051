import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../../config/Connection";
import ModalReport from "../../../../components/pdf/laporan/tabungan/ModalReport";
import ModalKodepos from "../../../../components/ModalKodepos";

class Posting extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],
            setModal: '',
            startDate: '',
            endDate: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }
    }

    getData = () => {
        if(this.state.startDate && this.state.endDate){
            let urlencoded = new URLSearchParams();
            urlencoded.append("tgl_trans_start", this.state.startDate);
            urlencoded.append("tgl_trans_end", this.state.endDate);

            $(".loader-wrapper").css("display", "");
            fetch(Connection.API_URL + 'akuntansi-jurnal', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept-Language': 'id',
                    'Cache-Control': 'no-cache',
                    'Authorization': 'Bearer ' + this.state.sessToken
                },
                body: urlencoded
            }).then((res) => res.json()).then((json) => {
                if(json.exception){
                    toast.error(json.message);
                }else{
                    if(json.errors){
                        alert(Helpers.customError(json.errors));
                    }else{
                        if(json.status === 'error'){
                            toast.error(json.message);
                        }else{
                            this.setState({
                                dataSource: json.data
                            });
                        }
                    }
                }

                $(".loader-wrapper").css("display", "none");
            }).catch((e) => {
                $(".loader-wrapper").css("display", "none");
            });
        }else{
            toast.error('Anda belum memilih periode');
        }
    };

    render() {
        return (
            <div className="content">
                { this.state.setModal }

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Akuntansi</li>
                                        <li className="breadcrumb-item">Pembukuan</li>
                                        <li className="breadcrumb-item">Administrasi</li>
                                        <li className="breadcrumb-item active">Posting</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Posting</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label">Tanggal</label>
                                            <input type="date" className="form-control" value={this.state.startDate} onChange={e => this.setState({startDate: e.target.value})} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Ke</label>
                                            <input type="date" className="form-control" value={this.state.endDate} onChange={e => this.setState({endDate: e.target.value})} />
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.getData()}}>
                                                Proses
                                            </a>
                                        </div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'Jurnal Type',
                                                        field: 'jurnal_type',
                                                    },
                                                    {
                                                        label: 'Start Time',
                                                        field: 'start_time',
                                                    },
                                                    {
                                                        label: 'End Time',
                                                        field: 'end_time',
                                                    },
                                                    {
                                                        label: 'Diff In Microsecond',
                                                        field: 'diff_in_microsecond',
                                                    },
                                                    {
                                                        label: 'Diff In Second',
                                                        field: 'diff_in_second',
                                                    },
                                                    {
                                                        label: 'Total Transactions',
                                                        field: 'total_transactions',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    return {
                                                        ...row
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Posting;