import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";
import ModalReport from "../../../components/pdf/laporan/tabungan/ModalReport";
import ModalKodepos from "../../../components/ModalKodepos";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {Document, Page} from "react-pdf";

class JurnalUmumBaru extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            urlParam: queryString.parse(window.location.search),
            dataSource: [],
            trans_id: '',
            cabang_kode: Helpers.DEF_COMPANY_CODE,
            cabang_nama: Helpers.DEF_COMPANY,
            tgl_trans: '',
            keterangan: '',
            reff: '',
            transactions: [],
            dataPerkiraan: [],

            isModalOpen: false,
            reportStream: null,
            pdfWidth: 0
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getMasterData('kode-perkiraan?limit=0&search=type:D','dataPerkiraan','kode_perk',['kode_perk','nama_perk']);
            this.viewTable();
        }
    }

    getMasterData = (endpoint,state,value,label) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                });
            }
        });
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value });

        if(state === 'my_kode_trans') {
            this.setState({
                my_kode_trans_label: event.label,
                transactions: []
            }, () => {
                this.viewTable();
            });
        }
    };

    generateTable = () => {
        this.state.transactions.push({
            "kode_perk": "",
            "deskripsi": "",
            "debet": 0,
            "kredit": 0,
            "uraian": ""
        });

        this.viewTable();
    };

    viewTable = () => {
        const tableTransaksi = this.state.transactions.map((object, i) => {
            return <tr>
                <td>
                    <a className="btn btn-danger btn-xs waves-effect waves-light"
                       onClick={() => {
                           this.deleteByRow(i)
                       }}>
                        <i className="fe-trash"></i>
                    </a>
                </td>
                {
                    (this.state.my_kode_trans === '100' || this.state.my_kode_trans === '390') && (
                        <td>{object.kode_perk}</td>
                    )
                }
                {
                    (this.state.my_kode_trans !== '100' && this.state.my_kode_trans !== '390') && (
                        <td width="20%">
                            <Select
                                options={this.state.dataPerkiraan}
                                value={this.state.dataPerkiraan.filter((item) => item.value === this.state.transactions[i].kode_perk)}
                                onChange={event => this.changeByRow(i,'kode_perk', event)} />
                        </td>
                    )
                }
                <td>{object.deskripsi}</td>
                <td>
                    <input type="number" className="form-control"
                           value={this.state.transactions[i].debet}
                           onChange={e => this.changeByRow(i,'debet', e.target.value)} />
                </td>
                <td>
                    <input type="number" className="form-control"
                           value={this.state.transactions[i].kredit}
                           onChange={e => this.changeByRow(i,'kredit', e.target.value)} />
                </td>
                <td>
                    <input type="text" className="form-control"
                           value={this.state.transactions[i].uraian}
                           onChange={e => this.changeByRow(i,'uraian', e.target.value)} />
                </td>
            </tr>
        });

        this.setState({
            tableTransaksi
        })
    };

    changeByRow = (i,key,val) => {
        const objArr = [...this.state.transactions];
        objArr[i] = {
            "kode_perk": (key === 'kode_perk') ? val.value : objArr[i].kode_perk,
            "deskripsi": (key === 'kode_perk') ? val.label : objArr[i].deskripsi,
            "debet": (key === 'debet') ? val : objArr[i].debet,
            "kredit": (key === 'kredit') ? val : objArr[i].kredit,
            "uraian": (key === 'uraian') ? val : objArr[i].uraian,
        };
        this.setState({transactions: objArr}, () => {
            this.viewTable();
        });
    };

    deleteByRow = (i) => {
        let objArr = [...this.state.transactions].filter((item, index) =>
            index !== i
        );
        this.setState({transactions: objArr}, () => {
            this.viewTable();
        });
    };

    saveTransaksi = () => {
        let urlencoded = new URLSearchParams();
        if(this.state.trans_id){
            urlencoded.append("trans_id", this.state.trans_id);
        }
        urlencoded.append("id_cabang", this.state.cabang_kode);
        urlencoded.append("tgl_trans", this.state.tgl_trans);
        urlencoded.append("keterangan", this.state.keterangan);
        urlencoded.append("reff", this.state.reff);
        urlencoded.append("transactions", JSON.stringify({
            details: this.state.transactions
        }));

        $(".loader-wrapper").css("display", "");
        let getResponseCode = null;
        fetch(Connection.API_URL + 'akuntansi-jurnal/manual', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => {
            getResponseCode = res.status;
            return res.json();
        }).then((json) => {
            if(getResponseCode !== 200){
                toast.error(json.message);
            }else{
                if(json.exception){
                    toast.error(json.message);
                }else{
                    if(json.errors){
                        alert(Helpers.customError(json.errors));
                    }else{
                        if(json.status === 'error'){
                            toast.error(json.message);
                        }else{
                            toast.success(Helpers.MSG_SUCCESS);

                            /*this.setState({
                                trans_id: json.data[0].trans_id
                            });*/

                            setTimeout(function() {
                                window.location.href = './akuntansi-jurnalumum-baru';
                            }, 1000);
                        }
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        return (
            <div className="content">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Akuntansi</li>
                                        <li className="breadcrumb-item">Pembukuan</li>
                                        <li className="breadcrumb-item">Jurnal Umum</li>
                                        <li className="breadcrumb-item active">Jurnal Umum Baru</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Jurnal Umum Baru</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-4">
                                    <div className="mb-1">
                                        <label className="form-label">Trans Id</label>
                                        <input type="number" className="form-control" value={this.state.trans_id} />
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="form-label">Akun Cabang</label>
                                            <input type="text" className="form-control" readOnly={true} value={this.state.cabang_kode} />
                                        </div>
                                        <div className="col-md-8">
                                            <label className="form-label">&nbsp;</label>
                                            <input type="text" className="form-control" readOnly={true} value={this.state.cabang_nama} />
                                        </div>
                                    </div>
                                    <div className="mb-1">
                                        <label className="form-label">Tgl Transaksi</label>
                                        <input type="date" className="form-control" value={this.state.tgl_trans} onChange={e => this.setState({tgl_trans: e.target.value})} />
                                    </div>
                                    <div className="mb-1">
                                        <label className="form-label">Keterangan</label>
                                        <textarea className="form-control" rows={3} onChange={e => this.setState({keterangan: e.target.value})}>{this.state.keterangan}</textarea>
                                    </div>
                                    <div className="mb-1">
                                        <label className="form-label">Reff</label>
                                        <input type="text" className="form-control" value={this.state.reff} onChange={e => this.setState({reff: e.target.value})} />
                                    </div>
                                </div>
                                <div className={'col-8'}>
                                    <div className="mb-1">
                                        <label className="form-label">Transaksi</label>
                                    </div>
                                    <table className="table" width="100%">
                                        <thead className="table-light">
                                        <tr>
                                            <th>
                                                <a className="btn btn-info btn-xs waves-effect waves-light"
                                                   onClick={() => {
                                                       this.generateTable()
                                                   }}>
                                                    <i className="fe-plus"></i>
                                                </a>
                                            </th>
                                            <th>Kode Perkiraan</th>
                                            <th>Deskripsi</th>
                                            <th>Debet</th>
                                            <th>Kredit</th>
                                            <th>Uraian</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.tableTransaksi
                                        }
                                        </tbody>
                                    </table>
                                    <div className="mt-3">
                                        <a className="btn btn-primary waves-effect waves-light mr-5" onClick={() => {this.saveTransaksi()}}>
                                            <i className="fe-save me-1"></i> Simpan
                                        </a>
                                        <a className="btn btn-danger waves-effect waves-light mr-5" onClick={() => {window.location.reload()}}>
                                            <i className="fe-trash me-1"></i> Bersihkan
                                        </a>
                                        <a className="btn btn-info waves-effect waves-light mr-5" onClick={() => {this.handleItemPrint()}}>
                                            <i className="fe-printer me-1"></i> Cetak Kuitansi
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JurnalUmumBaru;