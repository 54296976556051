import React from "react";
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import $ from "jquery";
import {Helpers} from "../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../config/Connection";
import ModalKodepos from "../../components/ModalKodepos";

class DataNasabahForm extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: '',
            urlParam: queryString.parse(window.location.search),
            jn_kantor_kode: Helpers.DEF_COMPANY_CODE,
            jn_kantor_nama: Helpers.DEF_COMPANY,
            black_list: 0,
            rating_perusahaan: 0,
            kode_lembaga_rating: '13',
            go_public: 0,

            getIdNasabah: '',
            nasabah_id: '',
            no_alternatif: '',

            dataJenisNasabah: JSON.parse(ReactSession.get("bprsess_dataJenisNasabah")),
            dataKota: JSON.parse(ReactSession.get("bprsess_dataKota")),
            dataKodepos: [],
            dataBidangUsaha: JSON.parse(ReactSession.get("bprsess_dataBidangUsaha")),
            dataNegara: JSON.parse(ReactSession.get("bprsess_dataNegara")),
            dataLokasi: JSON.parse(ReactSession.get("bprsess_dataLokasi")),
            dataGolDebitur: JSON.parse(ReactSession.get("bprsess_dataGolDebitur")),
            dataPemilik: JSON.parse(ReactSession.get("bprsess_dataPemilik")),
            dataGolKreditur: JSON.parse(ReactSession.get("bprsess_dataGolKreditur")),
            dataSlikPihakTiga: JSON.parse(ReactSession.get("bprsess_dataSlikPihakTiga")),
            dataSlikHubungan: JSON.parse(ReactSession.get("bprsess_dataSlikHubungan")),
            dataBank: JSON.parse(ReactSession.get("bprsess_dataBank")),
            dataJenisIdentitas: JSON.parse(ReactSession.get("bprsess_dataJenisIdentitas")),
            dataJenisKelamin: JSON.parse(ReactSession.get("bprsess_dataJenisKelamin")),
            dataStatusPerkawinan: JSON.parse(ReactSession.get("bprsess_dataStatusPerkawinan")),
            dataAgama: JSON.parse(ReactSession.get("bprsess_dataAgama")),
            dataStatusRumah: JSON.parse(ReactSession.get("bprsess_dataStatusRumah")),
            dataPekerjaan: JSON.parse(ReactSession.get("bprsess_dataPekerjaan")),
            dataSumberPenghasilan: JSON.parse(ReactSession.get("bprsess_dataSumberPenghasilan")),
            dataLembagaRating: JSON.parse(ReactSession.get("bprsess_dataLembagaRating")),

            setModalKodepos: '',
            setModalKodeposDom: '',
            setModalKodeposCom: '',

            dataAPUPPTSoal: [],
            dataAPUPPT: [],
            dataAPUPPTScoring: []
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.setState({
                getIdNasabah: (this.state.urlParam.ref) ? this.state.urlParam.ref : '',
            }, () => {
                this.getDataAPUPPTJawaban();
                this.getDataAPUPPTSoal();

                if(this.state.getIdNasabah){
                    this.getData();
                }
            });
        }
    }

    getMasterData = (endpoint,state,value,label) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: item[label]
                        };
                    })
                });
            }
        })
    };

    getDataAPUPPTSoal = () => {
        Helpers.getMasterData(this.state.sessToken,'apu-soal?limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataAPUPPTSoal: res.data
                }, () => {
                    const entries = document.querySelector('[data-test="mdb-datatable-entries"]');
                    const row = entries.parentNode.parentNode.parentNode;

                    row.style.display = 'none';
                });
            }
        })
    };

    getDataAPUPPTJawaban = () => {
        Helpers.getMasterData(this.state.sessToken,'apu-jawab?limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataAPUPPT: res.data.map(function(item) {
                        return {
                            value: item.real_id,
                            label: item.nilai + ' - ' + item.deskripsi,
                            no_soal: item.no_soal,
                            nilai: item.nilai
                        };
                    })
                });
            }
        })
    };

    countNilaiAPUPPT = () => {
        let data_length = this.state.dataAPUPPTSoal.length;

        if(data_length>0){
            let apuppt_nilai_raw = 0.0;
            let apuppt_nilai = 0.0;
            for(let i=1; i<=data_length; i++){
                let findValue = this.state.dataAPUPPT.filter((item) => item.value === this.state['apu_scoring_'+i.toString()])[0];

                if (findValue !== undefined && findValue !== null) {
                    findValue = findValue.nilai;

                    this.setState({
                        ['apu_scoring_nilai_' + i.toString()]: findValue
                    }, () => {
                        apuppt_nilai_raw += findValue;
                        apuppt_nilai = parseFloat(apuppt_nilai_raw/data_length).toFixed(2);

                        this.setState({
                            apuppt_nilai: apuppt_nilai,
                            apuppt_kesimpulan: (apuppt_nilai < 1.5) ? 'Resiko Rendah' : (apuppt_nilai >= 1.5 && apuppt_nilai < 2.5) ? 'Resiko Sedang' : 'Resiko Tinggi'
                        });
                    });
                }

            }
        }
    };

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getDataNasabahDetail(this.state.sessToken,this.state.getIdNasabah).then(res => {
            if(res.data){
                const data = res.data;
                //console.log(data);
                this.setState({
                    dataSource: data,
                    nasabah_id: data.id_nasabah,
                    nama_nasabah: data.nama_nasabah,
                    nama_alias: data.nama_alias,
                    jenis_nasabah: data.jenis_nasabah,
                    black_list: data.black_list,
                    black_list_note: data.black_list_note,
                    alamat: data.alamat,
                    kode_kota: data.kota.kode_kota,
                    kodepos: data.kodepos.id_pos,
                    alamat_domisili: data.alamat_domisili,
                    kota_domisili: data.kota_domisili.kode_kota,
                    id_pos_domisili: data.id_pos_domisili,

                    telpon: data.telpon,
                    handphone: data.handphone,
                    bidang_usaha_perusahaan: data.bidang_usaha_perusahaan,
                    kode_negara: data.negara.kode_negara,
                    tgl_registrasi: moment(data.tgl_registrasi).utc().format('YYYY-MM-DD'),
                    npwp: data.npwp,
                    email: data.email,
                    status_ebanking: data.status_ebanking,

                    kode_lokasi: data.kode_lokasi,
                    kode_gol_debitur: data.kode_gol_debitur,
                    kode_pemilik: data.kode_pemilik,
                    bank_terkait: data.bank_terkait,
                    kode_gol_kreditur: data.kode_gol_kreditur,
                    sid_gol_debitur: data.sid_gol_debitur,
                    sid_hubungan_ljk: data.sid_hubungan_ljk,

                    bank_tabungan_1: this.checkNull(data.bank_tabungan_1),
                    bank_tabungan_2: this.checkNull(data.bank_tabungan_2),
                    bank_tabungan_3: this.checkNull(data.bank_tabungan_3),
                    no_rek_tabungan_1: this.checkNull(data.no_rek_tabungan_1),
                    no_rek_tabungan_2: this.checkNull(data.no_rek_tabungan_2),
                    no_rek_tabungan_3: this.checkNull(data.no_rek_tabungan_3),

                    bank_kartu_kredit_1: this.checkNull(data.bank_kartu_kredit_1),
                    bank_kartu_kredit_2: this.checkNull(data.bank_kartu_kredit_2),
                    bank_kartu_kredit_3: this.checkNull(data.bank_kartu_kredit_3),
                    no_kartu_kredit_1: this.checkNull(data.no_kartu_kredit_1),
                    no_kartu_kredit_2: this.checkNull(data.no_kartu_kredit_2),
                    no_kartu_kredit_3: this.checkNull(data.no_kartu_kredit_3),

                    jenis_identitas: data.jenis_identitas,
                    no_id: data.no_id,
                    tgl_id: data.tgl_id,
                    pass_id: data.pass_id,
                    tgl_passpor: data.tgl_passpor,
                    tempat_lahir: data.tempat_lahir,
                    tgl_lahir: data.tgl_lahir,
                    jenis_kelamin: data.jenis_kelamin,
                    status_perkawinan: data.status_perkawinan,
                    kode_agama: data.agama.kode_agama,
                    ibu_kandung: data.ibu_kandung,
                    status_rumah_tinggal: data.status_rumah_tinggal,
                    tgl_rumah_tinggal: data.tgl_rumah_tinggal,

                    nama_pasangan: data.nama_pasangan,
                    tempat_lahir_pasangan: data.tempat_lahir_pasangan,
                    tgl_lahir_pasangan: data.tgl_lahir_pasangan,
                    no_id_pasangan: data.no_id_pasangan,
                    tgl_id_pasangan: data.tgl_id_pasangan,
                    tgl_nikah: data.tgl_nikah,
                    handphone_pasangan: data.handphone_pasangan,
                    pekerjaan_pasangan: data.pekerjaan_pasangan,

                    kode_pekerjaan: (data.kode_pekerjaan) ? data.kode_pekerjaan.kode_pekerjaan : null,
                    nama_perusahaan: data.nama_perusahaan,
                    alamat_perusahaan: data.alamat_perusahaan,
                    id_pos_perusahaan: data.id_pos_perusahaan,
                    telpon_perusahaan: data.telpon_perusahaan,
                    fax_perusahaan: data.fax_perusahaan,
                    sumber_penghasilan: data.sumber_penghasilan,
                    jml_penghasilan_per_tahun: data.jml_penghasilan_per_tahun,
                    jml_tanggungan: data.jml_tanggungan,

                    apu_scoring_1: (data.apu_scoring.length > 0) ? data.apu_scoring[0].no_jawab : null,
                    apu_scoring_2: (data.apu_scoring.length > 0) ? data.apu_scoring[1].no_jawab : null,
                    apu_scoring_3: (data.apu_scoring.length > 0) ? data.apu_scoring[2].no_jawab : null,
                    apu_scoring_4: (data.apu_scoring.length > 0) ? data.apu_scoring[3].no_jawab : null,
                    apu_scoring_5: (data.apu_scoring.length > 0) ? data.apu_scoring[4].no_jawab : null,
                    apu_scoring_6: (data.apu_scoring.length > 0) ? data.apu_scoring[5].no_jawab : null,
                    apu_scoring_7: (data.apu_scoring.length > 0) ? data.apu_scoring[6].no_jawab : null,
                    tgl_proses: (data.apu_scoring.length > 0) ? data.apu_scoring[0].tgl_proses : null,
                    tgl_penilaian: data.tgl_penilaian,
                    dataAPUPPTScoring: data.apu_scoring,

                    tanda_daftar_perusahaan: data.tanda_daftar_perusahaan,
                    tempat_pendirian_perusahaan: data.tempat_pendirian_perusahaan,
                    no_akte_awal: data.no_akte_awal,
                    tgl_akte_awal: data.tgl_akte_awal,
                    no_akte_akhir: data.no_akte_akhir,
                    tgl_akte_akhir: data.tgl_akte_akhir,
                    surat_kemenkumham: data.surat_kemenkumham,
                    tgl_kemenkumham: data.tgl_kemenkumham,
                    surat_izin_usaha_perusahaan: data.surat_izin_usaha_perusahaan,
                    surat_domisili_perusahaan: data.surat_domisili_perusahaan,
                    group_perusahaan: data.group_perusahaan,
                    surat_kuasa_perusahaan: data.surat_kuasa_perusahaan,
                    nama_kuasa_perusahaan_1: data.nama_kuasa_perusahaan_1,
                    alamat_kuasa_perusahaan_1: data.alamat_kuasa_perusahaan_1,
                    noid_kuasa_perusahaan_1: data.noid_kuasa_perusahaan_1,
                    jabatan_kuasa_perusahaan_1: data.jabatan_kuasa_perusahaan_1,
                    nama_kuasa_perusahaan_2: data.nama_kuasa_perusahaan_2,
                    alamat_kuasa_perusahaan_2: data.alamat_kuasa_perusahaan_2,
                    noid_kuasa_perusahaan_2: data.noid_kuasa_perusahaan_2,
                    jabatan_kuasa_perusahaan_2: data.jabatan_kuasa_perusahaan_2,

                    go_public: data.go_public,
                    tgl_rating: data.tgl_rating,
                    kode_lembaga_rating: data.kode_lembaga_rating.kode_lembaga_rating,
                    rating_perusahaan: data.rating_perusahaan
                }, () => {
                    this.countNilaiAPUPPT();

                    if(this.state.jenis_nasabah === '10' || this.state.jenis_nasabah === '11'){
                        $("#tab-perorangan").css("display", "");
                        $("#tab-company").css("display", "none");
                    }else{
                        $("#tab-perorangan").css("display", "none");
                        $("#tab-company").css("display", "");
                    }
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    handleChange = (event,state,modalstate) => {
        this.setState({ [state]: event.value, [modalstate]: '' }, () => {
            if(state.substring(0,3) === 'apu'){
                this.countNilaiAPUPPT();
            }
            if(state === 'jenis_nasabah'){
                if(event.value === '10' || event.value === '11'){
                    $("#tab-perorangan").css("display", "");
                    $("#tab-company").css("display", "none");
                }else{
                    $("#tab-perorangan").css("display", "none");
                    $("#tab-company").css("display", "");
                }
            }
        });
    };

    toggleCheckbox = (state) => {
        this.setState({ [state]: (this.state[state] === 0) ? 1 : 0 });
    };

    handleImageChange = (event,state) => {
        this.setState({
            [state]: URL.createObjectURL(event.target.files[0])
        })
    };

    handleModalClick = (modalstate,valuestate,component) => {
        this.setState({
            [modalstate]: ''
        }, () => {
            const ModalComponent = component;

            this.setState({
                [modalstate]: <ModalComponent modalRef={true} onSelectData={event => this.handleChange(event,valuestate,modalstate)}/>
            })
        })
    };

    checkNull = (val) => {
        return (val !== null) ? val : '';
    };

    submitData = () => {
        if(this.state.getIdNasabah){
            this.saveNasabah('PATCH','nasabah/'+this.state.getIdNasabah);
        }else{
            this.saveNasabah('POST','nasabah');
        }
    };

    saveNasabah = (method,endpoint) => {
        let urlencoded = new URLSearchParams();
        if(this.state.nasabah_id){
            urlencoded.append("nasabah_id", this.state.nasabah_id);
        }
        if(this.state.no_alternatif){
            urlencoded.append("no_alternatif", this.state.no_alternatif);
        }
        urlencoded.append("nama_nasabah", this.state.nama_nasabah);
        urlencoded.append("nama_alias", Helpers.castEmpty(this.state.nama_alias));
        urlencoded.append("alamat", this.state.alamat);
        urlencoded.append("id_pos", this.state.kodepos);
        urlencoded.append("kode_kota", this.state.kode_kota);
        urlencoded.append("alamat_domisili", this.state.alamat_domisili);
        urlencoded.append("id_pos_domisili", this.state.id_pos_domisili);
        urlencoded.append("kode_kota_domisili", this.state.kota_domisili);
        urlencoded.append("telpon", Helpers.castEmpty(this.state.telpon));
        urlencoded.append("jenis_kelamin", this.state.jenis_kelamin);
        urlencoded.append("pekerjaan", ""); //
        urlencoded.append("kode_pekerjaan", this.state.kode_pekerjaan);
        urlencoded.append("tempat_lahir", this.state.tempat_lahir);
        urlencoded.append("tgl_lahir", this.state.tgl_lahir);
        urlencoded.append("kode_gelar", "0104");
        urlencoded.append("jenis_identitas", this.state.jenis_identitas);
        urlencoded.append("no_id", this.state.no_id);
        urlencoded.append("npwp", Helpers.castEmpty(this.state.npwp));
        urlencoded.append("shu_pinjaman", "");
        urlencoded.append("shu_simpanan", "");
        urlencoded.append("ibu_kandung", this.state.ibu_kandung);
        urlencoded.append("keterangan_gelar", "");
        urlencoded.append("no_din", "");
        urlencoded.append("sid_gol_debitur", this.state.sid_gol_debitur);
        urlencoded.append("nama_perusahaan", this.state.nama_perusahaan);
        urlencoded.append("kode_bidang_usaha_perusahaan", "504000");
        urlencoded.append("kode_negara", this.state.kode_negara);
        urlencoded.append("sid_hubungan_ljk", this.state.sid_hubungan_ljk);
        urlencoded.append("jenis_nasabah", this.state.jenis_nasabah);
        urlencoded.append("tgl_id", Helpers.castEmpty(this.state.tgl_id));
        urlencoded.append("black_list", Helpers.castEmpty(this.state.black_list));
        urlencoded.append("tgl_registrasi", this.state.tgl_registrasi);
        urlencoded.append("surat_izin_usaha_perusahaan", Helpers.castEmpty(this.state.surat_izin_usaha_perusahaan));
        urlencoded.append("tanda_daftar_perusahaan", Helpers.castEmpty(this.state.tanda_daftar_perusahaan));
        urlencoded.append("surat_domisili_perusahaan", Helpers.castEmpty(this.state.surat_domisili_perusahaan));
        urlencoded.append("surat_kuasa_perusahaan", Helpers.castEmpty(this.state.surat_kuasa_perusahaan));
        urlencoded.append("surat_kemenkumham", Helpers.castEmpty(this.state.surat_kemenkumham));
        urlencoded.append("tgl_kemenkumham", Helpers.castEmpty((this.state.tgl_kemenkumham) ? this.state.tgl_kemenkumham : '1990-01-01'));
        urlencoded.append("handphone", this.state.handphone);
        urlencoded.append("alamat_perusahaan", this.state.alamat_perusahaan);
        urlencoded.append("id_pos_perusahaan", this.state.id_pos_perusahaan);
        urlencoded.append("telpon_perusahaan", this.state.telpon_perusahaan);
        urlencoded.append("fax_perusahaan", Helpers.castEmpty(this.state.fax_perusahaan));
        urlencoded.append("group_perusahaan", Helpers.castEmpty(this.state.group_perusahaan));
        urlencoded.append("tempat_pendirian_perusahaan", Helpers.castEmpty(this.state.tempat_pendirian_perusahaan));
        urlencoded.append("bank_tabungan_1", Helpers.castEmpty(this.state.bank_tabungan_1));
        urlencoded.append("bank_tabungan_2", Helpers.castEmpty(this.state.bank_tabungan_2));
        urlencoded.append("bank_tabungan_3", Helpers.castEmpty(this.state.bank_tabungan_3));
        urlencoded.append("no_rek_tabungan_1", Helpers.castEmpty(this.state.no_rek_tabungan_1));
        urlencoded.append("no_rek_tabungan_2", Helpers.castEmpty(this.state.no_rek_tabungan_2));
        urlencoded.append("no_rek_tabungan_3", Helpers.castEmpty(this.state.no_rek_tabungan_3));
        urlencoded.append("bank_kartu_kredit_1", Helpers.castEmpty(this.state.bank_kartu_kredit_1));
        urlencoded.append("bank_kartu_kredit_2", Helpers.castEmpty(this.state.bank_kartu_kredit_2));
        urlencoded.append("bank_kartu_kredit_3", Helpers.castEmpty(this.state.bank_kartu_kredit_3));
        urlencoded.append("no_kartu_kredit_1", Helpers.castEmpty(this.state.no_kartu_kredit_1));
        urlencoded.append("no_kartu_kredit_2", Helpers.castEmpty(this.state.no_kartu_kredit_2));
        urlencoded.append("no_kartu_kredit_3", Helpers.castEmpty(this.state.no_kartu_kredit_3));
        urlencoded.append("black_list_note", Helpers.castEmpty(this.state.black_list_note));
        urlencoded.append("tgl_buka", "");
        urlencoded.append("kode_bank", "");
        urlencoded.append("bank_terkait", this.state.bank_terkait);
        urlencoded.append("kode_gol_debitur", this.state.kode_gol_debitur);
        urlencoded.append("kode_gol_kreditur", this.state.kode_gol_kreditur);
        urlencoded.append("kode_lokasi", this.state.kode_lokasi);
        urlencoded.append("kode_pemilik", this.state.kode_pemilik);
        urlencoded.append("no_paspor", Helpers.castEmpty(this.state.pass_id));
        urlencoded.append("no_akte_awal", Helpers.castEmpty(this.state.no_akte_awal));
        urlencoded.append("tgl_akte_awal", Helpers.castEmpty((this.state.tgl_akte_awal) ? this.state.tgl_akte_awal : '1990-01-01'));
        urlencoded.append("tgl_berlaku_paspor", Helpers.castEmpty((this.state.tgl_passpor) ? this.state.tgl_passpor : '1990-01-01'));
        urlencoded.append("shu_simpanan_pendiri", "");
        urlencoded.append("shu_pinjaman_pendiri", "");
        urlencoded.append("tgl_passpor", Helpers.castEmpty((this.state.tgl_passpor) ? this.state.tgl_passpor : '1990-01-01'));
        urlencoded.append("status_perkawinan", this.state.status_perkawinan);
        urlencoded.append("jml_tanggungan", Helpers.castEmpty(this.state.jml_tanggungan));
        urlencoded.append("nama_pasangan", Helpers.castEmpty(this.state.nama_pasangan));
        urlencoded.append("tempat_lahir_pasangan", Helpers.castEmpty(this.state.tempat_lahir_pasangan));
        urlencoded.append("tgl_lahir_pasangan", Helpers.castEmpty((this.state.tgl_lahir_pasangan) ? this.state.tgl_lahir_pasangan : '1990-01-01'));
        urlencoded.append("no_id_pasangan", Helpers.castEmpty(this.state.no_id_pasangan));
        urlencoded.append("tgl_id_pasangan", Helpers.castEmpty((this.state.tgl_id_pasangan) ? this.state.tgl_id_pasangan : '1990-01-01'));
        urlencoded.append("handphone_pasangan", Helpers.castEmpty(this.state.handphone_pasangan));
        urlencoded.append("pekerjaan_pasangan", Helpers.castEmpty(this.state.pekerjaan_pasangan));
        urlencoded.append("tgl_nikah", Helpers.castEmpty((this.state.tgl_nikah) ? this.state.tgl_nikah : '1990-01-01'));
        urlencoded.append("agama", this.state.kode_agama);
        urlencoded.append("langgar_BMPK", "");
        urlencoded.append("lampui_BMPK", "");
        urlencoded.append("rating_perusahaan", Helpers.castEmpty(this.state.rating_perusahaan));
        urlencoded.append("kode_lembaga_rating", this.state.kode_lembaga_rating);
        urlencoded.append("tgl_rating", Helpers.castEmpty((this.state.tgl_rating) ? this.state.tgl_rating : '1990-01-01'));
        urlencoded.append("go_public", Helpers.castEmpty(this.state.go_public));
        urlencoded.append("status_rumah_tinggal", this.state.status_rumah_tinggal);
        urlencoded.append("tgl_rumah_tinggal", Helpers.castEmpty((this.state.tgl_rumah_tinggal) ? this.state.tgl_rumah_tinggal : '1990-01-01'));
        urlencoded.append("status_ebanking", Helpers.castEmpty(this.state.status_ebanking));
        urlencoded.append("id_mobile", "");
        urlencoded.append("sumber_penghasilan", this.state.sumber_penghasilan);
        urlencoded.append("jml_penghasilan_per_tahun", Helpers.castEmpty(this.state.jml_penghasilan_per_tahun));
        urlencoded.append("email", this.state.email);
        urlencoded.append("nama_kuasa_perusahaan_1", Helpers.castEmpty(this.state.nama_kuasa_perusahaan_1));
        urlencoded.append("alamat_kuasa_perusahaan_1", Helpers.castEmpty(this.state.alamat_kuasa_perusahaan_1));
        urlencoded.append("noid_kuasa_perusahaan_1", Helpers.castEmpty(this.state.noid_kuasa_perusahaan_1));
        urlencoded.append("nama_kuasa_perusahaan_2", Helpers.castEmpty(this.state.nama_kuasa_perusahaan_2));
        urlencoded.append("alamat_kuasa_perusahaan_2", Helpers.castEmpty(this.state.alamat_kuasa_perusahaan_2));
        urlencoded.append("noid_kuasa_perusahaan_2", Helpers.castEmpty(this.state.noid_kuasa_perusahaan_2));
        urlencoded.append("jabatan_kuasa_perusahaan_2", Helpers.castEmpty(this.state.jabatan_kuasa_perusahaan_2));
        urlencoded.append("shu_uraian", "");
        urlencoded.append("apu_scoring", this.state.dataAPUPPTSoal.map((row,index) => {
            var apu_scoring = this.state['apu_scoring_' + (index+1).toString()]

            return apu_scoring;
        }));
        urlencoded.append("tgl_penilaian", this.state.tgl_penilaian);

        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => res.json()).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        toast.success(Helpers.MSG_SUCCESS);

                        setTimeout(function() {
                            window.location.href = './data-nasabah';
                        }, 1000);
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        return (
            <div className="content">
                { this.state.setModalKodepos }
                { this.state.setModalKodeposDom }
                { this.state.setModalKodeposCom }

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Customer Service</li>
                                        <li className="breadcrumb-item"><a href="./data-nasabah">Data Nasabah</a></li>
                                        <li className="breadcrumb-item active">Form</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Data Nasabah Form</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary waves-effect waves-light" onClick={() => {this.submitData()}}>
                                        <i className="fe-save me-1"></i> Simpan
                                    </a>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-bordered">
                                        <li className="nav-item">
                                            <a href="#form-1" data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                                Data Utama
                                            </a>
                                        </li>
                                        <li className="nav-item" id="tab-perorangan" style={{'display': 'none'}}>
                                            <a href="#form-2" data-bs-toggle="tab" aria-expanded="true" className="nav-link">
                                                Perorangan
                                            </a>
                                        </li>
                                        <li className="nav-item" id="tab-company" style={{'display': 'none'}}>
                                            <a href="#form-2a" data-bs-toggle="tab" aria-expanded="true" className="nav-link">
                                                Khusus Badan Usaha,Bank,Koperasi
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-3" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                Rekening Di Bank Lain
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-4" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                Sandi BI
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-5" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                Specimen
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-6" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                APUPPT
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="form-1">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6">
                                                            <label className="form-label">Nasabah ID</label>
                                                            <input type="text" className="form-control" value={this.state.nasabah_id} onChange={e => this.setState({nasabah_id: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">Alternatif</label>
                                                            <input type="text" className="form-control" value={this.state.no_alternatif} onChange={e => this.setState({no_alternatif: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <h4 className="header-title">Detail Nasabah</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nama Lengkap <code>(required)</code></label>
                                                        <input type="text" className="form-control" value={this.state.nama_nasabah} onChange={e => this.setState({nama_nasabah: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nama Alias</label>
                                                        <input type="text" className="form-control" value={this.state.nama_alias} onChange={e => this.setState({nama_alias: e.target.value})} />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-4">
                                                            <label className="form-label">Kantor</label>
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.jn_kantor_kode} onChange={e => this.setState({jn_kantor_kode: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.jn_kantor_nama} onChange={e => this.setState({jn_kantor_nama: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Nasabah <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataJenisNasabah}
                                                            value={this.state.dataJenisNasabah.filter((item) => item.value === this.state.jenis_nasabah)}
                                                            onChange={event => this.handleChange(event,'jenis_nasabah')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Catatan</label>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input"
                                                                   checked={(this.state.black_list === 1) ? true : false}
                                                                   onClick={() => {this.toggleCheckbox('black_list')}}/>
                                                            <label className="form-check-label">Black List</label>
                                                        </div>
                                                        <textarea className="form-control" rows="3" value={this.state.black_list_note} onChange={e => this.setState({black_list_note: e.target.value})}></textarea>
                                                    </div>

                                                    <h4 className="header-title mt-3">Alamat</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Alamat <code>(required)</code></label>
                                                        <textarea className="form-control" rows="3" value={this.state.alamat} onChange={e => this.setState({alamat: e.target.value})}></textarea>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kota <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataKota}
                                                            value={this.state.dataKota.filter((item) => item.value === this.state.kode_kota)}
                                                            onChange={event => this.handleChange(event,'kode_kota')} />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <label className="form-label">Kode Pos <code>(required)</code></label>
                                                        <div className="input-group input-group-merge">
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.kodepos} />
                                                            <a className="input-group-text" onClick={() => {this.handleModalClick('setModalKodepos','kodepos',ModalKodepos)}}>
                                                                <span className="fe-search"></span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Alamat Domisili</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Alamat <code>(required)</code></label>
                                                        <textarea className="form-control" rows="3" value={this.state.alamat_domisili} onChange={e => this.setState({alamat_domisili: e.target.value})}></textarea>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kota <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataKota}
                                                            value={this.state.dataKota.filter((item) => item.value === this.state.kota_domisili)}
                                                            onChange={event => this.handleChange(event,'kota_domisili')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kode Pos <code>(required)</code></label>
                                                        <div className="input-group input-group-merge">
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.id_pos_domisili} />
                                                            <a className="input-group-text" onClick={() => {this.handleModalClick('setModalKodeposDom','id_pos_domisili',ModalKodepos)}}>
                                                                <span className="fe-search"></span>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <h4 className="header-title mt-3">Data Tambahan</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Telepon</label>
                                                        <input type="number" className="form-control" value={this.state.telpon} onChange={e => this.setState({telpon: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Fax / Handphone <code>(required)</code></label>
                                                        <input type="number" className="form-control" value={this.state.handphone} onChange={e => this.setState({handphone: e.target.value})} />
                                                    </div>
                                                    {/*<div className="row mb-1">*/}
                                                    {/*<label className="form-label">Pendidikan / Jenis Badan Usaha</label>*/}
                                                    {/*<Select*/}
                                                    {/*options={this.state.dataKota}*/}
                                                    {/*value={this.state.dataKota.filter((item) => item.value === this.state.kota_domisili)}*/}
                                                    {/*onChange={event => this.handleChange(event,'kota_domisili')} />*/}
                                                    {/*</div>*/}
                                                    <div className="mb-1">
                                                        <label className="form-label">Bidang Usaha</label>
                                                        <Select
                                                            options={this.state.dataBidangUsaha}
                                                            value={this.state.dataBidangUsaha.filter((item) => item.value === this.state.bidang_usaha_perusahaan)}
                                                            onChange={event => this.handleChange(event,'bidang_usaha_perusahaan')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Domisili Negara <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataNegara}
                                                            value={this.state.dataNegara.filter((item) => item.value === this.state.kode_negara)}
                                                            onChange={event => this.handleChange(event,'kode_negara')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tanggal Registrasi <code>(required)</code></label>
                                                        <input type="date" className="form-control" value={this.state.tgl_registrasi} onChange={e => this.setState({tgl_registrasi: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">NPWP</label>
                                                        <input type="number" className="form-control" value={this.state.npwp} onChange={e => this.setState({npwp: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">E-Mail <code>(required)</code></label>
                                                        <input type="email" className="form-control" value={this.state.email} onChange={e => this.setState({email: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Mendapat Fasilitas E-Banking</label>
                                                        <Select
                                                            options={Helpers.optionsPick}
                                                            value={Helpers.optionsPick.filter((item) => item.value === this.state.status_ebanking)}
                                                            onChange={event => this.handleChange(event,'status_ebanking')} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-2">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Data Pribadi</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Identitas <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataJenisIdentitas}
                                                            value={this.state.dataJenisIdentitas.filter((item) => item.value === this.state.jenis_identitas)}
                                                            onChange={event => this.handleChange(event,'jenis_identitas')} />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <label className="form-label">No. KTP/Expired <code>(required)</code></label>
                                                            <input type="number" className="form-control" value={this.state.no_id} onChange={e => this.setState({no_id: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="date" className="form-control" value={this.state.tgl_id} onChange={e => this.setState({tgl_id: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <label className="form-label">No. Paspor/Expired</label>
                                                            <input type="number" className="form-control" value={this.state.pass_id} onChange={e => this.setState({pass_id: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="date" className="form-control" value={this.state.tgl_passpor} onChange={e => this.setState({tgl_passpor: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <label className="form-label">Tempat/Tgl Lahir <code>(required)</code></label>
                                                            <input type="text" className="form-control" value={this.state.tempat_lahir} onChange={e => this.setState({tempat_lahir: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="date" className="form-control" value={this.state.tgl_lahir} onChange={e => this.setState({tgl_lahir: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Kelamin <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataJenisKelamin}
                                                            value={this.state.dataJenisKelamin.filter((item) => item.value === this.state.jenis_kelamin)}
                                                            onChange={event => this.handleChange(event,'jenis_kelamin')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Status <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataStatusPerkawinan}
                                                            value={this.state.dataStatusPerkawinan.filter((item) => item.value === this.state.status_perkawinan)}
                                                            onChange={event => this.handleChange(event,'status_perkawinan')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Agama <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataAgama}
                                                            value={this.state.dataAgama.filter((item) => item.value === this.state.kode_agama)}
                                                            onChange={event => this.handleChange(event,'kode_agama')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Ibu Kandung <code>(required)</code></label>
                                                        <input type="text" className="form-control" value={this.state.ibu_kandung} onChange={e => this.setState({ibu_kandung: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Status Rumah Tinggal <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataStatusRumah}
                                                            value={this.state.dataStatusRumah.filter((item) => item.value === this.state.status_rumah_tinggal)}
                                                            onChange={event => this.handleChange(event,'status_rumah_tinggal')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Mulai Menempati</label>
                                                        <input type="date" className="form-control" value={this.state.tgl_rumah_tinggal} onChange={e => this.setState({tgl_rumah_tinggal: e.target.value})} />
                                                    </div>

                                                    <h4 className="header-title mt-3">Data Pasangan</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nama Suami/Istri</label>
                                                        <input type="text" className="form-control" value={this.state.nama_pasangan} onChange={e => this.setState({nama_pasangan: e.target.value})} />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <label className="form-label">Tempat/Tgl Lahir</label>
                                                            <input type="text" className="form-control" value={this.state.tempat_lahir_pasangan} onChange={e => this.setState({tempat_lahir_pasangan: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="date" className="form-control" value={this.state.tgl_lahir_pasangan} onChange={e => this.setState({tgl_lahir_pasangan: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <label className="form-label">No. KTP/Expired</label>
                                                            <input type="number" className="form-control" value={this.state.no_id_pasangan} onChange={e => this.setState({no_id_pasangan: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="date" className="form-control" value={this.state.tgl_id_pasangan} onChange={e => this.setState({tgl_id_pasangan: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tgl Pernikahan</label>
                                                        <input type="date" className="form-control" value={this.state.tgl_nikah} onChange={e => this.setState({tgl_nikah: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">No. HandPhone</label>
                                                        <input type="number" className="form-control" value={this.state.handphone_pasangan} onChange={e => this.setState({handphone_pasangan: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Pekerjaan</label>
                                                        <input type="text" className="form-control" value={this.state.pekerjaan_pasangan} onChange={e => this.setState({pekerjaan_pasangan: e.target.value})} />
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Data Pekerjaan</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Pekerjaan <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataPekerjaan}
                                                            value={this.state.dataPekerjaan.filter((item) => item.value === this.state.kode_pekerjaan)}
                                                            onChange={event => this.handleChange(event,'kode_pekerjaan')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nama Perusahaan</label>
                                                        <input type="text" className="form-control" value={this.state.nama_perusahaan} onChange={e => this.setState({nama_perusahaan: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Alamat Perusahaan <code>(required)</code></label>
                                                        <textarea className="form-control" rows="3" value={this.state.alamat_perusahaan} onChange={e => this.setState({alamat_perusahaan: e.target.value})}></textarea>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kode Pos <code>(required)</code></label>
                                                        <div className="input-group input-group-merge">
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.id_pos_perusahaan} />
                                                            <a className="input-group-text" onClick={() => {this.handleModalClick('setModalKodeposCom','id_pos_perusahaan',ModalKodepos)}}>
                                                                <span className="fe-search"></span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <label className="form-label">Telp/Fax <code>(required)</code></label>
                                                            <input type="number" className="form-control" value={this.state.telpon_perusahaan} onChange={e => this.setState({telpon_perusahaan: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.fax_perusahaan} onChange={e => this.setState({fax_perusahaan: e.target.value})} />
                                                        </div>
                                                    </div>

                                                    <h4 className="header-title mt-3">Penghasilan dan Tanggungan</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Sumber Penghasilan <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataSumberPenghasilan}
                                                            value={this.state.dataSumberPenghasilan.filter((item) => item.value === this.state.sumber_penghasilan)}
                                                            onChange={event => this.handleChange(event,'sumber_penghasilan')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jumlah Penghasilan Kotor Per Tahun</label>
                                                        <input type="number" className="form-control" value={this.state.jml_penghasilan_per_tahun} onChange={e => this.setState({jml_penghasilan_per_tahun: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jml. Tanggungan</label>
                                                        <input type="number" className="form-control" value={this.state.jml_tanggungan} onChange={e => this.setState({jml_tanggungan: e.target.value})} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-2a">
                                            <div className={'row'}>
                                                <div className={'col-5'}>
                                                    <h4 className="header-title">Khusus Badan Usaha dan Bank</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Sandi Bank</label>
                                                        <Select
                                                            options={this.state.dataBank}
                                                            value={this.state.dataBank.filter((item) => item.value === this.state.bank_tabungan_1)}
                                                            onChange={event => this.handleChange(event,'bank_tabungan_1')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">TDP</label>
                                                        <input type="text" className="form-control" value={this.state.tanda_daftar_perusahaan} onChange={e => this.setState({tanda_daftar_perusahaan: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tempat Pendirian</label>
                                                        <input type="text" className="form-control" value={this.state.tempat_pendirian_perusahaan} onChange={e => this.setState({tempat_pendirian_perusahaan: e.target.value})} />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">No/Tgl Akte Awal</label>
                                                            <input type="text" className="form-control" value={this.state.no_akte_awal} onChange={e => this.setState({no_akte_awal: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="date" className="form-control" value={this.state.tgl_akte_awal} onChange={e => this.setState({tgl_akte_awal: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">No/Tgl Akte Akhir</label>
                                                            <input type="text" className="form-control" value={this.state.no_akte_akhir} onChange={e => this.setState({no_akte_akhir: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="date" className="form-control" value={this.state.tgl_akte_akhir} onChange={e => this.setState({tgl_akte_akhir: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">Kemenkumham</label>
                                                            <input type="text" className="form-control" value={this.state.surat_kemenkumham} onChange={e => this.setState({surat_kemenkumham: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="date" className="form-control" value={this.state.tgl_kemenkumham} onChange={e => this.setState({tgl_kemenkumham: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">SIUP</label>
                                                        <input type="text" className="form-control" value={this.state.surat_izin_usaha_perusahaan} onChange={e => this.setState({surat_izin_usaha_perusahaan: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Surat Domisili Perusahaan</label>
                                                        <input type="text" className="form-control" value={this.state.surat_domisili_perusahaan} onChange={e => this.setState({surat_domisili_perusahaan: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Group Perusahaan</label>
                                                        <input type="text" className="form-control" value={this.state.group_perusahaan} onChange={e => this.setState({group_perusahaan: e.target.value})} />
                                                    </div>

                                                    <h4 className="header-title mt-3">Kuasa Perusahaan</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Surat Kuasa Perusahaan</label>
                                                        <input type="text" className="form-control" value={this.state.surat_kuasa_perusahaan} onChange={e => this.setState({surat_kuasa_perusahaan: e.target.value})} />
                                                    </div>
                                                    <h4 className="header-title mt-3">Kuasa Perusahaan 1</h4>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nama</label>
                                                        <input type="text" className="form-control" value={this.state.nama_kuasa_perusahaan_1} onChange={e => this.setState({nama_kuasa_perusahaan_1: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Alamat</label>
                                                        <input type="text" className="form-control" value={this.state.alamat_kuasa_perusahaan_1} onChange={e => this.setState({alamat_kuasa_perusahaan_1: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">No KTP</label>
                                                        <input type="text" className="form-control" value={this.state.noid_kuasa_perusahaan_1} onChange={e => this.setState({noid_kuasa_perusahaan_1: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jabatan</label>
                                                        <input type="text" className="form-control" value={this.state.jabatan_kuasa_perusahaan_1} onChange={e => this.setState({jabatan_kuasa_perusahaan_1: e.target.value})} />
                                                    </div>
                                                    <h4 className="header-title mt-3">Kuasa Perusahaan 2</h4>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nama</label>
                                                        <input type="text" className="form-control" value={this.state.nama_kuasa_perusahaan_2} onChange={e => this.setState({nama_kuasa_perusahaan_2: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Alamat</label>
                                                        <input type="text" className="form-control" value={this.state.alamat_kuasa_perusahaan_2} onChange={e => this.setState({alamat_kuasa_perusahaan_2: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">No KTP</label>
                                                        <input type="text" className="form-control" value={this.state.noid_kuasa_perusahaan_2} onChange={e => this.setState({noid_kuasa_perusahaan_2: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jabatan</label>
                                                        <input type="text" className="form-control" value={this.state.jabatan_kuasa_perusahaan_2} onChange={e => this.setState({jabatan_kuasa_perusahaan_2: e.target.value})} />
                                                    </div>

                                                    <h4 className="header-title mt-3">GO PUBLIC</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Go Public</label>
                                                        <Select
                                                            options={Helpers.optionsPick}
                                                            value={Helpers.optionsPick.filter((item) => item.value === this.state.go_public)}
                                                            onChange={event => this.handleChange(event,'go_public')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Peringkat Rating</label>
                                                        <input type="number" className="form-control" value={this.state.rating_perusahaan} onChange={e => this.setState({rating_perusahaan: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tgl Rating</label>
                                                        <input type="date" className="form-control" value={this.state.tgl_rating} onChange={e => this.setState({tgl_rating: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Lembaga Rating <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataLembagaRating}
                                                            value={this.state.dataLembagaRating.filter((item) => item.value === this.state.kode_lembaga_rating)}
                                                            onChange={event => this.handleChange(event,'kode_lembaga_rating')} />
                                                    </div>
                                                </div>
                                                <div className={'col-7'}>
                                                    <h4 className="header-title">Data Pengurus dan Pemegang Saham</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <table className="table" width="100%">
                                                        <thead className="table-light">
                                                        <tr>
                                                            <th>
                                                                <a className="btn btn-info btn-xs waves-effect waves-light mr-5"
                                                                   onClick={() => {
                                                                       //this.submitData()
                                                                   }}>
                                                                    <i className="fe-plus"></i>
                                                                </a>
                                                            </th>
                                                            <th>Nama</th>
                                                            <th>Alamat</th>
                                                            <th>Jenis Kelamin</th>
                                                            <th>Kode Kota</th>
                                                            <th>Nama Kota</th>
                                                            <th>Kode Pos</th>
                                                            <th>Kelurahan</th>
                                                            <th>No KTP</th>
                                                            <th>NPWP</th>
                                                            <th>Jabatan</th>
                                                            <th>Presentase Kepemilikan</th>
                                                            <th>Operation</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-3">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Rekening Tabungan Yang Dimiliki</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">1. Sandi Bank</label>
                                                            <Select
                                                                options={this.state.dataBank}
                                                                value={this.state.dataBank.filter((item) => item.value === this.state.bank_tabungan_1)}
                                                                onChange={event => this.handleChange(event,'bank_tabungan_1')} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">Rekening Simpanan</label>
                                                            <input type="number" className="form-control" value={this.state.no_rek_tabungan_1} onChange={e => this.setState({no_rek_tabungan_1: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">2. Sandi Bank</label>
                                                            <Select
                                                                options={this.state.dataBank}
                                                                value={this.state.dataBank.filter((item) => item.value === this.state.bank_tabungan_2)}
                                                                onChange={event => this.handleChange(event,'bank_tabungan_2')} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">Rekening Simpanan</label>
                                                            <input type="number" className="form-control" value={this.state.no_rek_tabungan_2} onChange={e => this.setState({no_rek_tabungan_2: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">3. Sandi Bank</label>
                                                            <Select
                                                                options={this.state.dataBank}
                                                                value={this.state.dataBank.filter((item) => item.value === this.state.bank_tabungan_3)}
                                                                onChange={event => this.handleChange(event,'bank_tabungan_3')} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">Rekening Simpanan</label>
                                                            <input type="number" className="form-control" value={this.state.no_rek_tabungan_3} onChange={e => this.setState({no_rek_tabungan_3: e.target.value})} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Rekening Pinjaman Yg Dimiliki</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">1. Sandi Bank</label>
                                                            <Select
                                                                options={this.state.dataBank}
                                                                value={this.state.dataBank.filter((item) => item.value === this.state.bank_kartu_kredit_1)}
                                                                onChange={event => this.handleChange(event,'bank_kartu_kredit_1')} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">Rekening Simpanan</label>
                                                            <input type="number" className="form-control" value={this.state.no_kartu_kredit_1} onChange={e => this.setState({no_kartu_kredit_1: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">2. Sandi Bank</label>
                                                            <Select
                                                                options={this.state.dataBank}
                                                                value={this.state.dataBank.filter((item) => item.value === this.state.bank_kartu_kredit_2)}
                                                                onChange={event => this.handleChange(event,'bank_kartu_kredit_2')} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">Rekening Simpanan</label>
                                                            <input type="number" className="form-control" value={this.state.no_kartu_kredit_2} onChange={e => this.setState({no_kartu_kredit_2: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">3. Sandi Bank</label>
                                                            <Select
                                                                options={this.state.dataBank}
                                                                value={this.state.dataBank.filter((item) => item.value === this.state.bank_kartu_kredit_3)}
                                                                onChange={event => this.handleChange(event,'bank_kartu_kredit_3')} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">Rekening Simpanan</label>
                                                            <input type="number" className="form-control" value={this.state.no_kartu_kredit_3} onChange={e => this.setState({no_kartu_kredit_3: e.target.value})} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-4">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">LAPBUL</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Lokasi <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataLokasi}
                                                            value={this.state.dataLokasi.filter((item) => item.value === this.state.kode_lokasi)}
                                                            onChange={event => this.handleChange(event,'kode_lokasi')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">BI Gol. Debitur <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataGolDebitur}
                                                            value={this.state.dataGolDebitur.filter((item) => item.value === this.state.kode_gol_debitur)}
                                                            onChange={event => this.handleChange(event,'kode_gol_debitur')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Pemilik <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataPemilik}
                                                            value={this.state.dataPemilik.filter((item) => item.value === this.state.kode_pemilik)}
                                                            onChange={event => this.handleChange(event,'kode_pemilik')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Hubungan dengan Bank <code>(required)</code></label>
                                                        <Select
                                                            options={Helpers.optionsKeterkaitan}
                                                            value={Helpers.optionsKeterkaitan.filter((item) => item.value === this.state.bank_terkait)}
                                                            onChange={event => this.handleChange(event,'bank_terkait')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">BI Gol. Kreditur <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataGolKreditur}
                                                            value={this.state.dataGolKreditur.filter((item) => item.value === this.state.kode_gol_kreditur)}
                                                            onChange={event => this.handleChange(event,'kode_gol_kreditur')} />
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">SLIK</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">SID Gol.Debitur <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataSlikPihakTiga}
                                                            value={this.state.dataSlikPihakTiga.filter((item) => item.value === this.state.sid_gol_debitur)}
                                                            onChange={event => this.handleChange(event,'sid_gol_debitur')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Hub Deb.Dgn Bank <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataSlikHubungan}
                                                            value={this.state.dataSlikHubungan.filter((item) => item.value === this.state.sid_hubungan_ljk)}
                                                            onChange={event => this.handleChange(event,'sid_hubungan_ljk')} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-5">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Foto Nasabah</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <input id="fileNasabah" type="file" className="form-control" onChange={event => this.handleImageChange(event,'fileNasabah')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <img src={this.state.fileNasabah} width={300}/>
                                                    </div>

                                                    <h4 className="header-title mt-3">Foto Pasangan</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <input id="filePasangan" type="file" className="form-control" onChange={event => this.handleImageChange(event,'filePasangan')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <img src={this.state.filePasangan} width={300}/>
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Specimen</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <input id="fileSpecimen" type="file" className="form-control" onChange={event => this.handleImageChange(event,'fileSpecimen')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <img src={this.state.fileSpecimen} width={300}/>
                                                    </div>

                                                    <h4 className="header-title mt-3">Foto Asset</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <input id="fileAsset" type="file" className="form-control" onChange={event => this.handleImageChange(event,'fileAsset')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <img src={this.state.fileAsset} width={300}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-6">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tgl Proses</label>
                                                        <input type="date" className="form-control" value={this.state.tgl_proses} onChange={e => this.setState({tgl_proses: e.target.value})} />
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tgl Penilaian APUPPT</label>
                                                        <input type="date" className="form-control" value={this.state.tgl_penilaian} onChange={e => this.setState({tgl_penilaian: e.target.value})} />
                                                    </div>
                                                </div>
                                                <div className={'col-12'}>
                                                    <MDBDataTableV5
                                                        hover
                                                        striped
                                                        entries={7}
                                                        data={
                                                            {
                                                                columns: [
                                                                    {
                                                                        label: 'Deskripsi',
                                                                        field: 'deskripsi',
                                                                    },
                                                                    {
                                                                        label: 'Jawaban',
                                                                        field: 'jawaban',
                                                                    },
                                                                    {
                                                                        label: 'Nilai',
                                                                        field: 'nilai',
                                                                    }
                                                                ],
                                                                rows: this.state.dataAPUPPTSoal.map((row,index) => {
                                                                    var dataJawaban = this.state.dataAPUPPT.filter((item) => item.no_soal === row.no_soal);

                                                                    return {
                                                                        ...row,
                                                                        jawaban: (
                                                                            <Select
                                                                                options={dataJawaban}
                                                                                value={dataJawaban.filter((item) => item.value === this.state['apu_scoring_' + (index+1).toString()])}
                                                                                onChange={event => this.handleChange(event,'apu_scoring_' + (index+1).toString())}
                                                                            />
                                                                        ),
                                                                        nilai: (
                                                                            <input type="number" className="form-control" readOnly={true} value={ this.state['apu_scoring_nilai_' + (index+1).toString()] } />
                                                                        ),
                                                                    };
                                                                }),
                                                            }
                                                        } />
                                                </div>
                                                <div className={'col-6'}>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nilai</label>
                                                        <input type="number" className="form-control" readOnly={true} value={this.state.apuppt_nilai} />
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kesimpulan</label>
                                                        <input type="text" className="form-control" readOnly={true} value={this.state.apuppt_kesimpulan} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DataNasabahForm;