import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";
import ModalReport from "../../../components/pdf/laporan/tabungan/ModalReport";
import ModalKodepos from "../../../components/ModalKodepos";

class JurnalUmum extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],
            setModal: '',
            startDate: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getData();
        }
    }

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'akuntansi-jurnal/report/jurnal-umum?limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataSource: res.data
                });

                ReactSession.set("bprsess_report", JSON.stringify(
                    {
                        "report_type": "nominatif_deposito",
                        "invoice_no": "001-Kantor Pusat",
                        "start_date": this.state.startDate,
                        "end_date": this.state.startDate,
                        "items": res.data,
                        "meta": res.meta
                    }
                ));
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    handleModalClick = () => {
        if(this.state.dataSource.length > 0){
            this.setState({
                setModal: ''
            }, () => {
                this.setState({
                    setModal: <ModalReport modalRef={true} />
                })
            })
        }else{
            toast.error('Mohon untuk proses data terlebih dahulu');
        }
    };

    selectData = (id) => {
        window.location.href = './akuntansi-jurnalumum-detail?ref=' + id;
    };

    render() {
        return (
            <div className="content">
                { this.state.setModal }

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Akuntansi</li>
                                        <li className="breadcrumb-item">Pembukuan</li>
                                        <li className="breadcrumb-item active">Jurnal Umum</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Jurnal Umum</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary waves-effect waves-light" href="./akuntansi-jurnalumum-baru">
                                        <i className="fe-plus me-1"></i> Tambah
                                    </a>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'Trans Id',
                                                        field: 'trans_id',
                                                    },
                                                    {
                                                        label: 'Tgl Transaksi',
                                                        field: 'tgl_trans_formatted',
                                                    },
                                                    {
                                                        label: 'Nominal',
                                                        field: 'd1',
                                                    },
                                                    {
                                                        label: 'No. Bukti',
                                                        field: 'kuitansi',
                                                    },
                                                    {
                                                        label: 'Keterangan',
                                                        field: 'keterangan',
                                                    },
                                                    {
                                                        label: 'Action',
                                                        field: 'action',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    return {
                                                        ...row,
                                                        d1: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.nominal_formatted}</label>
                                                        ),
                                                        action: (
                                                            <button type="button" className="btn btn-success btn-rounded btn-sm" onClick={() => {this.selectData(row.trans_id)}}>
                                                                Pilih
                                                            </button>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JurnalUmum;