import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";
import ModalReport from "../../../components/pdf/laporan/tabungan/ModalReport";
import ModalKodepos from "../../../components/ModalKodepos";

class NominatifTabungan extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],
            setModal: '',
            startDate: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }
    }

    getData = () => {
        if(this.state.startDate){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'tabungan-transaction/report/nominatif?tgl_saldo='+this.state.startDate+'&limit=0').then(res => {
                if(res.data){
                    this.setState({
                        dataSource: res.data
                    });

                    ReactSession.set("bprsess_report", JSON.stringify(
                        {
                            "report_type": "nominatif_tabungan",
                            "invoice_no": "001-Kantor Pusat",
                            "start_date": this.state.startDate,
                            "end_date": this.state.startDate,
                            "items": res.data,
                            "meta": res.meta
                        }
                    ));
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }else{
            toast.error('Anda belum memilih periode');
        }
    };

    handleModalClick = () => {
        if(this.state.dataSource.length > 0){
            this.setState({
                setModal: ''
            }, () => {
                this.setState({
                    setModal: <ModalReport modalRef={true} />
                })
            })
        }else{
            toast.error('Mohon untuk proses data terlebih dahulu');
        }
    };

    render() {
        return (
            <div className="content">
                { this.state.setModal }

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Laporan</li>
                                        <li className="breadcrumb-item">Tabungan</li>
                                        <li className="breadcrumb-item active">Nominatif Tabungan</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Nominatif Tabungan</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label">Tanggal</label>
                                            <input type="date" className="form-control" value={this.state.startDate} onChange={e => this.setState({startDate: e.target.value})} />
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.getData()}}>
                                                Proses
                                            </a>
                                        </div>
                                        <div className="col-1">
                                            {/*<a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.handleModalClick()}}>*/}
                                                {/*Cetak*/}
                                            {/*</a>*/}
                                        </div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'No. Rekening',
                                                        field: 'no_rekening',
                                                    },
                                                    {
                                                        label: 'No Alternatif',
                                                        field: 'no_alternatif',
                                                    },
                                                    {
                                                        label: 'Nama Nasabah',
                                                        field: 'nama_nasabah',
                                                    },
                                                    {
                                                        label: 'Lokasi',
                                                        field: 'kota',
                                                    },
                                                    {
                                                        label: 'Terkait',
                                                        field: 'bank_terkait',
                                                    },
                                                    {
                                                        label: 'Pemilik',
                                                        field: 'kode_gol_debitur',
                                                    },
                                                    {
                                                        label: 'Kelompok',
                                                        field: 'jenis_nasabah',
                                                    },
                                                    {
                                                        label: 'Status',
                                                        field: 'status_rekening',
                                                    },
                                                    {
                                                        label: 'Saldo Akhir',
                                                        field: 'saldo',
                                                    },
                                                    /*{
                                                        label: 'VA No',
                                                        field: null,
                                                    },
                                                    {
                                                        label: 'Va Saldo',
                                                        field: null,
                                                    }*/
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    return {
                                                        ...row,
                                                        saldo: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.balance_formatted}</label>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NominatifTabungan;