import React from "react";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import $ from "jquery";
import {Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";

class Pemilik extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: []
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getData();
        }
    }

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'kode-pemilik?limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataSource: res.data
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    selectData = (id) => {
        window.location.href = './data-nasabah-form?ref=' + id;
    };

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Customer Service</li>
                                        <li className="breadcrumb-item">Master Data</li>
                                        <li className="breadcrumb-item active">Master Pemilik</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Master Pemilik</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary waves-effect waves-light" href="./data-nasabah-form">
                                        Tambah
                                    </a>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'ID',
                                                        field: 'real_id',
                                                    },
                                                    {
                                                        label: 'Deskripsi',
                                                        field: 'deskripsi',
                                                    },
                                                    {
                                                        label: 'Action',
                                                        field: 'action',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    return {
                                                        ...row,
                                                        action: (
                                                            <button type="button" className="btn btn-success btn-rounded btn-sm" onClick={() => {this.selectData(row.id)}}>
                                                                Pilih
                                                            </button>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pemilik;