import React from "react";
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";

class Home extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : ''
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }
    }

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item active">Home</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Home</h4>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <img src="./assets/images/profile-middle.png" width={'95%'} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;