import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import Select from 'react-select';
import ModalReport from "../../laporan/deposito/NominatifDeposito";

class SyncData extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            selectedData: '',
            masterData: [],
            endpoint: '',
            kategori: '',
            total: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.setState({
                masterData: Helpers.arrMasterData.map((value, index) => {
                    return {
                        value: value.state,
                        label: value.name
                    }
                })
            });
        }
    }

    getMasterData = (endpoint,state,value,label) => {
        return Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    total: res.data.length,
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]] : item[val];
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label]
                        };
                    })
                }, () => {
                    ReactSession.set("bprsess_" + state, JSON.stringify(this.state[state]));
                });
            }
        })
    };

    loadMasterData = async () => {
        for(let i=0; i< Helpers.arrMasterData.length; i++) {
            if(this.state.selectedData === Helpers.arrMasterData[i].state){
                this.setState({
                    endpoint: Helpers.arrMasterData[i].endpoint,
                    kategori: Helpers.arrMasterData[i].name
                });

                await this.getMasterData(
                    Helpers.arrMasterData[i].endpoint,
                    Helpers.arrMasterData[i].state,
                    Helpers.arrMasterData[i].value,
                    Helpers.arrMasterData[i].label
                );
            }
        }
    };

    getData = () => {
        if(this.state.selectedData){
            if (window.confirm('Sync Data akan menghapus data yang sudah diunduh dan memerlukan waktu untuk mengunduh ulang, apakah anda ingin melanjutkan?')) {
                $(".loader-wrapper").css("display", "");
                this.loadMasterData().then((res) => {
                    toast.success('Sync Data Berhasil');

                    $(".loader-wrapper").css("display", "none");
                }).catch((e) => {
                    console.log(e);
                    $(".loader-wrapper").css("display", "none");
                })
            }
        }else{
            toast.error('Anda belum memilih kategori master data');
        }
    };

    handleChange = (event,state) => {
        this.setState({
            [state]: event.value
        });
    };

    render() {
        return (
            <div className="content">
                { this.state.setModal }

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Customer Service</li>
                                        <li className="breadcrumb-item">Setting</li>
                                        <li className="breadcrumb-item active">Sync Data</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Sync Data</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3">
                                    <label className="form-label">Kategori Master Data</label>
                                    <Select
                                        options={this.state.masterData}
                                        value={this.state.masterData.filter((item) => item.value === this.state.selectedData)}
                                        onChange={event => this.handleChange(event,'selectedData')} />
                                </div>
                                <div className="col-1">
                                    <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.getData()}}>
                                        Proses
                                    </a>
                                </div>
                            </div>
                            <div className="dropdown-divider"></div>
                            <div className="row">
                                <div className="col-12">
                                    <table width="100%" className="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th>Endpoint</th>
                                            <th>Master Data</th>
                                            <th>Total Data</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{this.state.endpoint}</td>
                                            <td>{this.state.kategori}</td>
                                            <td>{this.state.total}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SyncData;