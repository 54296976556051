import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#000',
        backgroundColor: '#FFF',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold'
    },
    description: {
        width: '60%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%'
    },
    td: {
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 8
    }
});

const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={[styles.td, {width: '3%'}]}>No.</Text>
        <Text style={[styles.td, {width: '7%'}]}>Tgl Transaksi</Text>
        <Text style={[styles.td, {width: '7%'}]}>No. Rekening</Text>
        <Text style={[styles.td, {width: '21%'}]}>Nama Nasabah</Text>
        <Text style={[styles.td, {width: '5%'}]}>Kode</Text>
        <Text style={[styles.td, {width: '16%'}]}>Jenis</Text>
        <Text style={[styles.td, {width: '7%'}]}>No. Reff</Text>
        <Text style={[styles.td, {width: '7%'}]}>User Id</Text>
        <Text style={[styles.td, {width: '7%'}]}>User App</Text>
        <Text style={[styles.td, {width: '10%'}]}>Setoran</Text>
        <Text style={[styles.td, {width: '10%'}]}>Penarikan</Text>
    </View>
);

export default InvoiceTableHeader