import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";
import ModalReport from "../../../components/pdf/laporan/tabungan/ModalReport";
import ModalKodepos from "../../../components/ModalKodepos";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {Document, Page} from "react-pdf";

class JurnalUmumDetail extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            urlParam: queryString.parse(window.location.search),
            dataSource: [],
            getTransId: '',

            isModalOpen: false,
            reportStream: null,
            pdfWidth: 0
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.setState({
                getTransId: (this.state.urlParam.ref) ? this.state.urlParam.ref : '',
            }, () => {
                if(this.state.getTransId){
                    this.getData();
                }
            });
        }
    }

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'akuntansi-jurnal/report/jurnal-umum/detail/'+this.state.getTransId).then(res => {
            if(res.data){
                this.setState({
                    dataSource: res.data[0].detail,
                    trans_id: res.data[0].trans_id,
                    cabang_kode: res.data[0].cabang.kode,
                    cabang_nama: res.data[0].cabang.nama,
                    tgl_trans: res.data[0].tgl_trans,
                    src: res.data[0].src,
                    keterangan: res.data[0].keterangan,
                    reff: res.data[0].reff,
                    total_debet_formatted: res.data[0].total_debet_formatted,
                    total_kredit_formatted: res.data[0].total_kredit_formatted,
                    total_admin_debet_formatted: res.data[0].total_admin_debet_formatted,
                    total_admin_kredit_formatted: res.data[0].total_admin_kredit_formatted
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    handleItemPrint = () => {
        if(this.state.getTransId){
            $(".loader-wrapper").css("display", "");
            Helpers.getReportPDF(this.state.sessToken,'akuntansi-jurnal/report/jurnal-umum/detail/'+this.state.getTransId+'/print?format=pdf').then(res => {
                this.setState({
                    reportStream: res
                }, () => {
                    this.handleModal();
                });

                $(".loader-wrapper").css("display", "none");
            });
        }else{
            toast.error('Anda belum melakukan transaksi!');
        }
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleDownload = () => {
        const uid = (new Date().getTime());
        Helpers.download(this.state.reportStream, 'kuitansi_penerimaan_bunga_deposito_'+uid);
    };

    render() {
        return (
            <div className="content">
                {/*{ this.state.setModal }*/}

                <Modal
                    size={'lg'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}>
                    <ModalBody>
                        <Document file={this.state.reportStream}>
                            <Page
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={this.state.pdfWidth}
                            />
                        </Document>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {this.handleDownload()}}>Download</Button>
                        <Button onClick={() => {this.handleModal()}}>Close</Button>
                    </ModalFooter>
                </Modal>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Akuntansi</li>
                                        <li className="breadcrumb-item">Pembukuan</li>
                                        <li className="breadcrumb-item">Jurnal Umum</li>
                                        <li className="breadcrumb-item active">Jurnal Umum Detail</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Jurnal Umum Detail</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-4">
                                    <div className="mb-1">
                                        <label className="form-label">Trans Id</label>
                                        <input type="text" className="form-control" readOnly={true} value={this.state.trans_id} />
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="form-label">Akun Cabang</label>
                                            <input type="text" className="form-control" readOnly={true} value={this.state.cabang_kode} />
                                        </div>
                                        <div className="col-md-8">
                                            <label className="form-label">&nbsp;</label>
                                            <input type="text" className="form-control" readOnly={true} value={this.state.cabang_nama} />
                                        </div>
                                    </div>
                                    <div className="mb-1">
                                        <label className="form-label">Tgl Transaksi</label>
                                        <input type="text" className="form-control" readOnly={true} value={this.state.tgl_trans} />
                                    </div>
                                    <div className="mb-1">
                                        <label className="form-label">Modul</label>
                                        <input type="text" className="form-control" readOnly={true} value={this.state.src} />
                                    </div>
                                    <div className="mb-1">
                                        <label className="form-label">Keterangan</label>
                                        <textarea className="form-control" rows={3}>{this.state.keterangan}</textarea>
                                    </div>
                                    <div className="mb-1">
                                        <label className="form-label">Reff</label>
                                        <input type="text" className="form-control" value={this.state.reff} />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'Kode Perkiraan',
                                                        field: 'kode_perk',
                                                    },
                                                    {
                                                        label: 'Deskripsi',
                                                        field: 'perk',
                                                    },
                                                    {
                                                        label: 'Debet',
                                                        field: 'd1',
                                                    },
                                                    {
                                                        label: 'Kredit',
                                                        field: 'd2',
                                                    },
                                                    {
                                                        label: 'Uraian',
                                                        field: 'uraian',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    let namaperk = row.nama_perk.replace(/ /g,"\u00A0");
                                                    return {
                                                        ...row,
                                                        perk: (
                                                            <p>{namaperk}</p>
                                                        ),
                                                        d1: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.debet_formatted}</label>
                                                        ),
                                                        d2: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.kredit_formatted}</label>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />

                                    <div className="row mb-1">
                                        <div className="col-md-3">
                                            <label className="form-label">Total Debet</label>
                                            <input type="text" className="form-control" style={{'text-align': 'right'}} readOnly={true} value={this.state.total_debet_formatted} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label">Total Kredit</label>
                                            <input type="text" className="form-control" style={{'text-align': 'right'}} readOnly={true} value={this.state.total_kredit_formatted} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label">Administratif Debet</label>
                                            <input type="text" className="form-control" style={{'text-align': 'right'}} readOnly={true} value={this.state.total_admin_debet_formatted} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label">Administratif Kredit</label>
                                            <input type="text" className="form-control" style={{'text-align': 'right'}} readOnly={true} value={this.state.total_admin_kredit_formatted} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mt-3">
                                        <a className="btn btn-info waves-effect waves-light mr-5" onClick={() => {this.handleItemPrint()}}>
                                            <i className="fe-printer me-1"></i> Cetak
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JurnalUmumDetail;