import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";

class DataRekeningForm extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: '',
            urlParam: queryString.parse(window.location.search),
            jn_kantor_kode: Helpers.DEF_COMPANY_CODE,
            jn_kantor_nama: Helpers.DEF_COMPANY,

            getIdRekening: '',
            black_list: 0,
            flag_amortisasi_hadiah: 0,
            flag_amortisasi_fee: 0,
            flag_amortisasi_cash_back: 0,
            penanggung_pajak: 0,
            suku_bunga: '0',
            total_suku_bunga_estimasi: 0,

            persen_provisi: '0',
            nominal_provisi: '0',
            persen_admin: '0',
            nominal_admin: '0',
            minimum_deposito: '0',
            saldo_akhir: '0',
            suku_bunga_estimasi: '0',
            persen_premi_jiwa: '0',
            nominal_premi_jiwa: '0',
            persen_cash_back: '0',
            nominal_cash_back: '0',
            persen_pajak_fee: '0',
            persen_hadiah: '0',
            nominal_hadiah: '0',
            persen_fee: '0',
            nominal_fee: '0',

            qq_name: '',
            alokasi_bunga_note: '',
            alokasi_bunga_note2: '',
            keterangan_hadiah: '',
            ahli_waris: '',
            alokasi_fee_note: '',

            status_rekening: '10',
            kolektibilitas_overide: '0',
            tgl_kolektibilitas_overide: moment(new Date()).format("YYYY-MM-DD"),
            tgl_registrasi: moment(new Date()).format("YYYY-MM-DD"),

            dataJenisDeposito: JSON.parse(ReactSession.get("bprsess_dataJenisDeposito")),
            dataNasabah: [],
            dataRekening: [],
            dataUsers: [],
            dataKelompok: JSON.parse(ReactSession.get("bprsess_dataKelompok")),
            dataStatusRekening: JSON.parse(ReactSession.get("bprsess_dataStatusRekening")),
            dataSumberDana: JSON.parse(ReactSession.get("bprsess_dataSumberDanaRekening")),
            dataTujuanPembukaan: JSON.parse(ReactSession.get("bprsess_dataTujuanPembukaan")),
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.setState({
                getIdRekening: (this.state.urlParam.ref) ? this.state.urlParam.ref : '',
            }, () => {
                this.getMasterData('nasabah?limit=0','dataNasabah','real_id',['id_nasabah','nama_nasabah']);
                this.getMasterData('rekening-tabungan?limit=0','dataRekening','real_id',['no_rekening','nasabah|nama']);
                this.getMasterData('users?search=is_backoffice:1&limit=0','dataUsers','userid',['userid','name']);

                if(this.state.getIdRekening){
                    this.getData();
                }
            });
        }
    }

    getMasterData = (endpoint,state,value,label) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                });
            }
        })
    };

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'rekening-deposito/'+this.state.getIdRekening).then(res => {
            if(res.data){
                const data = res.data;
                this.setState({
                    dataSource: data,
                    jenis_deposito: data.jenis_deposito.kode_jenis_deposito,
                    nasabah_id: data.nasabah.id,
                    qq_name: data.qq_name,
                    ahli_waris: data.ahli_waris,
                    tgl_registrasi: data.tgl_registrasi,
                    id_ao: data.id_ao,
                    id_kelompok: data.id_kelompok,
                    suku_bunga: data.suku_bunga,
                    no_bilyet: data.no_bilyet,
                    aro: (data.aro === 'Ya') ? '1' : '0',
                    status_rekening: data.status_rekening,
                    tgl_kolektibilitas_overide: data.tgl_kolektibilitas_overide,
                    kolektibilitas_overide: data.kolektibilitas_overide,
                    sumber_dana_rekening: data.sumber_dana_rekening,
                    tujuan_pembukaan_rekening: data.tujuan_pembukaan_rekening,
                    setoran_per_bln: data.setoran_per_bln,
                    jkw: data.jkw,
                    tgl_jatuh_tempo: data.tgl_jatuh_tempo,
                    nominal_deposito: data.nominal_deposito,
                    persen_premi_jiwa: data.persen_premi_jiwa,
                    nominal_premi_jiwa: data.nominal_premi_jiwa,
                    penanggung_pajak: data.penanggung_pajak,
                    alokasi_fee: data.alokasi_fee.kode,
                    flag_amortisasi_fee: data.flag_amortisasi_fee,
                    nominal_cash_back: data.nominal_cash_back,
                    persen_cash_back: data.persen_cash_back,
                    flag_amortisasi_cash_back: data.flag_amortisasi_cash_back,
                    persen_hadiah: data.persen_hadiah,
                    persen_fee: data.persen_fee,
                    nominal_fee: data.nominal_fee,
                    flag_amortisasi_hadiah: data.flag_amortisasi_hadiah,
                    nominal_hadiah: data.nominal_hadiah,
                    keterangan_hadiah: data.keterangan_hadiah,
                    alokasi_bunga: data.alokasi_bunga,
                    alokasi_bunga_note: data.alokasi_bunga_note,
                    alokasi_bunga_note2: data.alokasi_bunga_note2,
                    nominal_provisi: data.nominal_provisi,
                    nominal_admin: data.nominal_admin,
                    based_fee: data.based_fee.kode,
                    keterangan_alokasi: data.alokasi_fee_note,
                    persen_pajak_fee: data.persen_pajak_fee,
                    rekening_si_otodebet: data.otodebet.no_rek_tabungan
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value });

        if(state === 'jenis_deposito'){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'kode-jenis-deposito?limit=0').then(res => {
                if(res.data.length > 0){
                    res.data.forEach((v, i) => {
                        if(v.real_id === event.value){
                            this.setState({
                                jkw: v.jkw,
                                suku_bunga: v.suku_bunga,
                                tgl_jatuh_tempo: moment(new Date()).add(v.jkw, 'months').format("YYYY-MM-DD"),
                            });
                        }
                    });
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }
    };

    countFeeAmortisasi = (value,state) => {
        this.setState({ [state]: value }, () => {
            if(this.state.jkw && this.state.nominal_deposito && this.state.persen_fee){
                this.setState({
                    nominal_fee: (((this.state.nominal_deposito * (this.state.persen_fee/100))/12)*this.state.jkw).toFixed(2)
                });
            }
        });
    };

    toggleCheckbox = (state) => {
        this.setState({ [state]: (this.state[state] === 0) ? 1 : 0 });
    };

    submitData = () => {
        if(this.state.getIdRekening){
            this.saveData('PATCH','rekening-deposito/'+this.state.getIdRekening);
        }else{
            this.saveData('POST','rekening-deposito');
        }
    };

    onTextChange = (event,state) => {
        this.setState({ [state]: event }, () => {
            if(state === 'suku_bunga' || state === 'suku_bunga_estimasi'){
                const suku_bunga = parseFloat((this.state.suku_bunga) ? this.state.suku_bunga : 0);
                const suku_bunga_estimasi = parseFloat((this.state.suku_bunga_estimasi) ? this.state.suku_bunga_estimasi : 0);

                this.setState({ total_suku_bunga_estimasi: suku_bunga+suku_bunga_estimasi });
            }
        });
    };

    saveData = (method,endpoint) => {
        let urlencoded = new URLSearchParams();
        urlencoded.append("jenis_deposito", this.state.jenis_deposito);
        urlencoded.append("nasabah_id", Helpers.castEmpty(this.state.nasabah_id));
        urlencoded.append("qq_name", Helpers.castEmpty(this.state.qq_name));
        urlencoded.append("ahli_waris", Helpers.castEmpty(this.state.ahli_waris));
        urlencoded.append("tgl_registrasi",(this.state.tgl_registrasi) ? this.state.tgl_registrasi : '1990-01-01');
        urlencoded.append("id_ao", this.state.id_ao);
        urlencoded.append("id_kelompok", this.state.id_kelompok);
        urlencoded.append("suku_bunga", this.state.suku_bunga);
        urlencoded.append("no_bilyet", this.state.no_bilyet);
        urlencoded.append("aro", (parseInt(this.state.aro) === 1) ? 'Ya' : 'Tidak');
        urlencoded.append("status_rekening", this.state.status_rekening);
        urlencoded.append("tgl_kolektibilitas_overide", (this.state.tgl_kolektibilitas_overide) ? this.state.tgl_kolektibilitas_overide : '1990-01-01');
        urlencoded.append("kolektibilitas_overide", this.state.kolektibilitas_overide);
        urlencoded.append("sumber_dana_rekening", this.state.sumber_dana_rekening);
        urlencoded.append("tujuan_pembukaan_rekening", this.state.tujuan_pembukaan_rekening);
        urlencoded.append("setoran_per_bln", Helpers.castEmpty(this.state.setoran_per_bln));
        urlencoded.append("jkw", Helpers.castEmpty(this.state.jkw));
        urlencoded.append("tgl_jatuh_tempo",(this.state.tgl_jatuh_tempo) ? this.state.tgl_jatuh_tempo : '1990-01-01');
        urlencoded.append("nominal_deposito", this.state.nominal_deposito);
        urlencoded.append("persen_premi_jiwa", Helpers.castEmpty(this.state.persen_premi_jiwa));
        urlencoded.append("nominal_premi_jiwa", Helpers.castEmpty(this.state.nominal_premi_jiwa));
        urlencoded.append("penanggung_pajak", this.state.penanggung_pajak);
        urlencoded.append("alokasi_fee", this.state.alokasi_fee);
        urlencoded.append("flag_amortisasi_fee", this.state.flag_amortisasi_fee);
        urlencoded.append("nominal_cash_back", Helpers.castEmpty(this.state.nominal_cash_back));
        urlencoded.append("persen_cash_back", Helpers.castEmpty(this.state.persen_cash_back));
        urlencoded.append("flag_amortisasi_cash_back", this.state.flag_amortisasi_cash_back);
        urlencoded.append("persen_hadiah", Helpers.castEmpty(this.state.persen_hadiah));
        urlencoded.append("persen_fee", Helpers.castEmpty(this.state.persen_fee));
        urlencoded.append("nominal_fee", Helpers.castEmpty(this.state.nominal_fee));
        urlencoded.append("flag_amortisasi_hadiah", this.state.flag_amortisasi_hadiah);
        urlencoded.append("nominal_hadiah", Helpers.castEmpty(this.state.nominal_hadiah));
        urlencoded.append("keterangan_hadiah", Helpers.castEmpty(this.state.keterangan_hadiah));
        urlencoded.append("alokasi_bunga", this.state.alokasi_bunga);
        urlencoded.append("alokasi_bunga_note", Helpers.castEmpty(this.state.alokasi_bunga_note));
        urlencoded.append("alokasi_bunga_note2", Helpers.castEmpty(this.state.alokasi_bunga_note2));
        urlencoded.append("nominal_provisi", Helpers.castEmpty(this.state.nominal_provisi));
        urlencoded.append("nominal_admin", Helpers.castEmpty(this.state.nominal_admin));
        urlencoded.append("based_fee", this.state.based_fee);
        urlencoded.append("alokasi_fee_note", Helpers.castEmpty(this.state.keterangan_alokasi));
        urlencoded.append("rekening_si_otodebet", Helpers.castEmpty(this.state.rekening_si_otodebet));
        urlencoded.append("fee_si_otodebet", '');
        urlencoded.append("persen_pajak_fee", Helpers.castEmpty(this.state.persen_pajak_fee));

        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => res.json()).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        toast.success(Helpers.MSG_SUCCESS);

                        setTimeout(function() {
                            window.location.href = './data-rekening-deposito';
                        }, 1000);
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Back Office</li>
                                        <li className="breadcrumb-item">Deposito</li>
                                        <li className="breadcrumb-item"><a href="./data-rekening-deposito">Data Rekening Deposito</a></li>
                                        <li className="breadcrumb-item active">Form</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Data Rekening Deposito Form</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary waves-effect waves-light" onClick={() => {this.submitData()}}>
                                        <i className="fe-save me-1"></i> Simpan
                                    </a>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-bordered">
                                        <li className="nav-item">
                                            <a href="#form-1" data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                                Rekening Deposito
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-2" data-bs-toggle="tab" aria-expanded="true" className="nav-link">
                                                Nisbah dan Nominal Deposito
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-3" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                Alokasi Bunga
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-4" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                Rekening ABA
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="form-1">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Informasi Produk</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-4">
                                                            <label className="form-label">Cabang</label>
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.jn_kantor_kode} onChange={e => this.setState({jn_kantor_kode: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.jn_kantor_nama} onChange={e => this.setState({jn_kantor_nama: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Deposito <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataJenisDeposito}
                                                            value={this.state.dataJenisDeposito.filter((item) => item.value === this.state.jenis_deposito)}
                                                            onChange={event => this.handleChange(event,'jenis_deposito')}
                                                            isDisabled={(this.state.getIdRekening) ? true : false} />
                                                    </div>

                                                    <h4 className="header-title mt-3">Informasi Nasabah</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nama Nasabah</label>
                                                        <Select
                                                            options={this.state.dataNasabah}
                                                            value={this.state.dataNasabah.filter((item) => item.value === this.state.nasabah_id)}
                                                            onChange={event => this.handleChange(event,'nasabah_id')}
                                                            isDisabled={(this.state.getIdRekening) ? true : false} />
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input"
                                                                   checked={(this.state.black_list === 1) ? true : false}
                                                                   onClick={() => {this.toggleCheckbox('black_list')}}
                                                                   readOnly={(this.state.getIdRekening) ? true : false} />
                                                            <label className="form-check-label">Black List</label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">QQ</label>
                                                        <input type="text" className="form-control" value={this.state.qq_name} onChange={e => this.setState({qq_name: e.target.value})}
                                                               readOnly={(this.state.getIdRekening) ? true : false} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Ahli Waris</label>
                                                        <textarea className="form-control" rows="3" value={this.state.ahli_waris} onChange={e => this.setState({ahli_waris: e.target.value})}
                                                                  readOnly={(this.state.getIdRekening) ? true : false} ></textarea>
                                                    </div>

                                                    <h4 className="header-title mt-3">Informasi Kelompok</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Petugas AO <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataUsers}
                                                            value={this.state.dataUsers.filter((item) => item.value === this.state.id_ao)}
                                                            onChange={event => this.handleChange(event,'id_ao')}
                                                            isDisabled={(this.state.getIdRekening) ? true : false} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kelompok <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataKelompok}
                                                            value={this.state.dataKelompok.filter((item) => item.value === this.state.id_kelompok)}
                                                            onChange={event => this.handleChange(event,'id_kelompok')}
                                                            isDisabled={(this.state.getIdRekening) ? true : false} />
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Data Rekening</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">No. Bilyet <code>(required)</code></label>
                                                        <input type="text" className="form-control" value={this.state.no_bilyet} onChange={e => this.setState({no_bilyet: e.target.value})}
                                                               readOnly={(this.state.getIdRekening) ? true : false} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">ARO <code>(required)</code></label>
                                                        <Select
                                                            options={Helpers.optionsPick}
                                                            value={Helpers.optionsPick.filter((item) => item.value === this.state.aro)}
                                                            onChange={event => this.handleChange(event,'aro')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Status Rekening <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataStatusRekening}
                                                            value={this.state.dataStatusRekening.filter((item) => item.value === this.state.status_rekening)}
                                                            onChange={event => this.handleChange(event,'status_rekening')}
                                                            isDisabled={true}/>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">Overide Kolektibilitas <code>(required)</code></label>
                                                            <Select
                                                                options={Helpers.optionsKolektibilitas}
                                                                value={Helpers.optionsKolektibilitas.filter((item) => item.value === this.state.kolektibilitas_overide)}
                                                                onChange={event => this.handleChange(event,'kolektibilitas_overide')}
                                                                isDisabled={(this.state.getIdRekening) ? true : false} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="date" className="form-control" value={this.state.tgl_kolektibilitas_overide} onChange={e => this.setState({tgl_kolektibilitas_overide: e.target.value})}
                                                                   readOnly={(this.state.getIdRekening) ? true : false} />
                                                        </div>
                                                    </div>

                                                    <h4 className="header-title mt-3">Sumber Dana dan Tujuan Pembukaan</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kode Sumber Dana <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataSumberDana}
                                                            value={this.state.dataSumberDana.filter((item) => item.value === this.state.sumber_dana_rekening)}
                                                            onChange={event => this.handleChange(event,'sumber_dana_rekening')}
                                                            isDisabled={(this.state.getIdRekening) ? true : false} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tujuan Pembukaan <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataTujuanPembukaan}
                                                            value={this.state.dataTujuanPembukaan.filter((item) => item.value === this.state.tujuan_pembukaan_rekening)}
                                                            onChange={event => this.handleChange(event,'tujuan_pembukaan_rekening')}
                                                            isDisabled={(this.state.getIdRekening) ? true : false} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-2">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Jangka Waktu</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tanggal Registrasi <code>(required)</code></label>
                                                        <input type="date" className="form-control" value={this.state.tgl_registrasi} onChange={e => this.setState({tgl_registrasi: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jangka Waktu (Bulan)</label>
                                                        <input type="number" className="form-control" value={this.state.jkw} onChange={e => this.setState({jkw: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tgl Jatuh tempo <code>(required)</code></label>
                                                        <input type="date" className="form-control" value={this.state.tgl_jatuh_tempo} onChange={e => this.setState({tgl_jatuh_tempo: e.target.value})} />
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Nisbah dan Nominal Deposito</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jumlah Deposito <code>(required)</code></label>
                                                        <input type="number" className="form-control" value={this.state.nominal_deposito} onChange={e => this.countFeeAmortisasi(e.target.value, 'nominal_deposito')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Minimum Deposito</label>
                                                        <input type="number" readOnly={true} className="form-control" value={this.state.minimum_deposito} onChange={e => this.setState({minimum_deposito: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Saldo Akhir</label>
                                                        <input type="number" readOnly={true} className="form-control" value={this.state.saldo_akhir} onChange={e => this.setState({saldo_akhir: e.target.value})} />
                                                    </div>

                                                    <div className="row mb-1 mt-3">
                                                        <div className="col-md-2">
                                                            <label className="form-label">Bunga</label>
                                                            <input type="number" className="form-control" value={this.state.suku_bunga} onChange={e => this.onTextChange(e.target.value, 'suku_bunga')} />
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">+</label>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.suku_bunga_estimasi} onChange={e => this.onTextChange(e.target.value, 'suku_bunga_estimasi')} />
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">=</label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" readOnly={true} value={this.state.total_suku_bunga_estimasi} onChange={e => this.setState({total_suku_bunga_estimasi: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">% Nasabah</label>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input"
                                                                       checked={(this.state.penanggung_pajak === 1) ? true : false}
                                                                       onClick={() => {this.toggleCheckbox('penanggung_pajak')}}/>
                                                                <label className="form-check-label">Pajak Di Tanggung Lembaga</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1 mt-3">
                                                        <div className="col-md-5">
                                                            <label className="form-label">Premi</label>
                                                            <input type="number" className="form-control" value={this.state. persen_premi_jiwa} onChange={e => this.setState({ persen_premi_jiwa: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">% Rp. </label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.nominal_premi_jiwa} onChange={e => this.setState({nominal_premi_jiwa: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1 mt-3">
                                                        <div className="col-md-5">
                                                            <label className="form-label">Cash Back</label>
                                                            <input type="number" className="form-control" value={this.state.persen_cash_back} onChange={e => this.setState({persen_cash_back: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">% Rp. </label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.nominal_cash_back} onChange={e => this.setState({nominal_cash_back: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input"
                                                                       checked={(this.state.flag_amortisasi_cash_back === 1) ? true : false}
                                                                       onClick={() => {this.toggleCheckbox('flag_amortisasi_cash_back')}}/>
                                                                <label className="form-check-label">Amortisasi</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1 mt-3">
                                                        <label className="form-label">Based Fee <code>(required)</code></label>
                                                        <Select
                                                            options={Helpers.optionsBasedFee}
                                                            value={Helpers.optionsBasedFee.filter((item) => item.value === this.state.based_fee)}
                                                            onChange={event => this.handleChange(event,'based_fee')} />
                                                    </div>
                                                    <div className="row mb-1 mt-3">
                                                        <div className="col-md-5">
                                                            <label className="form-label">Fee Marketing</label>
                                                            <input type="number" className="form-control" value={this.state.persen_fee} onChange={e => this.countFeeAmortisasi(e.target.value, 'persen_fee')} />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">% Rp. </label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.nominal_fee} onChange={e => this.setState({nominal_fee: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-check mb-1">
                                                                <input type="checkbox" className="form-check-input"
                                                                       checked={(this.state.flag_amortisasi_fee === 1) ? true : false}
                                                                       onClick={() => {this.toggleCheckbox('flag_amortisasi_fee')}}/>
                                                                <label className="form-check-label">Amortisasi</label>
                                                            </div>
                                                            <label className="form-label">Pajak Fee (%)</label>
                                                            <input type="number" className="form-control" value={this.state.persen_pajak_fee} onChange={e => this.setState({persen_pajak_fee: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1 mt-3">
                                                        <label className="form-label">Alokasi Fee</label>
                                                        <Select
                                                            options={Helpers.optionsAlokasiBunga}
                                                            value={Helpers.optionsAlokasiBunga.filter((item) => item.value === this.state.alokasi_fee)}
                                                            onChange={event => this.handleChange(event,'alokasi_fee')} />
                                                        <label className="form-label">Keterangan</label>
                                                        <textarea className="form-control" rows="3" value={this.state.keterangan_alokasi} onChange={e => this.setState({keterangan_alokasi: e.target.value})}></textarea>
                                                    </div>
                                                    <div className="row mb-1 mt-3">
                                                        <div className="col-md-5">
                                                            <label className="form-label">Hadiah</label>
                                                            <input type="number" className="form-control" value={this.state.persen_hadiah} onChange={e => this.setState({persen_hadiah: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">% Rp. </label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.nominal_hadiah} onChange={e => this.setState({nominal_hadiah: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-check mb-1">
                                                                <input type="checkbox" className="form-check-input"
                                                                       checked={(this.state.flag_amortisasi_hadiah === 1) ? true : false}
                                                                       onClick={() => {this.toggleCheckbox('flag_amortisasi_hadiah')}}/>
                                                                <label className="form-check-label">Amortisasi</label>
                                                            </div>
                                                            <textarea className="form-control" rows="3" value={this.state.keterangan_hadiah} onChange={e => this.setState({keterangan_hadiah: e.target.value})}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-3">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Alokasi Bunga</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Alokasi Bunga</label>
                                                        <Select
                                                            options={Helpers.optionsAlokasiBunga}
                                                            value={Helpers.optionsAlokasiBunga.filter((item) => item.value === this.state.alokasi_bunga)}
                                                            onChange={event => this.handleChange(event,'alokasi_bunga')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Keterangan 1</label>
                                                        <textarea className="form-control" rows="3" value={this.state.alokasi_bunga_note} onChange={e => this.setState({alokasi_bunga_note: e.target.value})}></textarea>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Keterangan 2</label>
                                                        <textarea className="form-control" rows="3" value={this.state.alokasi_bunga_note2} onChange={e => this.setState({alokasi_bunga_note2: e.target.value})}></textarea>
                                                    </div>

                                                    {
                                                        (this.state.alokasi_bunga === '30') && (
                                                            <>
                                                                <h4 className="header-title mt-3">Rekening Tabungan Penampung Bunga</h4>
                                                                <div className="dropdown-divider"></div>
                                                                <div className="mb-1">
                                                                    <label className="form-label">No. Rekening</label>
                                                                    <Select
                                                                        options={this.state.dataRekening}
                                                                        value={this.state.dataRekening.filter((item) => item.value === this.state.rekening_si_otodebet)}
                                                                        onChange={event => this.handleChange(event,'rekening_si_otodebet')} />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-4">
                                            <div className={'row'}>
                                                <div className={'col-12'}>
                                                    <h4 className="header-title">Khusus Antar Bank Aktiva</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-5">
                                                            <label className="form-label">Provisi</label>
                                                            <input type="number" className="form-control" value={this.state.persen_provisi} onChange={e => this.setState({persen_provisi: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">% Rp. </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.nominal_provisi} onChange={e => this.setState({nominal_provisi: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-5">
                                                            <label className="form-label">Admin</label>
                                                            <input type="number" className="form-control" value={this.state.persen_admin} onChange={e => this.setState({persen_admin: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">% Rp. </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.nominal_admin} onChange={e => this.setState({nominal_admin: e.target.value})} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DataRekeningForm;