import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../../config/Connection";
import ModalReport from "../../../../components/pdf/laporan/tabungan/ModalReport";
import ModalKodepos from "../../../../components/ModalKodepos";

class HapusTransaksi extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],
            setModal: '',
            startDate: '',
            endDate: '',
            checklist: []
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }
    }

    getData = () => {
        if(this.state.startDate && this.state.endDate){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'tabungan-transaction/report/transaction?tgl_trans_start='+this.state.startDate+'&tgl_trans_end='+this.state.endDate+'&by_system=0&limit=0').then(res => {
                if(res.data){
                    this.setState({
                        dataSource: res.data
                    });
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }else{
            toast.error('Anda belum memilih periode');
        }
    };

    delete = () => {
        if(this.state.dataSource.length > 0){
            if(this.state.checklist.length > 0){
                let urlencoded = new URLSearchParams();
                this.state.checklist.forEach(x => {
                    urlencoded.append("trans_id[]", x);
                });

                $(".loader-wrapper").css("display", "");
                fetch(Connection.API_URL + 'tabungan-transaction', {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': 'id',
                        'Cache-Control': 'no-cache',
                        'Authorization': 'Bearer ' + this.state.sessToken
                    },
                    body: urlencoded
                }).then((res) => res.json()).then((json) => {
                    if(json.exception){
                        toast.error(json.message);
                    }else{
                        if(json.errors){
                            alert(Helpers.customError(json.errors));
                        }else{
                            if(json.status === 'error'){
                                toast.error(json.message);
                            }else{
                                toast.success(json.message);

                                this.setState({ checklist: [] });

                                this.getData();
                            }
                        }
                    }

                    $(".loader-wrapper").css("display", "none");
                }).catch((e) => {
                    $(".loader-wrapper").css("display", "none");
                });
            }else{
                toast.error('Mohon untuk memilih transaksi terlebih dahulu');
            }
        }else{
            toast.error('Mohon untuk proses data terlebih dahulu');
        }
    };

    toggleCheckbox = (value) => {
        let checked = this.state.checklist.includes(value);
        let rawChecklist = [];

        if(checked){
            rawChecklist = this.state.checklist.filter(function(x){
                return x !== value;
            })
        }else{
            rawChecklist = this.state.checklist;
            rawChecklist.push(value);
        }

        this.setState({ checklist: rawChecklist });
    };

    render() {
        return (
            <div className="content">
                { this.state.setModal }

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Back Office</li>
                                        <li className="breadcrumb-item">Tabungan</li>
                                        <li className="breadcrumb-item">Administrasi</li>
                                        <li className="breadcrumb-item active">Hapus Transaksi</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Hapus Transaksi</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label">Tanggal</label>
                                            <input type="date" className="form-control" value={this.state.startDate} onChange={e => this.setState({startDate: e.target.value})} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Ke</label>
                                            <input type="date" className="form-control" value={this.state.endDate} onChange={e => this.setState({endDate: e.target.value})} />
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.getData()}}>
                                                Cari
                                            </a>
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.delete()}}>
                                                Hapus
                                            </a>
                                        </div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'Pilih',
                                                        field: 'checklist',
                                                    },
                                                    {
                                                        label: 'No. Rekening',
                                                        field: 'no_rekening',
                                                    },
                                                    {
                                                        label: 'Nama Nasabah',
                                                        field: 'nama_nasabah',
                                                    },
                                                    {
                                                        label: 'Tgl Transaksi',
                                                        field: 'tgl_trans',
                                                    },
                                                    {
                                                        label: 'No. Bukti',
                                                        field: 'kuitansi',
                                                    },
                                                    {
                                                        label: 'Deskripsi',
                                                        field: 'keterangan',
                                                    },
                                                    {
                                                        label: 'Jumlah',
                                                        field: 'saldo',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    let checked = this.state.checklist.includes(row.trans_id);

                                                    return {
                                                        ...row,
                                                        saldo: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.saldo_trans_formatted}</label>
                                                        ),
                                                        checklist: (
                                                            <input type="checkbox" className="form-check-input"
                                                                   checked={checked}
                                                                   onClick={() => {this.toggleCheckbox(row.trans_id)}}/>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HapusTransaksi;