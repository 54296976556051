import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";

class Kolektif extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: '',
            urlParam: queryString.parse(window.location.search),
            jn_kantor_kode: Helpers.DEF_COMPANY_CODE,
            jn_kantor_nama: Helpers.DEF_COMPANY,
            black_list: 0,

            getIdRekening: '',
            dataJenisNasabah: [],
            dataKota: [],
            dataKodepos: [],
            dataBidangUsaha: [],
            dataNegara: [],

            dataLokasi: [],
            dataGolDebitur: [],
            dataPemilik: [],
            dataGolKreditur: [],
            dataSlikPihakTiga: [],
            dataSlikHubungan: [],
            dataBank: [],

            dataJenisIdentitas: [],
            dataJenisKelamin: [],
            dataStatusPerkawinan: [],
            dataAgama: [],
            dataStatusRumah: [],

            dataPekerjaan: [],
            dataSumberPenghasilan: [],

            dataAPUPPTSoal: [],
            dataAPUPPT: [],
            dataAPUPPTScoring: []
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.setState({
                getIdRekening: (this.state.urlParam.ref) ? this.state.urlParam.ref : '',
            }, () => {
                /*this.getMasterData('kode-jenis-nasabah?limit=0','dataJenisNasabah','real_id','deskripsi');
                this.getMasterData('kode-kota?limit=0','dataKota','real_id','deskripsi');
                //this.getMasterData('kode-pos?limit=0','dataKodepos','real_id','kelurahan');
                this.getMasterData('kode-sektor-ekonomi?limit=0','dataBidangUsaha','real_id','deskripsi');
                this.getMasterData('kode-negara?limit=0','dataNegara','real_id','deskripsi');

                this.getMasterData('kode-lokasi?limit=0','dataLokasi','real_id','deskripsi');
                this.getMasterData('kode-gol-debitur?limit=0','dataGolDebitur','real_id','deskripsi');
                this.getMasterData('kode-pemilik?limit=0','dataPemilik','real_id','deskripsi');
                this.getMasterData('kode-gol-kreditur?limit=0','dataGolKreditur','real_id','deskripsi');
                this.getMasterData('kode-slik-pihak-tiga?limit=0','dataSlikPihakTiga','real_id','deskripsi');
                this.getMasterData('kode-slik-hubungan-ljk?limit=0','dataSlikHubungan','real_id','deskripsi');

                this.getMasterData('kode-bank?limit=0','dataBank','real_id','deskripsi');

                this.getMasterData('kode-jenis-identitas?limit=0','dataJenisIdentitas','real_id','deskripsi');
                this.getMasterData('kode-jenis-kelamin?limit=0','dataJenisKelamin','real_id','deskripsi');
                this.getMasterData('kode-status-perkawinan?limit=0','dataStatusPerkawinan','real_id','deskripsi');
                this.getMasterData('kode-agama?limit=0','dataAgama','real_id','deskripsi');
                this.getMasterData('kode-status-rumah?limit=0','dataStatusRumah','real_id','deskripsi');

                this.getMasterData('kode-pekerjaan?limit=0','dataPekerjaan','real_id','deskripsi');
                this.getMasterData('kode-sumber-penghasilan?limit=0','dataSumberPenghasilan','real_id','deskripsi');

                this.getDataAPUPPTJawaban();
                this.getDataAPUPPTSoal();

                if(this.state.getIdRekening){
                    this.getData();
                }*/
            });
        }
    }

    getMasterData = (endpoint,state,value,label) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: item[label]
                        };
                    })
                });
            }
        })
    };

    getDataAPUPPTSoal = () => {
        Helpers.getMasterData(this.state.sessToken,'apu-soal?limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataAPUPPTSoal: res.data
                }, () => {
                    const entries = document.querySelector('[data-test="mdb-datatable-entries"]');
                    const row = entries.parentNode.parentNode.parentNode;

                    row.style.display = 'none';
                });
            }
        })
    };

    getDataAPUPPTJawaban = () => {
        Helpers.getMasterData(this.state.sessToken,'apu-jawab?limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataAPUPPT: res.data.map(function(item) {
                        return {
                            value: item.real_id,
                            label: item.nilai + ' - ' + item.deskripsi,
                            no_soal: item.no_soal,
                            nilai: item.nilai
                        };
                    })
                });
            }
        })
    };

    countNilaiAPUPPT = () => {
        let data_length = this.state.dataAPUPPTSoal.length;

        if(data_length>0){
            let apuppt_nilai_raw = 0.0;
            let apuppt_nilai = 0.0;
            for(let i=1; i<=data_length; i++){
                const findValue = this.state.dataAPUPPT.filter((item) => item.value === this.state['apu_scoring_'+i.toString()])[0].nilai;

                this.setState({
                    ['apu_scoring_nilai_' + i.toString()]: findValue
                }, () => {
                    apuppt_nilai_raw += findValue;
                    apuppt_nilai = parseFloat(apuppt_nilai_raw/data_length).toFixed(2);

                    this.setState({
                        apuppt_nilai: apuppt_nilai,
                        apuppt_kesimpulan: (apuppt_nilai < 1.5) ? 'Resiko Rendah' : (apuppt_nilai >= 1.5 && apuppt_nilai < 2.5) ? 'Resiko Sedang' : 'Resiko Tinggi'
                    });
                });
            }
        }
    };

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getDataNasabahDetail(this.state.sessToken,this.state.getIdRekening).then(res => {
            if(res.data){
                const data = res.data;
                this.setState({
                    dataSource: data,
                    nama_nasabah: data.nama_nasabah,
                    nama_alias: data.nama_alias,
                    jenis_nasabah: data.jenis_nasabah,
                    black_list: data.black_list,
                    black_list_note: data.black_list_note,
                    alamat: data.alamat,
                    kode_kota: data.kota.kode_kota,
                    kodepos: data.kodepos.id_pos,
                    alamat_domisili: data.alamat_domisili,
                    kota_domisili: data.kota_domisili.kode_kota,
                    id_pos_domisili: data.id_pos_domisili,

                    telpon: data.telpon,
                    handphone: data.handphone,
                    //id_pos_domisili: data.id_pos_domisili,
                    bidang_usaha_perusahaan: data.bidang_usaha_perusahaan,
                    kode_negara: data.negara.kode_negara,
                    tgl_registrasi: moment(data.tgl_registrasi).utc().format('YYYY-MM-DD'),
                    npwp: data.npwp,
                    email: data.email,
                    status_ebanking: data.status_ebanking,

                    kode_lokasi: data.kode_lokasi,
                    kode_gol_debitur: data.kode_gol_debitur,
                    kode_pemilik: data.kode_pemilik,
                    bank_terkait: data.bank_terkait,
                    kode_gol_kreditur: data.kode_gol_kreditur,
                    sid_gol_debitur: data.sid_gol_debitur,
                    sid_hubungan_ljk: data.sid_hubungan_ljk,

                    bank_tabungan_1: this.checkNull(data.bank_tabungan_1),
                    bank_tabungan_2: this.checkNull(data.bank_tabungan_2),
                    bank_tabungan_3: this.checkNull(data.bank_tabungan_3),
                    no_rek_tabungan_1: this.checkNull(data.no_rek_tabungan_1),
                    no_rek_tabungan_2: this.checkNull(data.no_rek_tabungan_2),
                    no_rek_tabungan_3: this.checkNull(data.no_rek_tabungan_3),

                    bank_kartu_kredit_1: this.checkNull(data.bank_kartu_kredit_1),
                    bank_kartu_kredit_2: this.checkNull(data.bank_kartu_kredit_2),
                    bank_kartu_kredit_3: this.checkNull(data.bank_kartu_kredit_3),
                    no_kartu_kredit_1: this.checkNull(data.no_kartu_kredit_1),
                    no_kartu_kredit_2: this.checkNull(data.no_kartu_kredit_2),
                    no_kartu_kredit_3: this.checkNull(data.no_kartu_kredit_3),

                    jenis_identitas: data.jenis_identitas,
                    no_id: data.no_id,
                    tgl_id: data.tgl_id,
                    pass_id: data.pass_id,
                    tgl_passpor: data.tgl_passpor,
                    tempat_lahir: data.tempat_lahir,
                    tgl_lahir: data.tgl_lahir,
                    jenis_kelamin: data.jenis_kelamin,
                    status_perkawinan: data.status_perkawinan,
                    kode_agama: data.agama.kode_agama,
                    ibu_kandung: data.ibu_kandung,
                    status_rumah_tinggal: data.status_rumah_tinggal,
                    tgl_rumah_tinggal: data.tgl_rumah_tinggal,

                    nama_pasangan: data.nama_pasangan,
                    tempat_lahir_pasangan: data.tempat_lahir_pasangan,
                    tgl_lahir_pasangan: data.tgl_lahir_pasangan,
                    no_id_pasangan: data.no_id_pasangan,
                    tgl_id_pasangan: data.tgl_id_pasangan,
                    tgl_nikah: data.tgl_nikah,
                    handphone_pasangan: data.handphone_pasangan,
                    pekerjaan_pasangan: data.pekerjaan_pasangan,

                    kode_pekerjaan: (data.kode_pekerjaan) ? data.kode_pekerjaan.kode_pekerjaan : null,
                    nama_perusahaan: data.nama_perusahaan,
                    alamat_perusahaan: data.alamat_perusahaan,
                    kode_pos_perusahaan: data.kode_pos_perusahaan,
                    telpon_perusahaan: data.telpon_perusahaan,
                    fax_perusahaan: data.fax_perusahaan,
                    sumber_penghasilan: data.sumber_penghasilan,
                    jml_penghasilan_per_tahun: data.jml_penghasilan_per_tahun,
                    jml_tanggungan: data.jml_tanggungan,

                    apu_scoring_1: (data.apu_scoring.length > 0) ? data.apu_scoring[0].no_jawab : null,
                    apu_scoring_2: (data.apu_scoring.length > 0) ? data.apu_scoring[1].no_jawab : null,
                    apu_scoring_3: (data.apu_scoring.length > 0) ? data.apu_scoring[2].no_jawab : null,
                    apu_scoring_4: (data.apu_scoring.length > 0) ? data.apu_scoring[3].no_jawab : null,
                    apu_scoring_5: (data.apu_scoring.length > 0) ? data.apu_scoring[4].no_jawab : null,
                    apu_scoring_6: (data.apu_scoring.length > 0) ? data.apu_scoring[5].no_jawab : null,
                    apu_scoring_7: (data.apu_scoring.length > 0) ? data.apu_scoring[6].no_jawab : null,
                    tgl_proses: (data.apu_scoring.length > 0) ? data.apu_scoring[0].tgl_proses : null,
                    tgl_penilaian: data.tgl_penilaian,
                    dataAPUPPTScoring: data.apu_scoring
                }, () => {
                    this.countNilaiAPUPPT();
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value }, () => {
            if(state.substring(0,3) === 'apu'){
                this.countNilaiAPUPPT();
            }
        });
    };

    toggleCheckbox = (state) => {
        this.setState({ [state]: (this.state[state] === 0) ? 1 : 0 });
    };

    handleImageChange = (event,state) => {
        this.setState({
            [state]: URL.createObjectURL(event.target.files[0])
        })
    };

    checkNull = (val) => {
        return (val !== null) ? val : '';
    };

    submitData = () => {
        if(this.state.getIdRekening){
            this.saveNasabah('PATCH','nasabah/'+this.state.getIdRekening);
        }else{
            this.saveNasabah('POST','nasabah');
        }
    };

    saveNasabah = (method,endpoint) => {
        let urlencoded = new URLSearchParams();
        urlencoded.append("nama_nasabah", this.state.nama_nasabah);
        urlencoded.append("nama_alias", this.state.nama_alias);
        urlencoded.append("alamat", this.state.alamat);
        urlencoded.append("id_pos", "26512");
        urlencoded.append("kode_kota", this.state.kode_kota);
        urlencoded.append("alamat_domisili", this.state.alamat_domisili);
        urlencoded.append("id_pos_domisili", "26512");
        urlencoded.append("kode_kota_domisili", this.state.kota_domisili);
        urlencoded.append("telpon", this.state.telpon);
        urlencoded.append("jenis_kelamin", this.state.jenis_kelamin);
        urlencoded.append("pekerjaan", ""); //
        urlencoded.append("kode_pekerjaan", this.state.kode_pekerjaan);
        urlencoded.append("tempat_lahir", this.state.tempat_lahir);
        urlencoded.append("tgl_lahir", this.state.tgl_lahir);
        urlencoded.append("kode_gelar", "0104");
        urlencoded.append("jenis_identitas", this.state.jenis_identitas);
        urlencoded.append("no_id", this.state.no_id);
        urlencoded.append("npwp", this.state.npwp);
        urlencoded.append("shu_pinjaman", "");
        urlencoded.append("shu_simpanan", "");
        urlencoded.append("ibu_kandung", this.state.ibu_kandung);
        urlencoded.append("keterangan_gelar", "");
        urlencoded.append("no_din", "");
        urlencoded.append("sid_gol_debitur", this.state.sid_gol_debitur);
        urlencoded.append("nama_perusahaan", this.state.nama_perusahaan);
        urlencoded.append("kode_bidang_usaha_perusahaan", "504000");
        urlencoded.append("kode_negara", this.state.kode_negara);
        urlencoded.append("sid_hubungan_ljk", this.state.sid_hubungan_ljk);
        urlencoded.append("jenis_nasabah", this.state.jenis_nasabah);
        urlencoded.append("tgl_id", this.state.tgl_id);
        urlencoded.append("black_list", this.state.black_list);
        urlencoded.append("tgl_registrasi", this.state.tgl_registrasi);
        urlencoded.append("surat_izin_usaha_perusahaan", "");
        urlencoded.append("tanda_daftar_perusahaan", "");
        urlencoded.append("surat_domisili_perusahaan", "");
        urlencoded.append("surat_kuasa_perusahaan", "");
        urlencoded.append("surat_kemenkumham", "");
        urlencoded.append("tgl_kemenkumham", "");
        urlencoded.append("handphone", this.state.handphone);
        urlencoded.append("alamat_perusahaan", this.state.alamat_perusahaan);
        urlencoded.append("id_pos_perusahaan", "26512");
        urlencoded.append("telpon_perusahaan", this.state.telpon_perusahaan);
        urlencoded.append("fax_perusahaan", this.state.fax_perusahaan);
        urlencoded.append("group_perusahaan", "");
        urlencoded.append("tempat_pendirian_perusahaan", "");
        urlencoded.append("bank_tabungan_1", this.state.bank_tabungan_1);
        urlencoded.append("bank_tabungan_2", this.state.bank_tabungan_2);
        urlencoded.append("bank_tabungan_3", this.state.bank_tabungan_3);
        urlencoded.append("no_rek_tabungan_1", this.state.no_rek_tabungan_1);
        urlencoded.append("no_rek_tabungan_2", this.state.no_rek_tabungan_2);
        urlencoded.append("no_rek_tabungan_3", this.state.no_rek_tabungan_3);
        urlencoded.append("bank_kartu_kredit_1", this.state.bank_kartu_kredit_1);
        urlencoded.append("bank_kartu_kredit_2", this.state.bank_kartu_kredit_2);
        urlencoded.append("bank_kartu_kredit_3", this.state.bank_kartu_kredit_3);
        urlencoded.append("no_kartu_kredit_1", this.state.no_kartu_kredit_1);
        urlencoded.append("no_kartu_kredit_2", this.state.no_kartu_kredit_2);
        urlencoded.append("no_kartu_kredit_3", this.state.no_kartu_kredit_3);
        urlencoded.append("black_list_note", this.state.black_list_note);
        urlencoded.append("tgl_buka", "");
        urlencoded.append("kode_bank", "");
        urlencoded.append("bank_terkait", this.state.bank_terkait);
        urlencoded.append("kode_gol_debitur", this.state.kode_gol_debitur);
        urlencoded.append("kode_gol_kreditur", this.state.kode_gol_kreditur);
        urlencoded.append("kode_lokasi", this.state.kode_lokasi);
        urlencoded.append("kode_pemilik", this.state.kode_pemilik);
        urlencoded.append("no_paspor", this.state.pass_id);
        urlencoded.append("no_akte_awal", "");
        urlencoded.append("tgl_akte_awal", "");
        urlencoded.append("tgl_berlaku_paspor", (this.state.tgl_passpor) ? this.state.tgl_passpor : '1990-01-01');
        urlencoded.append("shu_simpanan_pendiri", "");
        urlencoded.append("shu_pinjaman_pendiri", "");
        urlencoded.append("tgl_passpor", (this.state.tgl_passpor) ? this.state.tgl_passpor : '1990-01-01');
        urlencoded.append("status_perkawinan", this.state.status_perkawinan);
        urlencoded.append("jml_tanggungan", this.state.jml_tanggungan);
        urlencoded.append("nama_pasangan", this.state.nama_pasangan);
        urlencoded.append("tempat_lahir_pasangan", this.state.tempat_lahir_pasangan);
        urlencoded.append("tgl_lahir_pasangan", (this.state.tgl_lahir_pasangan) ? this.state.tgl_lahir_pasangan : '1990-01-01');
        urlencoded.append("no_id_pasangan", this.state.no_id_pasangan);
        urlencoded.append("tgl_id_pasangan", (this.state.tgl_id_pasangan) ? this.state.tgl_id_pasangan : '1990-01-01');
        urlencoded.append("handphone_pasangan", this.state.handphone_pasangan);
        urlencoded.append("pekerjaan_pasangan", this.state.pekerjaan_pasangan);
        urlencoded.append("tgl_nikah", (this.state.tgl_nikah) ? this.state.tgl_nikah : '1990-01-01');
        urlencoded.append("agama", this.state.kode_agama);
        urlencoded.append("langgar_BMPK", "");
        urlencoded.append("lampui_BMPK", "");
        urlencoded.append("rating_perusahaan", "");
        urlencoded.append("kode_lembaga_rating", "13");
        urlencoded.append("tgl_rating", "");
        urlencoded.append("go_public", "");
        urlencoded.append("status_rumah_tinggal", this.state.status_rumah_tinggal);
        urlencoded.append("tgl_rumah_tinggal", (this.state.tgl_rumah_tinggal) ? this.state.tgl_rumah_tinggal : '1990-01-01');
        urlencoded.append("status_ebanking", this.state.status_ebanking);
        urlencoded.append("id_mobile", "");
        urlencoded.append("sumber_penghasilan", this.state.sumber_penghasilan);
        urlencoded.append("jml_penghasilan_per_tahun", this.state.jml_penghasilan_per_tahun);
        urlencoded.append("email", this.state.email);
        urlencoded.append("nama_kuasa_perusahaan_1", "");
        urlencoded.append("alamat_kuasa_perusahaan_1", "");
        urlencoded.append("noid_kuasa_perusahaan_1", "");
        urlencoded.append("nama_kuasa_perusahaan_2", "");
        urlencoded.append("alamat_kuasa_perusahaan_2", "");
        urlencoded.append("noid_kuasa_perusahaan_2", "");
        urlencoded.append("jabatan_kuasa_perusahaan_2", "");
        urlencoded.append("shu_uraian", "");
        urlencoded.append("apu_scoring", "01-1,02-1,03-02,04-1,05-1,06-1,07-1");
        urlencoded.append("tgl_penilaian", this.state.tgl_penilaian);

        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => res.json()).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        toast.success(Helpers.MSG_SUCCESS);

                        setTimeout(function() {
                            window.location.href = './data-rekening';
                        }, 1000);
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });

        // Helpers.saveNasabah(this.state.sessToken, method, endpoint, urlencoded).then(res => {
        //     if(res.errors){
        //         toast.error(res.message);
        //     }else{
        //         toast.success(Helpers.MSG_SUCCESS);
        //
        //         // setTimeout(function() {
        //         //     window.location.href = './data-nasabah';
        //         // }, 1000);
        //     }
        //
        //     $(".loader-wrapper").css("display", "none");
        // })
    };

    render() {
        const optionsPick = [
            { value: '1', label: 'Ya' },
            { value: '0', label: 'Tidak' }
        ]

        const optionsKeterkaitan = [
            { value: '10', label: 'Terkait' },
            { value: '20', label: 'Tidak Terkait' },
            { value: '12', label: 'Terkait Lainnya' }
        ]

        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Teller</li>
                                        <li className="breadcrumb-item">Tabungan</li>
                                        <li className="breadcrumb-item active">Kolektif</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Kolektif</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                        <i className="fe-save me-1"></i> Simpan
                                    </a>
                                    <a className="btn btn-danger waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                        <i className="fe-trash me-1"></i> Bersihkan
                                    </a>
                                    <a className="btn btn-info waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                        <i className="fe-printer me-1"></i> Print Validasi
                                    </a>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-bordered">
                                        <li className="nav-item">
                                            <a href="#form-1" data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                                Teller Tabungan
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="form-1">
                                            <div className={'row'}>
                                                <div className={'col-5'}>
                                                    <h4 className="header-title">Sumber Data</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Pilih Data</label>
                                                        <Select
                                                            options={this.state.dataKota}
                                                            value={this.state.dataKota.filter((item) => item.value === this.state.kota_domisili)}
                                                            onChange={event => this.handleChange(event,'kota_domisili')} />
                                                    </div>

                                                    <div className="dropdown-divider mt-3 mb-2"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kantor</label>
                                                        <input type="date" className="form-control" value={this.state.nama_nasabah} onChange={e => this.setState({nama_nasabah: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tgl Transaksi</label>
                                                        <Select
                                                            options={this.state.dataKota}
                                                            value={this.state.dataKota.filter((item) => item.value === this.state.kota_domisili)}
                                                            onChange={event => this.handleChange(event,'kota_domisili')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Transaksi</label>
                                                        <input type="text" readOnly={true} className="form-control" value={this.state.nama_nasabah} onChange={e => this.setState({nama_nasabah: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Pembayaran</label>
                                                        <input type="text" readOnly={true} className="form-control" value={this.state.nama_nasabah} onChange={e => this.setState({nama_nasabah: e.target.value})} />
                                                    </div>

                                                    <div className="dropdown-divider mt-3 mb-2"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Berdasarkan</label>
                                                        <Select
                                                            options={this.state.dataKota}
                                                            value={this.state.dataKota.filter((item) => item.value === this.state.kota_domisili)}
                                                            onChange={event => this.handleChange(event,'kota_domisili')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kolektor</label>
                                                        <input type="text" className="form-control" value={this.state.nama_nasabah} onChange={e => this.setState({nama_nasabah: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kuitansi</label>
                                                        <textarea className="form-control" rows="3" value={this.state.alamat} onChange={e => this.setState({alamat: e.target.value})}></textarea>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Keterangan</label>
                                                        <Select
                                                            options={this.state.dataKota}
                                                            value={this.state.dataKota.filter((item) => item.value === this.state.kota_domisili)}
                                                            onChange={event => this.handleChange(event,'kota_domisili')} />
                                                    </div>
                                                </div>
                                                <div className={'col-7'}>
                                                    <table class="table" width="100%">
                                                        <thead class="table-light">
                                                        <tr>
                                                            <th>
                                                                <a className="btn btn-info btn-xs waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                                                    <i className="fe-plus"></i>
                                                                </a>
                                                            </th>
                                                            <th>No. Rekening</th>
                                                            <th>Nama Nasabah</th>
                                                            <th>Jumlah</th>
                                                            <th>Keterangan</th>
                                                            <th>Status</th>
                                                            <th>Log</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Kolektif;