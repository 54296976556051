import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";
import '../../../styles/NoBorderTable.css';
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {Document, Page} from "react-pdf";

class Perseorangan extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: '',
            urlParam: queryString.parse(window.location.search),

            virtual_account: 0,
            tgl_trans: moment(new Date()).utc().format('YYYY-MM-DD'),
            keterangan: '',
            ket_jenis: '',
            ket_pembayaran: '',
            nama_nasabah: '',
            no_rekening: '',
            alamat: '',
            kuitansi: '',
            kolektor: '',
            reff_account: '',
            id_cetak: '',
            no_rek_ob: '',
            nama_nasabah_ob: '',
            alamat_ob: '',
            ob_title: '',
            jenis_produk: '',
            n_jumlah: '0.00',
            n_biaya_admin: '0.00',
            n_premi_percentage: '0.00',
            n_premi_jumlah: '0.00',
            n_bunga: '0.00',
            n_pajak: '0.00',
            n_total: '0.00',
            dataRekening: [],
            dataRekeningDeposito: [],
            dataUsers: [],
            //dataKodeTransaksiTabungan: JSON.parse(ReactSession.get("bprsess_dataKodeTransaksiTabungan")),
            //dataKodeJenisTransaksiTabungan: JSON.parse(ReactSession.get("bprsess_dataKodeJenisTransaksiTabungan")),
            dataKodeTransaksiTabungan: [],
            dataKodeJenisTransaksiTabungan: [],
            dataKodeJenisPembayaran: JSON.parse(ReactSession.get("bprsess_dataKodeJenisPembayaran")),
            isShowKodeTransaksi: false,
            isShowOB: false,
            isShowRekeningDeposito: false,
            multiValue: [],
            multiRekeningDeposito: [],

            isModalOpen: false,
            reportStream: null,
            pdfWidth: 0
        };
        this.handleMultiChange = this.handleMultiChange.bind(this);
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            //this.getMasterData('kode-transaksi-tabungan?search=jenis_pembayaran:20&limit=0','dataKodeTransaksiTabungan','real_id',['real_id','deskripsi']);
            this.getMasterData('kode-jenis-transaksi-tabungan?search=by_system:0','dataKodeJenisTransaksiTabungan','real_id','deskripsi');
            this.getMasterData('rekening-tabungan?limit=0','dataRekening','id',['no_rekening','nasabah|nama']);
            this.getMasterData('users?search=is_backoffice:1&limit=0','dataUsers','userid',['userid','name']);
        }
    }

    getMasterData = (endpoint,state,value,label,except) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                if(except){
                    this.setState({
                        [state]: res.data.filter(function (e) {
                            return e[except] !== '';
                        }).map(function(item) {
                            return {
                                value: item[value],
                                label: (label instanceof Array) ? label.map(function(val, index) {
                                    let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                    return (
                                        index === label.length-1
                                    ) ? setVal : setVal + ' - ';
                                }) : item[label].trim()
                            };
                        })
                    });
                }else{
                    this.setState({
                        [state]: res.data.map(function(item) {
                            return {
                                value: item[value],
                                label: (label instanceof Array) ? label.map(function(val, index) {
                                    let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                    return (
                                        index === label.length-1
                                    ) ? setVal : setVal + ' - ';
                                }) : item[label].trim()
                            };
                        })
                    });
                }
            }
        });
    };

    setOBTitle = () => {
        if(this.state.ket_jenis === 'Setoran' && this.state.ket_pembayaran === 'OB Tabungan/Giro' && this.state.jenis_produk === '1'){
            this.setState({ ob_title: 'Penarikan' });
        }else if(this.state.ket_jenis === 'Setoran' && this.state.ket_pembayaran === 'OB Tabungan/Giro' && this.state.jenis_produk === '2') {
            this.setState({ob_title: 'Setoran'});
        }else if(this.state.ket_jenis === 'Penarikan' && this.state.ket_pembayaran === 'OB Tabungan/Giro' && this.state.jenis_produk === '1'){
            this.setState({ ob_title: 'Setoran' });
        }else if(this.state.ket_jenis === 'Penarikan' && this.state.ket_pembayaran === 'OB Tabungan/Giro' && this.state.jenis_produk === '2'){
            this.setState({ ob_title: 'Penarikan' });
        }else{
            this.setState({ ob_title: '' });
        }
    }

    handleChange = (event,state) => {
        this.setState({ [state]: event.value }, () => {
            this.setOBTitle();
        });

        if(state === 'my_kode_trans') {
            this.setState({
                ket_jenis: event.label,
            }, () => {
                this.setOBTitle();

                this.setState({
                    keterangan: this.state.ket_jenis + ': ' + this.state.ket_pembayaran + ' - ' + this.state.no_rekening + ' - ' + this.state.nama_nasabah,
                });
            });
        }else if(state === 'jenis_pembayaran'){
            this.setState({
                ket_pembayaran: event.label,
                //ob_title: (event.label === 'OB Tabungan/Giro') ? (this.state.ket_jenis === 'Setoran') : '',
                jenis_produk: (event.label === 'OB Tabungan/Giro') ? this.state.jenis_produk : '',
                nama_nasabah_ob: (event.label === 'OB Tabungan/Giro') ? this.state.nama_nasabah_ob : '',
                alamat_ob: (event.label === 'OB Tabungan/Giro') ? this.state.alamat_ob : '',
                no_rek_ob: (event.label === 'OB Tabungan/Giro') ? this.state.no_rek_ob : '',
                isShowKodeTransaksi: (event.label === 'OB GL') ? true : false,
                isShowOB: (event.label === 'OB Tabungan/Giro') ? true : false,
                kode_transaksi: this.state.kode_transaksi
            }, () => {
                this.setOBTitle();

                this.getMasterData('kode-transaksi-tabungan?search=jenis_pembayaran:'+event.value+'&limit=0','dataKodeTransaksiTabungan','real_id',['real_id','deskripsi'],'kode_perk');

                if(event.label === 'Tunai'){
                    this.setState({
                        kode_transaksi: (this.state.ket_jenis === 'Setoran') ? '01' : (this.state.ket_jenis === 'Penarikan') ? '02' : this.state.ket_jenis,
                    }, () => {
                        console.log(this.state.kode_transaksi);
                    });
                }else if(event.label === 'OB Tabungan/Giro'){
                    this.setState({
                        kode_transaksi: '03',
                    }, () => {
                        console.log(this.state.kode_transaksi);
                    });
                }

                this.setState({
                    keterangan: this.state.ket_jenis + ': ' + this.state.ket_pembayaran + ' - ' + this.state.no_rekening + ' - ' + this.state.nama_nasabah,
                });
            });
        }else if(state === 'id_rekening'){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'rekening-tabungan/'+event.value).then(res => {
                if(res.data){
                    this.setState({
                        dataSource: res.data,
                        nama_nasabah: res.data.nasabah.nama,
                        alamat: res.data.nasabah.alamat,
                        no_rekening: res.data.no_rekening,
                        kolektor: res.data.id_ao,
                    }, () => {
                        this.setState({
                            keterangan: this.state.ket_jenis + ': ' + this.state.ket_pembayaran + ' - ' + this.state.no_rekening + ' - ' + this.state.nama_nasabah,
                        });
                    });
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }else if(state === 'id_rekening_ob'){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'rekening-tabungan/'+event.value).then(res => {
                if(res.data){
                    this.setState({
                        nama_nasabah_ob: res.data.nasabah.nama,
                        alamat_ob: res.data.nasabah.alamat,
                        no_rek_ob: res.data.no_rekening
                    });
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }else if(state === 'jenis_pembayaran'){
            this.getMasterData('kode-transaksi-tabungan?search=jenis_pembayaran:'+event.value+'&limit=0','dataKodeTransaksiTabungan','real_id',['real_id','deskripsi'],'kode_perk');
        }else if(state === 'kode_transaksi'){
            this.getMasterData('rekening-deposito?search=flag_amortisasi_fee:1&limit=0','dataRekeningDeposito','id',['no_rekening','nasabah|nama']);
            this.setState({
                isShowRekeningDeposito: (event.value === '56') ? true : false
            });
        }else if(state === 'id_rekening_deposito'){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'rekening-deposito/'+event.value).then(res => {
                if(res.data){
                    this.setState({
                        nama_nasabah_deposito: res.data.nasabah.nama,
                        alamat_deposito: res.data.nasabah.alamat,
                        no_rek_deposito: res.data.no_rekening,
                        n_jumlah: res.data.nominal_fee,
                        n_total: res.data.nominal_fee
                    });
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }
    };

    handleMultiChange(option) {
        this.setState({
            multiValue: option
        }, () => {
            if(this.state.multiValue.length > 0){
                $(".loader-wrapper").css("display", "");
                let countData = 1;
                this.state.multiValue.forEach((v, i) => {
                    Helpers.getMasterData(this.state.sessToken,'rekening-deposito/'+v.value).then(res => {
                        if(res.data){
                            let newStateArray = this.state.multiRekeningDeposito.slice();
                            newStateArray.push(res.data.no_rekening);

                            let n_jumlah = (this.state.n_jumlah === '0.00' || this.state.n_jumlah === '0') ? 0 : this.state.n_jumlah;
                            let newJumlah = res.data.nominal_fee;
                            let n_jumlah_new = n_jumlah+newJumlah;

                            let n_pajak = (this.state.n_pajak === '0.00' || this.state.n_pajak === '0') ? 0 : this.state.n_pajak;
                            let newPajak = res.data.nominal_fee_pajak;
                            let n_pajak_new = n_pajak+newPajak;

                            let n_total = (this.state.n_total === '0.00' || this.state.n_total === '0') ? 0 : this.state.n_total;
                            let newTotal = res.data.nominal_fee_nett;
                            let n_total_new = n_total+newTotal;

                            this.setState({
                                multiRekeningDeposito: newStateArray,
                                n_jumlah: n_jumlah_new,
                                n_pajak: n_pajak_new,
                                n_total: n_total_new
                            });
                        }else{
                            toast.error(Helpers.MSG_ERROR);
                        }

                        if(countData === this.state.multiValue.length){
                            $(".loader-wrapper").css("display", "none");
                        }

                        countData++;
                    })
                });
            }else{
                this.setState({
                    multiRekeningDeposito: [],
                    n_jumlah: '0',
                    n_total: '0'
                });
            }
        });
    }

    onTextChange = (event,state) => {
        this.setState({ [state]: event }, () => {
            let n_jumlah = parseFloat(this.state.n_jumlah).toFixed(2);
            let n_biaya_admin = parseFloat(this.state.n_biaya_admin).toFixed(2);
            let n_premi_percentage = parseFloat(this.state.n_premi_percentage).toFixed(2);
            let n_premi_jumlah = parseFloat((n_premi_percentage > 0) ? this.state.n_premi_jumlah*(parseFloat(n_premi_percentage)/100) : this.state.n_premi_jumlah).toFixed(2);;
            let n_bunga = parseFloat(this.state.n_bunga).toFixed(2);
            let n_pajak = parseFloat(this.state.n_pajak).toFixed(2);
            let n_total = parseFloat(n_jumlah) + parseFloat(n_biaya_admin) + parseFloat(n_premi_jumlah) + parseFloat(n_bunga) - parseFloat(n_pajak);

            this.setState({ n_total: parseFloat(n_total).toFixed(2) });

            if(state === 'n_premi_percentage' || state === 'n_premi_jumlah'){
                this.setState({
                    n_premi_percentage: n_premi_percentage,
                    n_premi_jumlah: n_premi_jumlah
                });
            }
        });
    };

    toggleCheckbox = (state) => {
        this.setState({ [state]: (this.state[state] === 0) ? 1 : 0 });
    };

    submitData = () => {
        this.saveNasabah('POST','tabungan-transaction');
    };

    saveNasabah = (method,endpoint) => {
        let urlencoded = new URLSearchParams();
        urlencoded.append("tgl_trans", this.state.tgl_trans);
        urlencoded.append("nominal", this.state.n_total);
        urlencoded.append("my_kode_trans", this.state.my_kode_trans);
        urlencoded.append("jenis_pembayaran", this.state.jenis_pembayaran);
        urlencoded.append("kode_transaksi", (typeof this.state.kode_transaksi === 'undefined') ? '' : this.state.kode_transaksi);
        urlencoded.append("no_rekening", this.state.no_rekening);
        urlencoded.append("kuitansi", Helpers.castEmpty(this.state.kuitansi));
        urlencoded.append("keterangan", Helpers.castEmpty(this.state.keterangan));
        urlencoded.append("no_rek_ob", Helpers.castEmpty(this.state.no_rek_ob));
        urlencoded.append("id_col", Helpers.castEmpty(this.state.kolektor));
        urlencoded.append("reff_account", Helpers.castEmpty(this.state.reff_account));
        urlencoded.append("jenis_produk", Helpers.castEmpty(this.state.jenis_produk));
        urlencoded.append("nominal_pajak", Helpers.castEmpty(this.state.n_pajak));
        urlencoded.append("list_norek_fee_marketing", this.state.multiRekeningDeposito.map((row,index) => {
            return row;
        }));

        $(".loader-wrapper").css("display", "");
        let getResponseCode = null;
        fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => {
            getResponseCode = res.status;
            return res.json();
        }).then((json) => {
            if(getResponseCode !== 200){
                toast.error(json.message);
            }else{
                if(json.exception){
                    toast.error(json.message);
                }else{
                    if(json.errors){
                        alert(Helpers.customError(json.errors));
                    }else{
                        if(json.status === 'error'){
                            toast.error(json.message);
                        }else{
                            toast.success(Helpers.MSG_SUCCESS);

                            this.setState({
                                trans_id: json.data.trans_id
                            });

                            /*setTimeout(function() {
                                window.location.href = './teller-tab-perseorangan';
                            }, 1000);*/
                        }
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    handleItemPrint = () => {
        if(this.state.trans_id){
            $(".loader-wrapper").css("display", "");
            Helpers.getReportPDF(this.state.sessToken,'tabungan-transaction/report/transaction/print/'+this.state.trans_id+'?format=pdf').then(res => {
                this.setState({
                    reportStream: res
                }, () => {
                    this.handleModal();
                });

                $(".loader-wrapper").css("display", "none");
            });
        }else{
            toast.error('Anda belum melakukan transaksi!');
        }
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleDownload = () => {
        const uid = (new Date().getTime());
        Helpers.download(this.state.reportStream, 'kuitansi_transaksi_tabungan_'+uid);
    };

    render() {
        const animatedComponents = makeAnimated();

        return (
            <div className="content">

                <Modal
                    size={'lg'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}>
                    <ModalBody>
                        <Document file={this.state.reportStream}>
                            <Page
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={this.state.pdfWidth}
                            />
                        </Document>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {this.handleDownload()}}>Download</Button>
                        <Button onClick={() => {this.handleModal()}}>Close</Button>
                    </ModalFooter>
                </Modal>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Teller</li>
                                        <li className="breadcrumb-item">Tabungan</li>
                                        <li className="breadcrumb-item active">Perseorangan</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Perseorangan</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                        <i className="fe-save me-1"></i> Simpan
                                    </a>
                                    <a className="btn btn-danger waves-effect waves-light mr-5" onClick={() => {window.location.reload()}}>
                                        <i className="fe-trash me-1"></i> Bersihkan
                                    </a>
                                    <a className="btn btn-info waves-effect waves-light mr-5" onClick={() => {}}>
                                        <i className="fe-printer me-1"></i> Print Buku Tabungan
                                    </a>
                                    <a className="btn btn-info waves-effect waves-light mr-5" onClick={() => {}}>
                                        <i className="fe-printer me-1"></i> Print Validasi
                                    </a>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-bordered">
                                        <li className="nav-item">
                                            <a href="#form-1" data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                                Teller Tabungan
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="form-1">
                                            <div className={'row'}>
                                                <div className={'col-5'}>
                                                    <div className="mb-1">
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input"
                                                                   checked={(this.state.virtual_account === 1) ? true : false}
                                                                   onClick={() => {this.toggleCheckbox('virtual_account')}}/>
                                                            <label className="form-check-label">Virtual Account</label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Transaksi <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataKodeJenisTransaksiTabungan}
                                                            value={this.state.dataKodeJenisTransaksiTabungan.filter((item) => item.value === this.state.my_kode_trans)}
                                                            onChange={event => this.handleChange(event,'my_kode_trans')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Pembayaran <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataKodeJenisPembayaran}
                                                            value={this.state.dataKodeJenisPembayaran.filter((item) => item.value === this.state.jenis_pembayaran)}
                                                            onChange={event => this.handleChange(event,'jenis_pembayaran')} />
                                                    </div>
                                                    {
                                                        this.state.isShowOB && (
                                                            <div className="mb-1">
                                                                <label className="form-label">Jenis Produk</label>
                                                                <Select
                                                                    options={Helpers.optionsJenisProduk}
                                                                    value={Helpers.optionsJenisProduk.filter((item) => item.value === this.state.jenis_produk)}
                                                                    onChange={event => this.handleChange(event,'jenis_produk')} />
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        this.state.isShowKodeTransaksi && (
                                                            <div className="mb-1">
                                                                <label className="form-label">Kode Transaksi <code>(required)</code></label>
                                                                <Select
                                                                    options={this.state.dataKodeTransaksiTabungan}
                                                                    value={this.state.dataKodeTransaksiTabungan.filter((item) => item.value === this.state.kode_transaksi)}
                                                                    onChange={event => this.handleChange(event,'kode_transaksi')} />
                                                            </div>
                                                        )
                                                    }

                                                    <div className="dropdown-divider mt-3 mb-2"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tgl Transaksi <code>(required)</code></label>
                                                        <input type="date" className="form-control" value={this.state.tgl_trans} onChange={e => this.setState({tgl_trans: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">No. Rekening <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataRekening}
                                                            value={this.state.dataRekening.filter((item) => item.value === this.state.id_rekening)}
                                                            onChange={event => this.handleChange(event,'id_rekening')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nama</label>
                                                        <input type="text" readOnly={true} className="form-control" value={this.state.nama_nasabah} onChange={e => this.setState({nama_nasabah: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Alamat</label>
                                                        <input type="text" readOnly={true} className="form-control" value={this.state.alamat} onChange={e => this.setState({alamat: e.target.value})} />
                                                    </div>

                                                    {
                                                        this.state.isShowRekeningDeposito && (
                                                            <>
                                                                <div className="dropdown-divider mt-3 mb-2"></div>
                                                                <div className="mb-1">
                                                                    <label className="form-label">No. Rekening Deposito</label>
                                                                    <Select
                                                                        closeMenuOnSelect={false}
                                                                        components={animatedComponents}
                                                                        isMulti
                                                                        options={this.state.dataRekeningDeposito}
                                                                        value={this.state.multiValue}
                                                                        onChange={this.handleMultiChange}
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                    <div className="dropdown-divider mt-3 mb-2"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kolektor</label>
                                                        <Select
                                                            options={this.state.dataUsers}
                                                            value={this.state.dataUsers.filter((item) => item.value === this.state.kolektor)}
                                                            onChange={event => this.handleChange(event,'kolektor')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kuitansi</label>
                                                        <input type="text" className="form-control" value={this.state.kuitansi} onChange={e => this.setState({kuitansi: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Keterangan</label>
                                                        <textarea className="form-control" rows="3" value={this.state.keterangan} onChange={e => this.setState({keterangan: e.target.value})}></textarea>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Reff Account</label>
                                                        <Select
                                                            options={this.state.dataRekening}
                                                            value={this.state.dataRekening.filter((item) => item.value === this.state.reff_account)}
                                                            onChange={event => this.handleChange(event,'reff_account')} />
                                                    </div>

                                                    {
                                                        this.state.isShowOB && (
                                                            <>
                                                                <div className="dropdown-divider mt-3 mb-2"></div>
                                                                <div className="mb-1">
                                                                    <label className="form-label">No. Rekening {this.state.ob_title}</label>
                                                                    <Select
                                                                        options={this.state.dataRekening}
                                                                        value={this.state.dataRekening.filter((item) => item.value === this.state.id_rekening_ob)}
                                                                        onChange={event => this.handleChange(event,'id_rekening_ob')} />
                                                                </div>
                                                                <div className="mb-1">
                                                                    <label className="form-label">Nama</label>
                                                                    <input type="text" readOnly={true} className="form-control" value={this.state.nama_nasabah_ob} onChange={e => this.setState({nama_nasabah_ob: e.target.value})} />
                                                                </div>
                                                                <div className="mb-1">
                                                                    <label className="form-label">Alamat</label>
                                                                    <input type="text" readOnly={true} className="form-control" value={this.state.alamat_ob} onChange={e => this.setState({alamat_ob: e.target.value})} />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div className={'col-7'}>
                                                    <table width="100%">
                                                        <thead>
                                                        <tr>
                                                            <th width="30%">Deskripsi</th>
                                                            <th width="20%">%</th>
                                                            <th width="50%">Nominal</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>Jumlah <code>(required)</code></td>
                                                            <td></td>
                                                            <td>
                                                                <input type="text" className="form-control txt-right" value={this.state.n_jumlah} onChange={e => this.onTextChange(e.target.value, 'n_jumlah')} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Biaya Admin</td>
                                                            <td></td>
                                                            <td>
                                                                <input type="text" className="form-control txt-right" value={this.state.n_biaya_admin} onChange={e => this.onTextChange(e.target.value, 'n_biaya_admin')} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Premi</td>
                                                            <td>
                                                                <input type="text" className="form-control txt-right" value={this.state.n_premi_percentage} onChange={e => this.onTextChange(e.target.value, 'n_premi_percentage')} min={0} max={100} />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control txt-right" value={this.state.n_premi_jumlah} onChange={e => this.onTextChange(e.target.value, 'n_premi_jumlah')} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bunga</td>
                                                            <td></td>
                                                            <td>
                                                                <input type="text" className="form-control txt-right" value={this.state.n_bunga} onChange={e => this.onTextChange(e.target.value, 'n_bunga')} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pajak</td>
                                                            <td></td>
                                                            <td>
                                                                <input type="text" className="form-control txt-right" value={this.state.n_pajak} onChange={e => this.onTextChange(e.target.value, 'n_pajak')} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}><div className="dropdown-divider"></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total</td>
                                                            <td></td>
                                                            <td>
                                                                <input type="text" readOnly={true} className="form-control txt-right" value={this.state.n_total} onChange={e => this.setState({n_total: e.target.value})} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}><div className="dropdown-divider"></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <a className="btn btn-info btn-sm waves-effect waves-light mr-5" onClick={() => {}}>
                                                                    <i className="fe-printer me-1"></i> Print Template
                                                                </a>
                                                            </td>
                                                            <td></td>
                                                            <td>
                                                                <Select
                                                                    options={Helpers.optionsCetak}
                                                                    value={Helpers.optionsCetak.filter((item) => item.value === this.state.id_cetak)}
                                                                    onChange={event => this.handleChange(event,'id_cetak')} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <a className="btn btn-info btn-sm waves-effect waves-light mr-5" onClick={() => {this.handleItemPrint()}}>
                                                                    <i className="fe-printer me-1"></i> Cetak Kuitansi
                                                                </a>
                                                            </td>
                                                            <td></td>
                                                            <td>
                                                                <Select
                                                                    options={Helpers.optionsCetak}
                                                                    value={Helpers.optionsCetak.filter((item) => item.value === this.state.id_cetak)}
                                                                    onChange={event => this.handleChange(event,'id_cetak')} />
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Perseorangan;