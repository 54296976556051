import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from "moment";

const styles = StyleSheet.create({
    body: {
        flexDirection: 'row'
    },
    column: {
        width: '40%'
    },
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 5,
        justifyContent: 'flex-start'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    divider: {
        width: 15
    },
    label: {
        width: 60
    },
    container: {
        flexDirection: 'row',
        borderTopColor: '#000',
        borderBottomColor: '#000',
        backgroundColor: '#FFF',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        marginTop: 10
    },
    td: {
        borderRightColor: '#000',
        borderRightWidth: 1,
        borderLeftWidth: 1,
        fontSize: 8
    }
});


const InvoiceThankYouMsg = () => (
    <Fragment>
        <View style={styles.body}>
            <View style={styles.column}>
                <View style={styles.invoiceNoContainer}>
                    <Text style={styles.label}>Dicetak</Text>
                    <Text style={styles.divider}>:</Text>
                    <Text style={styles.invoiceDate}>bpr</Text>
                </View>
                <View style={styles.invoiceDateContainer}>
                    <Text style={styles.label}>Tanggal</Text>
                    <Text style={styles.divider}>:</Text>
                    <Text>{moment().format('MMMM Do YYYY, h:mm:ss a')}</Text>
                </View>
                <View style={styles.invoiceDateContainer}>
                    <Text style={styles.label}>Indeks</Text>
                    <Text style={styles.divider}>:</Text>
                    <Text></Text>
                </View>
            </View>
            <View style={{width: '20%'}}></View>
            <View style={[styles.column]}>
                <View style={styles.container}>
                    <Text style={[styles.td, {width: '25%'}]}>Mengetahui</Text>
                    <Text style={[styles.td, {width: '25%'}]}>Int. Audit</Text>
                    <Text style={[styles.td, {width: '25%'}]}>Pemeriksa</Text>
                    <Text style={[styles.td, {width: '25%'}]}>Pembuat</Text>
                </View>
                <View style={[styles.container, {marginTop: 0, borderTopWidth: 0, borderBottomWidth: 0}]}>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                </View>
                <View style={[styles.container, {marginTop: 0, borderTopWidth: 0}]}>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                </View>
                <View style={[styles.container, {marginTop: 0, borderTopWidth: 0}]}>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                    <Text style={[styles.td, {width: '25%'}]}>&nbsp;</Text>
                </View>
            </View>
        </View>
    </Fragment>
);

export default InvoiceThankYouMsg