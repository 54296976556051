import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {ReactSession} from "react-client-session";
import {MDBDataTableV5} from "mdbreact";

class ModalTracking extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            isModalOpen: this.props.modalRef,
            dataSource: JSON.parse(ReactSession.get("bprsess_dataKodepos"))
        }
    }

    selectData = (row) => {
        this.props.onSelectData({
            value: row.label[0].substring(0,5)
        });
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    render() {
        return (
            <Modal
                size={'lg'}
                isOpen={this.state.isModalOpen}
                toggle={() => {this.handleModal()}}>
                <ModalBody>
                    <MDBDataTableV5
                        hover
                        striped
                        fullPagination
                        entriesOptions={[5, 25, 50, 100]}
                        entries={5}
                        data={
                            {
                                columns: [
                                    {
                                        label: 'ID',
                                        field: 'value',
                                    },
                                    {
                                        label: 'Kode POS',
                                        field: 'label',
                                    },
                                    {
                                        label: 'Action',
                                        field: 'action',
                                    }
                                ],
                                rows: this.state.dataSource.map((row) => {
                                    return {
                                        ...row,
                                        action: (
                                            <button type="button" className="btn btn-success btn-rounded btn-sm" onClick={() => {this.selectData(row)}}>
                                                Pilih
                                            </button>
                                        ),
                                    };
                                }),
                            }
                        } />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {this.handleModal()}}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ModalTracking;