import React from "react";

class Footer extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            2024 &copy; <a href="#">BPR Prabu Mitra</a>
                        </div>
                        <div className="col-md-6">
                            <div className="text-md-end footer-links d-none d-sm-block">
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;