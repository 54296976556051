import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";
import ModalReport from "../../../components/pdf/laporan/tabungan/ModalReport";
import ModalKodepos from "../../../components/ModalKodepos";

class RekeningKoranDeposito extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],
            setModal: '',
            startDate: '',
            endDate: '',
            no_rekening: '',
            no_rekening_label: '',
            saldo_awal: '0',
            saldo_titipan: '0',
            dataRekening: [],
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getMasterData('rekening-deposito?limit=0','dataRekening','id',['no_rekening','nasabah|nama']);
        }

        $('.mdb-dataTable-head').prepend('<tr><th colspan="5"></th><th colspan="3" style="text-align: center;">Pokok</th><th colspan="3" style="text-align: center;">Titipan</th></tr>');
    }

    getMasterData = (endpoint,state,value,label) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                });
            }
        });
    };

    getData = () => {
        if(this.state.startDate && this.state.endDate && this.state.no_rekening){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'deposito-transaction/report/rekening-koran?no_rekening='+this.state.no_rekening_label+'&tgl_trans_start='+this.state.startDate+'&tgl_trans_end='+this.state.endDate+'&limit=0').then(res => {
                if(res.data){
                    this.setState({
                        dataSource: res.data,
                        saldo_awal: res.meta.custom.saldo_awal_formatted
                    });

                    ReactSession.set("bprsess_report", JSON.stringify(
                        {
                            "report_type": "rekening_koran_deposito",
                            "invoice_no": "001-Kantor Pusat",
                            "start_date": this.state.startDate,
                            "end_date": this.state.endDate,
                            "items": res.data,
                            "meta": res.meta
                        }
                    ));
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }else{
            toast.error('Anda belum memilih periode');
        }
    };

    handleChange = (event,state) => {
        this.setState({
            [state]: event.value,
            no_rekening_label: event.label.toString().split(" - ")[0]
        }, () => {
            console.log(this.state.no_rekening);
            console.log(this.state.no_rekening_label);
        });
    };

    handleModalClick = () => {
        if(this.state.dataSource.length > 0){
            this.setState({
                setModal: ''
            }, () => {
                this.setState({
                    setModal: <ModalReport modalRef={true} />
                })
            })
        }else{
            toast.error('Mohon untuk proses data terlebih dahulu');
        }
    };

    render() {
        return (
            <div className="content">
                { this.state.setModal }

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Laporan</li>
                                        <li className="breadcrumb-item">Deposito</li>
                                        <li className="breadcrumb-item active">Rekening Koran Deposito</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Rekening Koran Deposito</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label">No. Rekening</label>
                                            <Select
                                                options={this.state.dataRekening}
                                                value={this.state.dataRekening.filter((item) => item.value === this.state.no_rekening)}
                                                onChange={event => this.handleChange(event,'no_rekening')} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Tanggal</label>
                                            <input type="date" className="form-control" value={this.state.startDate} onChange={e => this.setState({startDate: e.target.value})} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Ke</label>
                                            <input type="date" className="form-control" value={this.state.endDate} onChange={e => this.setState({endDate: e.target.value})} />
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.getData()}}>
                                                Proses
                                            </a>
                                        </div>
                                        <div className="col-1">
                                            {/*<a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.handleModalClick()}}>*/}
                                            {/*Cetak*/}
                                            {/*</a>*/}
                                        </div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                    <div className="row" style={{'margin-bottom': '15px'}}>
                                        <div className="col-3">
                                            <label className="form-label">Saldo Awal Pokok</label>
                                            <input type="text" className="form-control" value={this.state.saldo_awal} readOnly={true} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Titipan</label>
                                            <input type="text" className="form-control" value={this.state.saldo_titipan} readOnly={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'Tgl Transaksi',
                                                        field: 'tgl_trans',
                                                    },
                                                    {
                                                        label: 'Kode Transaksi',
                                                        field: 'kode_trans',
                                                    },
                                                    {
                                                        label: 'Deskripsi',
                                                        field: 'kode_trans_desc',
                                                    },
                                                    {
                                                        label: 'Bunga',
                                                        field: 'nominal_bunga_formatted',
                                                    },
                                                    {
                                                        label: 'Pajak',
                                                        field: 'nominal_pajak_formatted',
                                                    },
                                                    {
                                                        label: 'Setoran',
                                                        field: 'pokok_setoran_formatted',
                                                    },
                                                    {
                                                        label: 'Penarikan',
                                                        field: 'pokok_penarikan_formatted',
                                                    },
                                                    {
                                                        label: 'Saldo Akhir',
                                                        field: 'pokok_saldoakhir_formatted',
                                                    },
                                                    {
                                                        label: 'Setoran',
                                                        field: 'titipan_setoran_formatted',
                                                    },
                                                    {
                                                        label: 'Penarikan',
                                                        field: 'titipan_penarikan_formatted',
                                                    },
                                                    {
                                                        label: 'Saldo Akhir',
                                                        field: 'titipan_saldoakhir_formatted',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    /*let objSetoran = (row.my_kode_trans === '100' && (row.kode_trans === '01' || row.kode_trans === '06' || row.kode_trans === '09' || row.kode_trans === '10')) ? row.saldo_trans_formatted : '0';
                                                    let objBunga = (row.kode_trans === '03') ? row.saldo_trans_formatted : '0';
                                                    let objPajak = (row.kode_trans === '04') ? row.saldo_trans_formatted : '0';
                                                    let objPenarikan = (row.my_kode_trans === '300' && (row.kode_trans === '02' || row.kode_trans === '10' || row.kode_trans === '12' || row.kode_trans === '06')) ? row.saldo_trans_formatted : '0';

                                                    let objSetoranTitipan = ((row.my_kode_trans === '100' || row.my_kode_trans === '301' || row.my_kode_trans === '200' || row.my_kode_trans === '110') || row.kode_trans === '03') ? row.saldo_trans_formatted : '0';
                                                    let objPenarikanTitipan = ((row.kode_trans === '04' || row.kode_trans === '05' || row.kode_trans === '07' || row.kode_trans === '08')) ? row.saldo_trans_formatted : '0';*/

                                                    return {
                                                        ...row,
                                                        /*Setoran: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{objSetoran}</label>
                                                        ),
                                                        Bunga: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{objBunga}</label>
                                                        ),
                                                        Pajak: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{objPajak}</label>
                                                        ),
                                                        Penarikan: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{objPenarikan}</label>
                                                        ),
                                                        saldo: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.balance_formatted}</label>
                                                        ),
                                                        TitipSetoran: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{objSetoranTitipan}</label>
                                                        ),
                                                        TitipPenarikan: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{objPenarikanTitipan}</label>
                                                        ),
                                                        TitipSaldo: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>0</label>
                                                        ),*/
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RekeningKoranDeposito;