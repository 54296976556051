import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#000',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    td: {
        textAlign: 'left',
        paddingLeft: 5,
        paddingRight: 5,
        fontSize: 8
    },
});


const InvoiceTableFooter = ({items}) => {
    return(
        <View style={styles.row}>
            <Text style={[styles.td, {width: '3%'}]}></Text>
            <Text style={[styles.td, {width: '7%'}]}></Text>
            <Text style={[styles.td, {width: '7%'}]}></Text>
            <Text style={[styles.td, {width: '21%'}]}></Text>
            <Text style={[styles.td, {width: '5%'}]}></Text>
            <Text style={[styles.td, {width: '16%'}]}></Text>
            <Text style={[styles.td, {width: '7%'}]}></Text>
            <Text style={[styles.td, {width: '7%'}]}></Text>
            <Text style={[styles.td, {width: '7%'}]}>Jumlah</Text>
            <Text style={[styles.td, {width: '10%', textAlign: 'right'}]}>{items.meta.custom.total_setoran_formatted}</Text>
            <Text style={[styles.td, {width: '10%', textAlign: 'right'}]}>{items.meta.custom.total_penarikan_formatted}</Text>
        </View>
    )
};

export default InvoiceTableFooter