import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";

class DataRekeningForm extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: '',
            urlParam: queryString.parse(window.location.search),
            jn_kantor_kode: Helpers.DEF_COMPANY_CODE,
            jn_kantor_nama: Helpers.DEF_COMPANY,

            getIdRekening: '',
            black_list: 0,
            flag_amortisasi_hadiah: 0,
            hitung_zakat: 0,
            suku_bunga: '0',
            total_suku_bunga_estimasi: 0,
            status_rekening: '10',
            flag_restricted: 'UnRestricted',
            setoran_per_bln: '0',
            kolektibilitas_overide: '0',
            tgl_kolektibilitas_overide: moment(new Date()).format("YYYY-MM-DD"),
            sumber_dana_rekening: 'GAJI',
            tujuan_pembukaan_rekening: '0001',

            dataJenisTabungan: JSON.parse(ReactSession.get("bprsess_dataJenisTabungan")),
            dataNasabah: [],
            dataRekening: [],
            dataUsers: [],
            dataKelompok: JSON.parse(ReactSession.get("bprsess_dataKelompok")),
            dataStatusRekening: JSON.parse(ReactSession.get("bprsess_dataStatusRekening")),
            dataSumberDana: JSON.parse(ReactSession.get("bprsess_dataSumberDanaRekening")),
            dataTujuanPembukaan: JSON.parse(ReactSession.get("bprsess_dataTujuanPembukaan")),
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.setState({
                getIdRekening: (this.state.urlParam.ref) ? this.state.urlParam.ref : '',
            }, () => {
                this.getMasterData('nasabah?limit=0','dataNasabah','real_id',['id_nasabah','nama_nasabah']);
                this.getMasterData('rekening-tabungan?limit=0','dataRekening','real_id',['no_rekening','nasabah|nama']);
                this.getMasterData('users?search=is_backoffice:1&limit=0','dataUsers','userid',['userid','name']);

                if(this.state.getIdRekening){
                    this.getData();
                }
            });
        }
    }

    getMasterData = (endpoint,state,value,label) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                });
            }
        })
    };

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'rekening-tabungan/'+this.state.getIdRekening).then(res => {
            if(res.data){
                const data = res.data;
                this.setState({
                    dataSource: data,
                    tgl_registrasi: data.tgl_registrasi,
                    jenis_tabungan: data.jenis_tabungan.kode_jenis_tabungan,
                    nasabah_id: data.nasabah.id,
                    qq_name: data.qq_name,
                    ahli_waris: data.ahli_waris,
                    no_rekening: data.no_rekening,
                    id_ao: data.no_rekening,
                    id_kelompok: data.id_kelompok,
                    flag_restricted: data.flag_restricted,
                    kolektibilitas_overide: data.kolektibilitas_overide,
                    tgl_kolektibilitas_overide: data.tgl_kolektibilitas_overide,
                    status_rekening: data.status_rekening,
                    sumber_dana_rekening: data.sumber_dana_rekening,
                    tujuan_pembukaan_rekening: data.tujuan_pembukaan_rekening,
                    //black_list: data.black_list,
                    setoran_per_bln: data.setoran_per_bln,
                    tgl_setor_simpanan_wajib: data.tgl_setor_simpanan_wajib,
                    suku_bunga_estimasi: data.suku_bunga_estimasi,
                    total_suku_bunga_estimasi: 0,

                    hitung_zakat: (data.hitung_zakat === 'null') ? 0 : data.hitung_zakat,

                    persen_hadiah: data.persen_hadiah,
                    nominal_hadiah: data.nominal_hadiah,
                    flag_amortisasi_hadiah: (data.flag_amortisasi_hadiah === 'null') ? 0 : data.flag_amortisasi_hadiah,
                    keterangan_hadiah: data.keterangan_hadiah,

                    va_no: data.va_no,
                    va_topup_periode: data.va_topup_periode,
                    va_quota: data.va_quota,
                    va_topup: data.va_topup,
                    va_topup_mulai: data.va_topup_mulai,
                    va_topup_tgl: data.va_topup_tgl,

                    saldo_akan_datang: data.saldo_akan_datang,
                    tgl_angsuran: data.tgl_angsuran,
                    termin_pokok: data.termin_pokok,
                    termin_premi: data.termin_premi,
                    satuan_waktu_angsuran: data.satuan_waktu_angsuran,
                    setoran_per_angsuran: data.setoran_per_angsuran,
                    premi_per_bln: data.premi_per_bln,
                    persen_premi_adm: data.persen_premi_adm,

                    saldo_akhir: data.saldo_akhir,
                    saldo_blokir: data.saldo_blokir,
                    va_saldo: data.va_saldo,

                    rekening_si_otodebet: ''
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value });

        if(state === 'jenis_tabungan'){
            Helpers.getMasterData(this.state.sessToken,'kode-jenis-tabungan/'+event.value).then(res => {
                this.setState({
                    suku_bunga: res.data.suku_bunga,
                });
            })
        }
    };

    toggleCheckbox = (state) => {
        this.setState({ [state]: (this.state[state] === 0) ? 1 : 0 });
    };

    onTextChange = (event,state) => {
        this.setState({ [state]: event }, () => {
            if(state === 'suku_bunga' || state === 'suku_bunga_estimasi'){
                const suku_bunga = parseFloat((this.state.suku_bunga) ? this.state.suku_bunga : 0);
                const suku_bunga_estimasi = parseFloat((this.state.suku_bunga_estimasi) ? this.state.suku_bunga_estimasi : 0);

                this.setState({ total_suku_bunga_estimasi: suku_bunga+suku_bunga_estimasi });
            }
        });
    };

    submitData = () => {
        if(this.state.getIdRekening){
            this.saveData('PATCH','rekening-tabungan/'+this.state.getIdRekening);
        }else{
            this.saveData('POST','rekening-tabungan');
        }
    };

    saveData = (method,endpoint) => {
        let urlencoded = new URLSearchParams();
        //urlencoded.append("no_rekening", "");
        //urlencoded.append("no_alternatif", "");
        urlencoded.append("nasabah_id", Helpers.castEmpty(this.state.nasabah_id));
        urlencoded.append("qq_name", Helpers.castEmpty(this.state.qq_name));
        urlencoded.append("jenis_tabungan", this.state.jenis_tabungan);
        urlencoded.append("tgl_registrasi", (this.state.tgl_registrasi) ? this.state.tgl_registrasi : '1990-01-01');
        urlencoded.append("status_rekening", this.state.status_rekening);
        urlencoded.append("id_ao", this.state.id_ao);
        urlencoded.append("id_kelompok", this.state.id_kelompok);
        urlencoded.append("tgl_jatuh_tempo", Helpers.castEmpty((this.state.tgl_angsuran) ? this.state.tgl_angsuran : '1990-01-01'));
        urlencoded.append("setoran_per_bln", Helpers.castEmpty(this.state.setoran_per_bln));
        urlencoded.append("jkw", "");
        urlencoded.append("keterangan", "");
        urlencoded.append("ahli_waris", Helpers.castEmpty(this.state.ahli_waris));
        urlencoded.append("penanggung_premi", "");
        urlencoded.append("sumber_dana_rekening", this.state.sumber_dana_rekening);
        urlencoded.append("tujuan_pembukaan_rekening", this.state.tujuan_pembukaan_rekening);
        urlencoded.append("flag_restricted", Helpers.castEmpty(this.state.flag_restricted));
        urlencoded.append("kolektibilitas_overide", this.state.kolektibilitas_overide);
        urlencoded.append("tgl_kolektibilitas_overide", Helpers.castEmpty((this.state.tgl_kolektibilitas_overide) ? this.state.tgl_kolektibilitas_overide : '1990-01-01'));
        urlencoded.append("tgl_setor_simpanan_wajib", Helpers.castEmpty((this.state.tgl_setor_simpanan_wajib) ? this.state.tgl_setor_simpanan_wajib : '1990-01-01'));
        urlencoded.append("suku_bunga_estimasi", "");
        urlencoded.append("hitung_zakat", Helpers.castEmpty(this.state.hitung_zakat));
        urlencoded.append("keterangan_hadiah", Helpers.castEmpty(this.state.keterangan_hadiah));
        urlencoded.append("nominal_hadiah", Helpers.castEmpty(this.state.nominal_hadiah));
        urlencoded.append("persen_hadiah", Helpers.castEmpty(this.state.persen_hadiah));
        urlencoded.append("flag_amortisasi_hadiah", Helpers.castEmpty(this.state.flag_amortisasi_hadiah));
        urlencoded.append("va_no", Helpers.castEmpty(this.state.va_no));
        urlencoded.append("va_topup_periode", Helpers.castEmpty(this.state.va_topup_periode));
        urlencoded.append("va_quota", Helpers.castEmpty(this.state.va_quota));
        urlencoded.append("va_topup", Helpers.castEmpty(this.state.va_topup));
        urlencoded.append("va_topup_mulai", Helpers.castEmpty(this.state.va_topup_mulai));
        urlencoded.append("va_topup_tgl", Helpers.castEmpty(this.state.va_topup_tgl));
        urlencoded.append("setoran_per_angsuran", Helpers.castEmpty(this.state.setoran_per_angsuran));
        urlencoded.append("premi_per_bln", Helpers.castEmpty(this.state.premi_per_bln));
        urlencoded.append("persen_premi_adm", Helpers.castEmpty(this.state.persen_premi_adm));
        urlencoded.append("tgl_angsuran", Helpers.castEmpty((this.state.tgl_angsuran) ? this.state.tgl_angsuran : '1990-01-01'));
        urlencoded.append("satuan_waktu_angsuran", this.state.satuan_waktu_angsuran);
        urlencoded.append("termin_pokok", Helpers.castEmpty(this.state.termin_pokok));
        urlencoded.append("termin_premi", Helpers.castEmpty(this.state.termin_premi));
        urlencoded.append("rekening_si_otodebet", Helpers.castEmpty(this.state.rekening_si_otodebet));

        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => res.json()).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        toast.success(Helpers.MSG_SUCCESS);

                        setTimeout(function() {
                            window.location.href = './data-rekening';
                        }, 1000);
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Back Office</li>
                                        <li className="breadcrumb-item">Tabungan</li>
                                        <li className="breadcrumb-item"><a href="./data-rekening">Data Rekening Tabungan</a></li>
                                        <li className="breadcrumb-item active">Form</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Data Rekening Tabungan Form</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary waves-effect waves-light" onClick={() => {this.submitData()}}>
                                        <i className="fe-save me-1"></i> Simpan
                                    </a>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-bordered">
                                        <li className="nav-item">
                                            <a href="#form-1" data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                                Rekening Simpanan
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-2" data-bs-toggle="tab" aria-expanded="true" className="nav-link">
                                                Tabungan Wajib
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-3" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                Bunga,Adm, & Minimum
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-4" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                Co Branding
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-5" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                Jadwal Rencana
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-6" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                Virtual Account
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#form-7" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                Informasi Saldo
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="form-1">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Informasi Produk</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-4">
                                                            <label className="form-label">Cabang</label>
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.jn_kantor_kode} onChange={e => this.setState({jn_kantor_kode: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.jn_kantor_nama} onChange={e => this.setState({jn_kantor_nama: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tanggal Registrasi <code>(required)</code></label>
                                                        <input type="date" className="form-control" value={this.state.tgl_registrasi} onChange={e => this.setState({tgl_registrasi: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Tabungan <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataJenisTabungan}
                                                            value={this.state.dataJenisTabungan.filter((item) => item.value === this.state.jenis_tabungan)}
                                                            onChange={event => this.handleChange(event,'jenis_tabungan')} />
                                                    </div>

                                                    <h4 className="header-title mt-3">Informasi Nasabah</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nama Nasabah</label>
                                                        <Select
                                                            options={this.state.dataNasabah}
                                                            value={this.state.dataNasabah.filter((item) => item.value === this.state.nasabah_id)}
                                                            onChange={event => this.handleChange(event,'nasabah_id')} />
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input"
                                                                   checked={(this.state.black_list === 1) ? true : false}
                                                                   onClick={() => {this.toggleCheckbox('black_list')}}/>
                                                            <label className="form-check-label">Black List</label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">QQ</label>
                                                        <input type="text" className="form-control" value={this.state.qq_name} onChange={e => this.setState({qq_name: e.target.value})} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Ahli Waris</label>
                                                        <textarea className="form-control" rows="3" value={this.state.ahli_waris} onChange={e => this.setState({ahli_waris: e.target.value})}></textarea>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">No. Rekening SI / Otodebt</label>
                                                        <Select
                                                            options={this.state.dataRekening}
                                                            value={this.state.dataRekening.filter((item) => item.value === this.state.rekening_si_otodebet)}
                                                            onChange={event => this.handleChange(event,'rekening_si_otodebet')} />
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Informasi Kelompok</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Petugas AO <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataUsers}
                                                            value={this.state.dataUsers.filter((item) => item.value === this.state.id_ao)}
                                                            onChange={event => this.handleChange(event,'id_ao')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kelompok <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataKelompok}
                                                            value={this.state.dataKelompok.filter((item) => item.value === this.state.id_kelompok)}
                                                            onChange={event => this.handleChange(event,'id_kelompok')} />
                                                    </div>

                                                    <h4 className="header-title mt-3">Data Rekening</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Restricted</label>
                                                        <Select
                                                            options={Helpers.optionsRestricted}
                                                            value={Helpers.optionsRestricted.filter((item) => item.value === this.state.flag_restricted)}
                                                            onChange={event => this.handleChange(event,'flag_restricted')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Status Rekening <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataStatusRekening}
                                                            value={this.state.dataStatusRekening.filter((item) => item.value === this.state.status_rekening)}
                                                            onChange={event => this.handleChange(event,'status_rekening')}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-8">
                                                            <label className="form-label">Overide Kolektibilitas <code>(required)</code></label>
                                                            <Select
                                                                options={Helpers.optionsKolektibilitas}
                                                                value={Helpers.optionsKolektibilitas.filter((item) => item.value === this.state.kolektibilitas_overide)}
                                                                onChange={event => this.handleChange(event,'kolektibilitas_overide')} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="date" className="form-control" value={this.state.tgl_kolektibilitas_overide} onChange={e => this.setState({tgl_kolektibilitas_overide: e.target.value})} />
                                                        </div>
                                                    </div>

                                                    <h4 className="header-title mt-3">Sumber Dana dan Tujuan Pembukaan</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kode Sumber Dana <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataSumberDana}
                                                            value={this.state.dataSumberDana.filter((item) => item.value === this.state.sumber_dana_rekening)}
                                                            onChange={event => this.handleChange(event,'sumber_dana_rekening')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tujuan Pembukaan <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataTujuanPembukaan}
                                                            value={this.state.dataTujuanPembukaan.filter((item) => item.value === this.state.tujuan_pembukaan_rekening)}
                                                            onChange={event => this.handleChange(event,'tujuan_pembukaan_rekening')} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-2">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Tabungan Wajib</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tabungan Wajib (Bulan)</label>
                                                        <input type="number" className="form-control" value={this.state.setoran_per_bln} onChange={e => this.setState({setoran_per_bln: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tanggal Mulai Setoran</label>
                                                        <input type="date" className="form-control" value={this.state.tgl_setor_simpanan_wajib} onChange={e => this.setState({tgl_setor_simpanan_wajib: e.target.value})} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-3">
                                            <div className={'row'}>
                                                <div className={'col-12'}>
                                                    <h4 className="header-title">Konfigurasi Bunga dan Biaya Administrasi</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-3">
                                                            <label className="form-label">Bunga</label>
                                                            <input type="number" className="form-control" readOnly={true} value={this.state.suku_bunga} onChange={e => this.setState({suku_bunga: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">+</label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.suku_bunga_estimasi} onChange={e => this.onTextChange(e.target.value, 'suku_bunga_estimasi')} />
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">=</label>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" readOnly={true} value={this.state.total_suku_bunga_estimasi} onChange={e => this.setState({total_suku_bunga_estimasi: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">%/ Tahun</label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Zakat</label>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input"
                                                                   checked={(this.state.hitung_zakat === 1) ? true : false}
                                                                   onClick={() => {this.toggleCheckbox('hitung_zakat')}}/>
                                                            <label className="form-check-label">Zakat</label>
                                                        </div>
                                                    </div>

                                                    <h4 className="header-title mt-3">Fee/Hadiah/Bonus</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-5">
                                                            <label className="form-label">Hadiah</label>
                                                            <input type="number" className="form-control" value={this.state.persen_hadiah} onChange={e => this.setState({persen_hadiah: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label className="form-label">&nbsp;</label>
                                                            <label className="form-label row-middle">% Rp. </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.nominal_hadiah} onChange={e => this.setState({nominal_hadiah: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input"
                                                                   checked={(this.state.flag_amortisasi_hadiah === 1) ? true : false}
                                                                   onClick={() => {this.toggleCheckbox('flag_amortisasi_hadiah')}}/>
                                                            <label className="form-check-label">Amortisasi</label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <textarea className="form-control" rows="3" value={this.state.keterangan_hadiah} onChange={e => this.setState({keterangan_hadiah: e.target.value})}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-4">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">CoBranding/Virtual Account</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Co Branding</label>
                                                        <Select
                                                            options={Helpers.optionsEmpty}
                                                            value={Helpers.optionsEmpty.filter((item) => item.value === this.state.co_branding)}
                                                            onChange={event => this.handleChange(event,'co_branding')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Rekening Virtual</label>
                                                        <input type="text" className="form-control" value={this.state.va_no} onChange={e => this.setState({va_no: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Priode Top Up</label>
                                                        <Select
                                                            options={Helpers.optionsPeriodeTopup}
                                                            value={Helpers.optionsPeriodeTopup.filter((item) => item.value === this.state.va_topup_periode)}
                                                            onChange={event => this.handleChange(event,'va_topup_periode')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Max Saldo Rekening Virtual</label>
                                                        <input type="number" className="form-control" value={this.state.va_quota} onChange={e => this.setState({va_quota: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jml Top Up Rekening Virtual</label>
                                                        <input type="number" className="form-control" value={this.state.va_topup} onChange={e => this.setState({va_topup: e.target.value})} />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <label className="form-label">Tgl Mulai Top Up</label>
                                                            <input type="date" className="form-control" value={this.state.va_topup_mulai} onChange={e => this.setState({va_topup_mulai: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="number" className="form-control" value={this.state.va_topup_tgl} onChange={e => this.setState({va_topup_tgl: e.target.value})} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-5">
                                            <div className={'row'}>
                                                <div className={'col-5'}>
                                                    <h4 className="header-title">Sumber Data</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Setoran Per Angs</label>
                                                        <input type="number" className="form-control" value={this.state.setoran_per_angsuran} onChange={e => this.setState({setoran_per_angsuran: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Premi (%)</label>
                                                        <input type="number" className="form-control" value={this.state.premi_per_bln} onChange={e => this.setState({premi_per_bln: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Admin (%)</label>
                                                        <input type="number" className="form-control" value={this.state.persen_premi_adm} onChange={e => this.setState({persen_premi_adm: e.target.value})} />
                                                    </div>

                                                    <div className="dropdown-divider mt-3 mb-2"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tanggal Angsuran</label>
                                                        <input type="date" className="form-control" value={this.state.tgl_angsuran} onChange={e => this.setState({tgl_angsuran: e.target.value})} />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <label className="form-label">Jangka Waktu <code>(required)</code></label>
                                                            <input type="number" className="form-control" value={this.state.jangka_waktu} onChange={e => this.setState({jangka_waktu: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">&nbsp;</label>
                                                            <Select
                                                                options={Helpers.optionsJangkaWaktu}
                                                                value={Helpers.optionsJangkaWaktu.filter((item) => item.value === this.state.satuan_waktu_angsuran)}
                                                                onChange={event => this.handleChange(event, 'satuan_waktu_angsuran')}/>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <label className="form-label">Termin Pokok</label>
                                                            <input type="number" className="form-control" value={this.state.termin_pokok} onChange={e => this.setState({termin_pokok: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">Termin Premi</label>
                                                            <input type="number" className="form-control" value={this.state.termin_premi} onChange={e => this.setState({termin_premi: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tgl Jatuh tempo</label>
                                                        <input type="date" readOnly={true} className="form-control" value={this.state.tgl_angsuran} onChange={e => this.setState({tgl_angsuran: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Saldo Bunga</label>
                                                        <input type="number" readOnly={true} className="form-control" value={this.state.saldo_bunga} onChange={e => this.setState({saldo_bunga: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Saldo Akan Datang</label>
                                                        <input type="number" readOnly={true} className="form-control" value={this.state.saldo_akan_datang} onChange={e => this.setState({saldo_akan_datang: e.target.value})} />
                                                    </div>
                                                </div>
                                                <div className={'col-7'}>
                                                    <table className="table" width="100%">
                                                        <thead className="table-light">
                                                        <tr>
                                                            <th>
                                                                <a className="btn btn-info btn-xs waves-effect waves-light mr-5"
                                                                   onClick={() => {
                                                                       //this.submitData()
                                                                   }}>
                                                                    <i className="fe-plus"></i>
                                                                </a>
                                                            </th>
                                                            <th>Tgl Transaksi</th>
                                                            <th>Angsuran</th>
                                                            <th>Setoran</th>
                                                            <th>Premi</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-6">
                                            <div className={'row'}>
                                                <div className={'col-12'}>
                                                    <table className="table" width="100%">
                                                        <thead className="table-light">
                                                        <tr>
                                                            <th>
                                                                <a className="btn btn-info btn-xs waves-effect waves-light mr-5"
                                                                   onClick={() => {
                                                                       //this.submitData()
                                                                   }}>
                                                                    <i className="fe-plus"></i>
                                                                </a>
                                                            </th>
                                                            <th>Tgl Transaksi</th>
                                                            <th>Va No</th>
                                                            <th>Nama</th>
                                                            <th>Nama</th>
                                                            <th>Nama Nasabah</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="form-7">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <h4 className="header-title">Informasi Saldo</h4>
                                                    <div className="dropdown-divider"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Saldo Akhir</label>
                                                        <input type="number" readOnly={true} className="form-control" value={this.state.saldo_akhir} onChange={e => this.setState({saldo_akhir: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Saldo Blokir</label>
                                                        <input type="number" readOnly={true} className="form-control" value={this.state.saldo_blokir} onChange={e => this.setState({saldo_blokir: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Saldo Virtual</label>
                                                        <input type="number" readOnly={true} className="form-control" value={this.state.va_saldo} onChange={e => this.setState({va_saldo: e.target.value})} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DataRekeningForm;