import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";
import ModalReport from "../../../components/pdf/laporan/tabungan/ModalReport";
import ModalKodepos from "../../../components/ModalKodepos";
import RekeningKoranInventaris from "./RekeningKoranInventaris";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {Document, Page} from "react-pdf";

class TransaksiInventaris extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],
            setModal: '',
            startDate: '',
            endDate: '',

            isModalOpen: false,
            reportStream: null,
            pdfWidth: 0
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }
    }

    getData = () => {
        if(this.state.startDate && this.state.endDate){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'inventaris-transaction/report/transaction?tgl_trans_start='+this.state.startDate+'&tgl_trans_end='+this.state.endDate+'&limit=0').then(res => {
                if(res.data){
                    this.setState({
                        dataSource: res.data
                    });

                    ReactSession.set("bprsess_report", JSON.stringify(
                        {
                            "report_type": "transaksi_deposito",
                            "invoice_no": "001-Kantor Pusat",
                            "start_date": this.state.startDate,
                            "end_date": this.state.endDate,
                            "items": res.data,
                            "meta": res.meta
                        }
                    ));
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }else{
            toast.error('Anda belum memilih periode');
        }
    };

    handleModalClick = () => {
        if(this.state.dataSource.length > 0){
            this.setState({
                setModal: ''
            }, () => {
                this.setState({
                    setModal: <ModalReport modalRef={true} />
                })
            })
        }else{
            toast.error('Mohon untuk proses data terlebih dahulu');
        }
    };

    handleItemPrint = (value) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getReportPDF(this.state.sessToken,'inventaris-transaction/report/transaction/print/'+value+'?format=pdf').then(res => {
            this.setState({
                reportStream: res
            }, () => {
                this.handleModal();
            });

            $(".loader-wrapper").css("display", "none");
        })
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleDownload = () => {
        const uid = (new Date().getTime());
        Helpers.download(this.state.reportStream, 'kuitansi_penerimaan_bunga_deposito_'+uid);
    };

    render() {
        return (
            <div className="content">
                { this.state.setModal }

                <Modal
                    size={'lg'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}>
                    <ModalBody>
                        <Document file={this.state.reportStream}>
                            <Page
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={this.state.pdfWidth}
                            />
                        </Document>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {this.handleDownload()}}>Download</Button>
                        <Button onClick={() => {this.handleModal()}}>Close</Button>
                    </ModalFooter>
                </Modal>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Laporan</li>
                                        <li className="breadcrumb-item">Inventaris</li>
                                        <li className="breadcrumb-item active">Transaksi Inventaris</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Transaksi Inventaris</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label">Tanggal</label>
                                            <input type="date" className="form-control" value={this.state.startDate} onChange={e => this.setState({startDate: e.target.value})} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Ke</label>
                                            <input type="date" className="form-control" value={this.state.endDate} onChange={e => this.setState({endDate: e.target.value})} />
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.getData()}}>
                                                Proses
                                            </a>
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.handleModalClick()}}>
                                                Cetak
                                            </a>
                                        </div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'Tgl Transaksi',
                                                        field: 'tgl_trans_formatted',
                                                    },
                                                    {
                                                        label: 'No. Rekening',
                                                        field: 'no_rekening',
                                                    },
                                                    {
                                                        label: 'Deskripsi',
                                                        field: 'deskripsi_inventaris',
                                                    },
                                                    {
                                                        label: 'Kode',
                                                        field: 'kode_trans',
                                                    },
                                                    {
                                                        label: 'Jenis',
                                                        field: 'deskripsi_jenis_pembayaran',
                                                    },
                                                    {
                                                        label: 'Pembelian',
                                                        field: 'pembelian_formatted',
                                                    },
                                                    {
                                                        label: 'Penyusutan',
                                                        field: 'penyusutan_formatted',
                                                    },
                                                    {
                                                        label: 'Cetak',
                                                        field: 'cetak',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    let btnPrint = (row.trans_id) ? <a className="btn btn-primary waves-effect waves-light" onClick={() => {this.handleItemPrint(row.trans_id)}}>
                                                        Cetak
                                                    </a> : '';

                                                    return {
                                                        ...row,
                                                        pembelian_formatted: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.pembelian_formatted}</label>
                                                        ),
                                                        penyusutan_formatted: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.penyusutan_formatted}</label>
                                                        ),
                                                        cetak: btnPrint
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TransaksiInventaris;