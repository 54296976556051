import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 5,
        justifyContent: 'flex-start'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        width: 60
    }

});


const InvoiceNo = ({invoice}) => (
    <Fragment>
        <View style={styles.invoiceNoContainer}>
            <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
        </View >
        {/*<View style={styles.invoiceDateContainer}>*/}
            {/*<Text style={styles.label}>Date: </Text>*/}
            {/*<Text >{invoice.trans_date}</Text>*/}
        {/*</View >*/}
    </Fragment>
);

export default InvoiceNo