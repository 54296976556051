import React from "react";

import { Helpers } from "../config/Helpers";
import {ReactSession} from "react-client-session";
import {toast} from "react-toastify";
import queryString from "query-string";
import {decode as base64_decode} from "base-64";
import $ from 'jquery';

class Login extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            urlParam: queryString.parse(window.location.search),
            username: '',
            password: ''
        }
    }

    componentDidMount() {
        if(this.state.sessToken){
            window.location.href = './';
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        $(".loader-wrapper").css("display", "");
        Helpers.getLoginData(this.state.username, this.state.password).then(res => {
            if(res.error){
                toast.error(res.message);
                $(".loader-wrapper").css("display", "none");
            }else{
                this.setState({
                    username: '',
                    password: '',
                    sessToken: res.access_token
                }, () => {
                    ReactSession.set("bprsess_id", res.id);
                    ReactSession.set("bprsess_real_id", res.id.toString());
                    ReactSession.set("bprsess_username", res.username);
                    ReactSession.set("bprsess_token_type", res.token_type);
                    ReactSession.set("bprsess_access_token", res.access_token);
                    ReactSession.set("bprsess_refresh_token", res.refresh_token);

                    this.loadMasterData().then((res) => {
                        const parUrl = (this.state.urlParam.redirectUrl) ? base64_decode(this.state.urlParam.redirectUrl) : '';
                        window.location.href = parUrl;

                        $(".loader-wrapper").css("display", "none");
                    }).catch((e) => {
                        console.log(e);
                        $(".loader-wrapper").css("display", "none");
                    })
                });
            }
        })
    };

    getMasterData = (endpoint,state,value,label) => {
        return Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]] : item[val];
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label]
                        };
                    })
                }, () => {
                    ReactSession.set("bprsess_" + state, JSON.stringify(this.state[state]));
                });
            }
        })
    };

    loadMasterData = async () => {
        for(let i=0; i< Helpers.arrMasterData.length; i++) {
            const checkMaster = ReactSession.get("bprsess_"+Helpers.arrMasterData[i].state);
            if(!checkMaster){
                await this.getMasterData(
                    Helpers.arrMasterData[i].endpoint,
                    Helpers.arrMasterData[i].state,
                    Helpers.arrMasterData[i].value,
                    Helpers.arrMasterData[i].label
                );
            }
        }
    };

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row justify-content-center mt-3">
                        <div className="col-md-8 col-lg-6 col-xl-4">
                            <div className="card bg-pattern">
                                <div className="card-body p-4">
                                    <div className="text-center w-75 m-auto">
                                        <div className="auth-logo">
                                            <a href="./" className="logo logo-dark text-center">
                                                <span className="logo-lg">
                                                    <img src="./assets/images/logo-light.png" alt="" height="22" />
                                                </span>
                                            </a>
                                            <a href="./" className="logo logo-light text-center">
                                                <span className="logo-lg">
                                                    <img src="./assets/images/logo-light.png" alt="" height="22" />
                                                </span>
                                            </a>
                                        </div>
                                        <p className="text-muted mb-4 mt-3">
                                            Enter your username and password to access admin panel.
                                        </p>
                                    </div>

                                    <form method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                        <div className="mb-3">
                                            <label className="form-label">Username</label>
                                            <input className="form-control"
                                                   required type="text"
                                                   value={this.state.username}
                                                   onChange={e => this.setState({username: e.target.value})}
                                                   placeholder="Enter your username" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Password</label>
                                            <input className="form-control"
                                                   required type="password"
                                                   value={this.state.password} onChange={e => this.setState({password: e.target.value})}
                                                   placeholder="Enter your password" />
                                        </div>
                                        <div className="text-center d-grid">
                                            <button className="btn btn-primary" type="submit"> Log In</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;