import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {ReactSession} from "react-client-session";
import {MDBDataTableV5} from "mdbreact";
//import {Quixote} from "./RenderPDF";
import {
    Document,
    Page,
    Text,
    Font,
    View,
    Image,
    StyleSheet,
    PDFViewer,
} from "@react-pdf/renderer";
import {encode as base64_encode} from "base-64";
import $ from "jquery";
import {Helpers} from "../config/Helpers";
import {toast} from "react-toastify";
import {Table, TableHeader, TableCell, TableBody, DataTableCell} from "@david.kucsai/react-pdf-table";

class ModalReport extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            isModalOpen: this.props.modalRef
        }
    }

    componentDidMount() {
        //this.getData();
    }

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'tabungan-transaction?tgl_trans_start=2022-08-01&tgl_trans_end=2022-09-05&limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataSource: res.data
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    render() {
        return (
            <Modal
                size={'xl'}
                isOpen={this.state.isModalOpen}
                toggle={() => {this.handleModal()}}>
                <ModalBody>
                    <PDFViewer>

                    </PDFViewer>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {this.handleModal()}}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ModalReport;