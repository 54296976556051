import React from "react";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import $ from "jquery";
import {Helpers} from "../../../../config/Helpers";
import {toast} from "react-toastify";
import Select from 'react-select';
import {Connection} from "../../../../config/Connection";

class KodeTransaksiInventaris extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],
            dataKodeJenisPembayaran: JSON.parse(ReactSession.get("bprsess_dataKodeJenisPembayaran")),
            dataKodePerkiraan: JSON.parse(ReactSession.get("bprsess_dataKodePerkiraan")),

            id: '',
            kode_transaksi_inventaris: '',
            deskripsi_transaksi_inventaris: '',
            jenis_pembayaran: '',
            kode_perk: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getData();
        }
    }

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'kode-transaksi-inventaris?limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataSource: res.data
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value });
    };

    selectData = (obj) => {
        this.setState({
            id: obj.id,
            kode_transaksi_inventaris: obj.kode_transaksi_inventaris,
            deskripsi_transaksi_inventaris: obj.deskripsi,
            jenis_pembayaran: obj.kode_jenis_pembayaran,
            kode_perk: obj.kode_perk
        });
    };

    deleteData = (obj) => {
        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + 'kode-transaksi-inventaris/'+obj.id, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            }
        }).then((res) => res.json()).then((json) => {
            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });

        this.getData();
    };

    submitData = () => {
        if(this.state.id){
            this.saveData('PATCH','kode-transaksi-inventaris/'+this.state.id);
        }else{
            this.saveData('POST','kode-transaksi-inventaris');
        }
    };

    clearData = () => {
        this.setState({
            id: '',
            kode_transaksi_inventaris: '',
            deskripsi_transaksi_inventaris: '',
            jenis_pembayaran: '',
            kode_perk: ''
        });
    };

    saveData = (method,endpoint) => {
        let urlencoded = new URLSearchParams();
        urlencoded.append("kode_transaksi_inventaris", this.state.kode_transaksi_inventaris);
        urlencoded.append("deskripsi_transaksi_inventaris", this.state.deskripsi_transaksi_inventaris);
        urlencoded.append("jenis_pembayaran", this.state.jenis_pembayaran);
        urlencoded.append("kode_perk", this.state.kode_perk);

        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => res.json()).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        toast.success(Helpers.MSG_SUCCESS);

                        this.getData();
                        this.clearData();
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Akuntansi</li>
                                        <li className="breadcrumb-item">Inventaris</li>
                                        <li className="breadcrumb-item">Setting</li>
                                        <li className="breadcrumb-item active">Kode Transaksi Inventaris</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Kode Transaksi Inventaris</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3">
                                    <div className="mb-1">
                                        <label className="form-label">Kode</label>
                                        <input type="text" className="form-control" value={this.state.kode_transaksi_inventaris} onChange={e => this.setState({kode_transaksi_inventaris: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="mb-1">
                                        <label className="form-label">Deskripsi</label>
                                        <input type="text" className="form-control" value={this.state.deskripsi_transaksi_inventaris} onChange={e => this.setState({deskripsi_transaksi_inventaris: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="mb-1">
                                        <label className="form-label">Jenis Pembayaran</label>
                                        <Select
                                            options={this.state.dataKodeJenisPembayaran}
                                            value={this.state.dataKodeJenisPembayaran.filter((item) => item.value === this.state.jenis_pembayaran)}
                                            onChange={event => this.handleChange(event,'jenis_pembayaran')} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="mb-1">
                                        <label className="form-label">Kode Perk</label>
                                        <Select
                                            options={this.state.dataKodePerkiraan}
                                            value={this.state.dataKodePerkiraan.filter((item) => item.value === this.state.kode_perk)}
                                            onChange={event => this.handleChange(event,'kode_perk')} />
                                    </div>
                                </div>
                                <div className="col-12"></div>
                                <div className="col-6">
                                    <a className="btn btn-primary waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                        <i className="fe-save me-1"></i> Simpan
                                    </a>
                                    <a className="btn btn-warning waves-effect waves-light mr-5" onClick={() => {this.clearData()}}>
                                        <i className="fe-trash me-1"></i> Bersihkan
                                    </a>
                                </div>
                                <div className="dropdown-divider"></div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'Kode Transaksi Inventaris',
                                                        field: 'kode_transaksi_inventaris',
                                                    },
                                                    {
                                                        label: 'Deskripsi',
                                                        field: 'deskripsi',
                                                    },
                                                    {
                                                        label: 'Jenis Pembayaran',
                                                        field: 'jenis_pembayaran',
                                                    },
                                                    {
                                                        label: 'Action',
                                                        field: 'action',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    return {
                                                        ...row,
                                                        action: (
                                                            <div>
                                                                <button type="button" className="btn btn-info btn-rounded btn-sm" onClick={() => {this.selectData(row)}}>
                                                                    Edit
                                                                </button>
                                                                <button type="button" className="btn btn-danger btn-rounded btn-sm" onClick={() => {this.deleteData(row)}}>
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default KodeTransaksiInventaris;