import React from "react";

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ReactSession} from "react-client-session";
import $ from "jquery";
import {Helpers} from "./config/Helpers";

class Header extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            sessID: (ReactSession.get("bprsess_id")) ? ReactSession.get("bprsess_id") : '',
            showMenu: ''
        }
    }

    componentDidMount() {
        if(this.state.sessToken){
            this.getData();
        }
    }

    getData = () => {
        Helpers.getDataUser(this.state.sessToken,this.state.sessID).then(res => {
            if(res.data){
                this.setState({
                    name: res.data.name
                }, () => {
                    this.setMenu();
                });
            }else{
                this.execLogout();
            }
        })
    };

    setMenu = () => {
        this.setState({
            showMenu: <ul className="navbar-nav">
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-dashboard" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe-headphones me-1"></i> Customer Service <div className="arrow-down"></div>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="topnav-dashboard">
                        <a href="./data-nasabah" className="dropdown-item"><i className="fe-users me-1"></i> Data Nasabah</a>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-layanannasabah" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-airplay me-1"></i>  Layanan Nasabah <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-layanannasabah">
                                <a href="ecommerce-dashboard.html" className="dropdown-item">Simulasi Pembiayaan</a>
                                <a href="ecommerce-dashboard.html" className="dropdown-item">Simulasi Tabungan Rencana</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-masterdata" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-database me-1"></i> Master Data <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-masterdata">
                                <a href="./master-negara" className="dropdown-item">Negara</a>
                                <a href="./master-kota" className="dropdown-item">Kota</a>
                                <a href="./master-pos" className="dropdown-item">Pos</a>
                                <a href="./master-gelar" className="dropdown-item">Gelar</a>
                                <a href="./master-bank" className="dropdown-item">Bank</a>
                                <a href="./master-lokasi" className="dropdown-item">Lokasi</a>
                                <a href="./master-pekerjaan" className="dropdown-item">Pekerjaan</a>
                                <a href="./master-pemilik" className="dropdown-item">Pemilik</a>
                                <a href="./master-valas" className="dropdown-item">Valas</a>
                                <a href="./master-sumberdana" className="dropdown-item">Sumber Dana</a>
                                <a href="./master-tujuanpembukaan" className="dropdown-item">Tujuan Pembukaan</a>
                                <a href="./master-pemasok" className="dropdown-item">Pemasok</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-setting" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-settings me-1"></i> Setting <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-setting">
                                <a href="ecommerce-dashboard.html"className="dropdown-item">Soal APUPPT</a>
                                <a href="./syncdata"className="dropdown-item">Sync Data</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-backoffice" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe-monitor me-1"></i> Back Office <div className="arrow-down"></div>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="topnav-backoffice">
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-tabungan" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Tabungan <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-tabungan">
                                <a href="./data-rekening" className="dropdown-item"><i className="fe-credit-card me-1"></i> Data Rekening</a>

                                <div className="dropdown">
                                    <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-tabunganadministrasi" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe-file me-1"></i> Administrasi <div className="arrow-down"></div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="topnav-tabunganadministrasi">
                                        <a href="./hapus-transaksi-tabungan" className="dropdown-item">Hapus Transaksi</a>
                                        <a href="#" className="dropdown-item">Koreksi Bunga dan Pajak Tabungan</a>
                                        <a href="#" className="dropdown-item">Blokir dan unblokir Tabungan</a>
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-tabungansetting" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe-settings me-1"></i> Setting <div className="arrow-down"></div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="topnav-tabungansetting">
                                        <a href="#" className="dropdown-item">Produk Tabungan</a>
                                        <a href="./kode-transaksi-tabungan" className="dropdown-item">Kode Transaksi Tabungan</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-tabungan" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Deposito <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-tabungan">
                                <a href="./data-rekening-deposito" className="dropdown-item"><i className="fe-credit-card me-1"></i> Data Rekening</a>
                                <a href="./aro" className="dropdown-item"><i className="fe-repeat me-1"></i> ARO</a>
                                <a href="./feemarketing" className="dropdown-item"><i className="fe-dollar-sign me-1"></i> Fee Marketing</a>

                                <div className="dropdown">
                                    <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-tabunganadministrasi" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe-file me-1"></i> Administrasi <div className="arrow-down"></div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="topnav-tabunganadministrasi">
                                        <a href="./hapus-transaksi-deposito" className="dropdown-item">Hapus Transaksi</a>
                                        <a href="#" className="dropdown-item">Koreksi Bunga dan Pajak Tabungan</a>
                                        <a href="#" className="dropdown-item">Blokir dan unblokir Tabungan</a>
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-tabungansetting" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe-settings me-1"></i> Setting <div className="arrow-down"></div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="topnav-tabungansetting">
                                        <a href="#" className="dropdown-item">Produk Tabungan</a>
                                        <a href="#" className="dropdown-item">Kode Transaksi Tabungan</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-teller" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe-headphones me-1"></i> Teller <div className="arrow-down"></div>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="topnav-teller">
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-teller" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Kas Umum <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-teller">
                                <a href="./teller-kas-transaksi" className="dropdown-item">Transaksi</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-teller" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Tabungan <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-teller">
                                <a href="./teller-tab-perseorangan" className="dropdown-item">Perseorangan</a>
                                <a href="./teller-tab-kolektif" className="dropdown-item">Kolektif</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-teller" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Deposito <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-teller">
                                <a href="./teller-dep-transaksi" className="dropdown-item">Transaksi</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-teller" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Inventaris <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-teller">
                                <a href="./teller-transaksi-inventaris" className="dropdown-item">Transaksi</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-teller" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> RRA <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-teller">
                                <a href="./teller-transaksi-rra" className="dropdown-item">Transaksi</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-akuntansi" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe-file-text me-1"></i> Akuntansi <div className="arrow-down"></div>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="topnav-akuntansi">
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-akuntansi" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Pembukuan <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-akuntansi">
                                <div className="dropdown">
                                    <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-akuntansi" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe-file me-1"></i> Administrasi <div className="arrow-down"></div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="topnav-akuntansi">
                                        <a href="./akuntansi-posting" className="dropdown-item">Posting</a>
                                    </div>
                                </div>
                                <a href="./akuntansi-jurnalumum" className="dropdown-item"><i className="fe-file me-1"></i> Jurnal Umum</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-inventaris" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Inventaris <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-inventaris">
                                <a href="./data-rekening-inventaris" className="dropdown-item"><i className="fe-file me-1"></i> Data Rekening</a>
                                <div className="dropdown">
                                    <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-inventaris" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe-file me-1"></i> Administrasi <div className="arrow-down"></div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="topnav-inventaris">
                                        <a href="./hapus-transaksi-inventaris" className="dropdown-item">Hapus Transaksi</a>
                                        <a href="./penyusutan-manual-inventaris" className="dropdown-item">Penyusutan Manual</a>
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-inventaris" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe-file me-1"></i> Setting <div className="arrow-down"></div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="topnav-inventaris">
                                        <a href="./jenis-inventaris" className="dropdown-item">Jenis Inventaris</a>
                                        <a href="./kode-transaksi-inventaris" className="dropdown-item">Kode Transaksi Inventaris</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-rra" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> RRA <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-rra">
                                <a href="./data-rekening-rra" className="dropdown-item"><i className="fe-file me-1"></i> Data Rekening</a>
                                <div className="dropdown">
                                    <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-rra" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe-file me-1"></i> Administrasi <div className="arrow-down"></div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="topnav-rra">
                                        <a href="./hapus-transaksi-rra" className="dropdown-item">Hapus Transaksi</a>
                                        <a href="./penyusutan-manual-rra" className="dropdown-item">Penyusutan Manual</a>
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-rra" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe-file me-1"></i> Setting <div className="arrow-down"></div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="topnav-rra">
                                        <a href="./jenis-rra" className="dropdown-item">Jenis RRA</a>
                                        <a href="./kode-transaksi-rra" className="dropdown-item">Kode Transaksi RRA</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-laporan" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe-archive me-1"></i> Laporan <div className="arrow-down"></div>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="topnav-laporan">
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-laporan" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Tabungan <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-laporan">
                                <a href="./report-nominatiftabungan" className="dropdown-item">Nominatif Tabungan</a>
                                <a href="./report-transaksitabungan" className="dropdown-item">Transaksi Tabungan</a>
                                <a href="./report-rekeningkorantabungan" className="dropdown-item">Rekening Koran Tabungan</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-laporan" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Deposito <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-laporan">
                                <a href="./report-nominatifdeposito" className="dropdown-item">Nominatif Deposito</a>
                                <a href="./report-transaksideposito" className="dropdown-item">Transaksi Deposito</a>
                                <a href="./report-rekeningkorandeposito" className="dropdown-item">Rekening Koran Deposito</a>
                                <a href="./report-jadwalbungadeposito" className="dropdown-item">Jadwal Bunga Deposito</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-laporan" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Inventaris <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-laporan">
                                <a href="./report-nominatifinventaris" className="dropdown-item">Nominatif Inventaris</a>
                                <a href="./report-transaksiinventaris" className="dropdown-item">Transaksi Inventaris</a>
                                <a href="./report-rekeningkoraninventaris" className="dropdown-item">Rekening Koran Inventaris</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-laporan" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> RRA <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-laporan">
                                <a href="./report-nominatifrra" className="dropdown-item">Nominatif RRA</a>
                                <a href="./report-transaksirra" className="dropdown-item">Transaksi RRA</a>
                                <a href="./report-rekeningkoranrra" className="dropdown-item">Rekening Koran RRA</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-laporan" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Akuntansi <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-laporan">
                                <a href="./report-bukubesar" className="dropdown-item">Buku Besar</a>
                                <a href="./report-neracalabarugi" className="dropdown-item">Neraca Laba Rugi</a>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a className="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-laporan" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-book me-1"></i> Teller <div
                                className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-laporan">
                                <a href="./report-posisikas" className="dropdown-item">Posisi Kas</a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        });
    }

    handleLogout = () => {
        if (window.confirm('Apakah anda yakin akan keluar dari aplikasi?')) {
            this.execLogout();
        }
    };

    execLogout = () => {
        ReactSession.set("bprsess_id", "");
        ReactSession.set("bprsess_real_id", "");
        ReactSession.set("bprsess_username", "");
        ReactSession.set("bprsess_token_type", "");
        ReactSession.set("bprsess_access_token", "");
        ReactSession.set("bprsess_refresh_token", "");

        /*for(let i=0; i< Helpers.arrMasterData.length; i++) {
            ReactSession.set("bprsess_" + Helpers.arrMasterData[i].state, "");
        }*/

        window.location.href = './login';
    }

    render() {
        return (
            <>
                <ToastContainer />

                <div className="loader-wrapper" style={{'display': 'none'}}>
                    <div className="loader"></div>
                </div>

                <div className="navbar-custom">
                    <div className="container-fluid">
                        <ul className="list-unstyled topnav-menu float-end mb-0">
                            <li className="dropdown notification-list topbar-dropdown">
                                <a className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
                                   data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false"
                                   aria-expanded="false">
                                    <img src="./assets/images/users/149071.png" alt="user-image"
                                         className="rounded-circle"/>
                                    <span className="pro-user-name ms-1"> {this.state.name} <i
                                        className="mdi mdi-chevron-down"></i>
                                    </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                                    <div className="dropdown-header noti-title">
                                        <h6 className="text-overflow m-0">Welcome !</h6>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                    <a onClick={() => {this.handleLogout()}} className="dropdown-item notify-item">
                                        <i className="fe-log-out"></i>
                                        <span>Logout</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <div className="logo-box">
                            <a href="./" className="logo logo-light text-center">
                                <span className="logo-sm">
                                    <img src="./assets/images/logo-sm.png" alt="" height="22"/>
                                </span>
                                <span className="logo-lg">
                                    <img src="./assets/images/logo-light.png" alt="" height="20"/>
                                </span>
                            </a>
                        </div>
                        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                            <li>
                                <button className="button-menu-mobile waves-effect waves-light">
                                    <i className="fe-menu"></i>
                                </button>
                            </li>
                            <li>
                                <a className="navbar-toggle nav-link" data-bs-toggle="collapse"
                                   data-bs-target="#topnav-menu-content">
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="topnav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                            <div className="collapse navbar-collapse" id="topnav-menu-content">
                                {
                                    this.state.showMenu
                                }
                            </div>
                        </nav>
                    </div>
                </div>
            </>
        );
    }
}

export default Header;