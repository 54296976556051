import React from "react";
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import $ from "jquery";
import {Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import {Connection} from "../../../config/Connection";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {Document, Page} from "react-pdf";

class Transaksi extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: '',
            urlParam: queryString.parse(window.location.search),
            jn_kantor_kode: Helpers.DEF_COMPANY_CODE,
            jn_kantor_nama: Helpers.DEF_COMPANY,

            my_kode_trans: '',
            my_kode_trans_label: '',
            jenis_kas: '10103',
            tgl_trans: '',
            kode_transaksi: '01',
            kuitansi: '',
            keterangan: '',
            transactions: [],
            dataPerkiraan: [],

            isModalOpen: false,
            reportStream: null,
            pdfWidth: 0
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getMasterData('kode-perkiraan?limit=0&search=type:D','dataPerkiraan','kode_perk',['kode_perk','nama_perk']);
            this.viewTable();
        }
    }

    getMasterData = (endpoint,state,value,label) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                this.setState({
                    [state]: res.data.map(function(item) {
                        return {
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                });
            }
        });
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value });

        if(state === 'my_kode_trans') {
            this.setState({
                my_kode_trans_label: event.label,
                transactions: []
            }, () => {
                this.viewTable();
            });
        }
    };

    generateTable = () => {
        if(this.state.my_kode_trans && this.state.jenis_kas){
            if(this.state.my_kode_trans === '100' || this.state.my_kode_trans === '390'){
                if(this.state.transactions.length === 1){
                    toast.error("Hanya dapat menambahkan satu record untuk penerimaan modal");
                }else{
                    this.state.transactions.push({
                        "kode_perk": "0",
                        "deskripsi": (this.state.my_kode_trans === '100') ? "Penerimaan Modal" : "Pengeluaran Modal",
                        "no_bukti": "",
                        "nominal": 0,
                        "kontra": "Tidak",
                        "uraian": ""
                    });

                    this.viewTable();
                }
            }else{
                this.state.transactions.push({
                    "kode_perk": "",
                    "deskripsi": "",
                    "no_bukti": "",
                    "nominal": 0,
                    "kontra": "Tidak",
                    "uraian": ""
                });

                this.viewTable();
            }
        }else{
            toast.error("Mohon mengisi jenis kas dan jenis transaksi terlebih dahulu");
        }
    };

    viewTable = () => {
        const tableTransaksi = this.state.transactions.map((object, i) => {
            return <tr>
                <td>
                    <a className="btn btn-danger btn-xs waves-effect waves-light"
                       onClick={() => {
                           this.deleteByRow(i)
                       }}>
                        <i className="fe-trash"></i>
                    </a>
                </td>
                {
                    (this.state.my_kode_trans === '100' || this.state.my_kode_trans === '390') && (
                        <td>{object.kode_perk}</td>
                    )
                }
                {
                    (this.state.my_kode_trans !== '100' && this.state.my_kode_trans !== '390') && (
                        <td width="20%">
                            <Select
                                options={this.state.dataPerkiraan}
                                value={this.state.dataPerkiraan.filter((item) => item.value === this.state.transactions[i].kode_perk)}
                                onChange={event => this.changeByRow(i,'kode_perk', event)} />
                        </td>
                    )
                }
                <td>{object.deskripsi}</td>
                <td>
                    <input type="text" className="form-control"
                           value={this.state.transactions[i].no_bukti}
                           onChange={e => this.changeByRow(i,'no_bukti', e.target.value)} />
                </td>
                <td>
                    <input type="number" className="form-control"
                           value={this.state.transactions[i].nominal}
                           onChange={e => this.changeByRow(i,'nominal', e.target.value)} />
                </td>
                <td>
                    <Select
                        options={Helpers.optionsPick}
                        value={Helpers.optionsPick.filter((item) => item.label === this.state.transactions[i].kontra)}
                        onChange={event => this.changeByRow(i,'kontra', event.label)} />
                </td>
                <td>
                    <input type="text" className="form-control"
                           value={this.state.transactions[i].uraian}
                           onChange={e => this.changeByRow(i,'uraian', e.target.value)} />
                </td>
            </tr>
        });

        this.setState({
            tableTransaksi
        })
    };

    changeByRow = (i,key,val) => {
        const objArr = [...this.state.transactions];
        objArr[i] = {
            "kode_perk": (key === 'kode_perk') ? val.value : objArr[i].kode_perk,
            "deskripsi": (key === 'kode_perk') ? val.label : objArr[i].deskripsi,
            "no_bukti": (key === 'no_bukti') ? val : objArr[i].no_bukti,
            "nominal": (key === 'nominal') ? val : objArr[i].nominal,
            "kontra": (key === 'kontra') ? val : objArr[i].kontra,
            "uraian": (key === 'uraian') ? val : objArr[i].uraian,
        };
        this.setState({transactions: objArr}, () => {
            this.viewTable();
        });
    };

    deleteByRow = (i) => {
        let objArr = [...this.state.transactions].filter((item, index) =>
            index !== i
        );
        this.setState({transactions: objArr}, () => {
            this.viewTable();
        });
    };

    saveTransaksi = () => {
        let urlencoded = new URLSearchParams();
        urlencoded.append("tgl_trans", this.state.tgl_trans);
        urlencoded.append("my_kode_trans", this.state.my_kode_trans);
        urlencoded.append("kuitansi", Helpers.castEmpty(this.state.kuitansi));
        urlencoded.append("keterangan", Helpers.castEmpty(this.state.keterangan));
        urlencoded.append("jenis_kas", this.state.jenis_kas);
        urlencoded.append("transactions", JSON.stringify({
            details: this.state.transactions
        }));

        $(".loader-wrapper").css("display", "");
        let getResponseCode = null;
        fetch(Connection.API_URL + 'teller-transaction/kas-umum', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => {
            getResponseCode = res.status;
            return res.json();
        }).then((json) => {
            if(getResponseCode !== 200){
                toast.error(json.message);
            }else{
                if(json.exception){
                    toast.error(json.message);
                }else{
                    if(json.errors){
                        alert(Helpers.customError(json.errors));
                    }else{
                        if(json.status === 'error'){
                            toast.error(json.message);
                        }else{
                            toast.success(Helpers.MSG_SUCCESS);

                            this.setState({
                                trans_id: json.data[0].trans_id
                            });

                            /*setTimeout(function() {
                                window.location.href = './teller-kas-transaksi';
                            }, 1000);*/
                        }
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    handleItemPrint = () => {
        if(this.state.trans_id){
            $(".loader-wrapper").css("display", "");
            Helpers.getReportPDF(this.state.sessToken,'teller-transaction/report/transaction/print/'+this.state.trans_id+'?format=pdf').then(res => {
                this.setState({
                    reportStream: res
                }, () => {
                    this.handleModal();
                });

                $(".loader-wrapper").css("display", "none");
            });
        }else{
            toast.error('Anda belum melakukan transaksi!');
        }
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleDownload = () => {
        const uid = (new Date().getTime());
        Helpers.download(this.state.reportStream, 'kuitansi_penerimaan_bunga_deposito_'+uid);
    };

    render() {
        return (
            <div className="content">

                <Modal
                    size={'lg'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}>
                    <ModalBody>
                        <Document file={this.state.reportStream}>
                            <Page
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={this.state.pdfWidth}
                            />
                        </Document>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {this.handleDownload()}}>Download</Button>
                        <Button onClick={() => {this.handleModal()}}>Close</Button>
                    </ModalFooter>
                </Modal>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Teller</li>
                                        <li className="breadcrumb-item">Kas Umum</li>
                                        <li className="breadcrumb-item active">Transaksi Kas</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Transaksi Kas</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-bordered">
                                        <li className="nav-item">
                                            <a href="#form-1" data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                                Info Cabang
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="form-1">
                                            <div className={'row'}>
                                                <div className={'col-5'}>
                                                    <div className="row mb-1">
                                                        <div className="col-md-4">
                                                            <label className="form-label">Cabang</label>
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.jn_kantor_kode} onChange={e => this.setState({jn_kantor_kode: e.target.value})} />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <label className="form-label">&nbsp;</label>
                                                            <input type="text" className="form-control" readOnly={true} value={this.state.jn_kantor_nama} onChange={e => this.setState({jn_kantor_nama: e.target.value})} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tanggal Transaksi <code>(required)</code></label>
                                                        <input type="date" className="form-control" value={this.state.tgl_trans} onChange={e => this.setState({tgl_trans: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Kas</label>
                                                        <Select
                                                            options={Helpers.optionsJenisKas}
                                                            value={Helpers.optionsJenisKas.filter((item) => item.value === this.state.jenis_kas)}
                                                            onChange={event => this.handleChange(event,'jenis_kas')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Jenis Transaksi <code>(required)</code></label>
                                                        <Select
                                                            options={Helpers.optionsJenisTransaksi}
                                                            value={Helpers.optionsJenisTransaksi.filter((item) => item.value === this.state.my_kode_trans)}
                                                            onChange={event => this.handleChange(event,'my_kode_trans')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Keterangan</label>
                                                        <textarea className="form-control" rows="3" value={this.state.keterangan} onChange={e => this.setState({keterangan: e.target.value})}></textarea>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Reff</label>
                                                        <input type="text" className="form-control" value={this.state.kuitansi} onChange={e => this.setState({kuitansi: e.target.value})} />
                                                    </div>
                                                </div>
                                                <div className={'col-12'}>
                                                    <div className="mb-1">
                                                        <label className="form-label">Transaksi</label>
                                                    </div>
                                                    <table className="table" width="100%">
                                                        <thead className="table-light">
                                                        <tr>
                                                            <th>
                                                                <a className="btn btn-info btn-xs waves-effect waves-light"
                                                                   onClick={() => {
                                                                       this.generateTable()
                                                                   }}>
                                                                    <i className="fe-plus"></i>
                                                                </a>
                                                            </th>
                                                            <th>No. Rekening</th>
                                                            <th>Deskripsi</th>
                                                            <th>No. Bukti</th>
                                                            <th>Jumlah</th>
                                                            <th>Kontra</th>
                                                            <th>Uraian</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            this.state.tableTransaksi
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="mt-3">
                                                    <a className="btn btn-primary waves-effect waves-light mr-5" onClick={() => {this.saveTransaksi()}}>
                                                        <i className="fe-save me-1"></i> Simpan
                                                    </a>
                                                    <a className="btn btn-danger waves-effect waves-light mr-5" onClick={() => {window.location.reload()}}>
                                                        <i className="fe-trash me-1"></i> Bersihkan
                                                    </a>
                                                    <a className="btn btn-info waves-effect waves-light mr-5" onClick={() => {this.handleItemPrint()}}>
                                                        <i className="fe-printer me-1"></i> Cetak Kuitansi
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Transaksi;