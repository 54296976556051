import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../../config/Connection";
import '../../../../styles/NoBorderTable.css';

class PenyusutanManual extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: '',
            urlParam: queryString.parse(window.location.search),

            tgl_trans: '',
            nominal: '',
            my_kode_trans: '300',
            jenis_pembayaran: '99',
            kode_transaksi: '30',
            no_rekening: '',
            kuitansi: '',
            keterangan: '',
            no_rek_ob: '',
            id_col: '',

            id_rekening: '',
            ket_jenis: '',
            ket_pembayaran: '',
            deskripsi: '',
            nilai_buku: '',

            isShowKodeTransaksi: false,

            dataRekening: [],
            dataKodeJenisTransaksiAktiva: [],
            dataKodeJenisPembayaran: JSON.parse(ReactSession.get("bprsess_dataKodeJenisPembayaran")),
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getMasterData('kode-transaksi-aktiva?limit=0','dataKodeJenisTransaksiAktiva','real_id','deskripsi');
            this.getMasterData('rekening-aktiva?limit=0','dataRekening','id',['no_rekening','deskripsi_rra']);
        }
    }

    getMasterData = (endpoint,state,value,label,except) => {
        Helpers.getMasterData(this.state.sessToken,endpoint).then(res => {
            if(res.data){
                if(except){
                    this.setState({
                        [state]: res.data.filter(function (e) {
                            return e[except] !== '';
                        }).map(function(item) {
                            return {
                                value: item[value],
                                label: (label instanceof Array) ? label.map(function(val, index) {
                                    let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                    return (
                                        index === label.length-1
                                    ) ? setVal : setVal + ' - ';
                                }) : item[label].trim()
                            };
                        })
                    });
                }else{
                    this.setState({
                        [state]: res.data.map(function(item) {
                            return {
                                value: item[value],
                                label: (label instanceof Array) ? label.map(function(val, index) {
                                    let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                    return (
                                        index === label.length-1
                                    ) ? setVal : setVal + ' - ';
                                }) : item[label].trim()
                            };
                        })
                    });
                }
            }
        });
    };

    generateKeterangan = () => {
        let parent = this;
        this.setState({
            keterangan: Helpers.optionsJenisTransaksiRRA.filter(function(x){
                return x.value === parent.state.my_kode_trans;
            })[0].label + ' ' + this.state.dataKodeJenisPembayaran.filter(function(x){
                return x.value === parent.state.jenis_pembayaran;
            })[0].label + ' RRA: ' + this.state.no_rekening + ' - ' + this.state.deskripsi,
        });
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value });

        if(state === 'my_kode_trans') {
            this.setState({
                ket_jenis: event.label,
                jenis_pembayaran: (event.value === '300') ? '99' : this.state.jenis_pembayaran
            }, () => {
                this.generateKeterangan();

                if(this.state.my_kode_trans === '100' && this.state.jenis_pembayaran === '10'){
                    this.setState({
                        kode_transaksi: '10',
                    });
                }else if(this.state.my_kode_trans === '300' && this.state.jenis_pembayaran === '99'){
                    this.setState({
                        kode_transaksi: '30',
                    });
                }else{
                    this.getMasterData('kode-transaksi-aktiva?search=jenis_pembayaran:'+event.value+'&limit=0','dataKodeJenisTransaksiAktiva','real_id','deskripsi');
                }
            });
        }else if(state === 'jenis_pembayaran'){
            this.setState({
                ket_pembayaran: event.label,
                isShowKodeTransaksi: (event.label === 'OB GL') ? true : false,
            }, () => {
                this.generateKeterangan();

                if(this.state.my_kode_trans === '100' && this.state.jenis_pembayaran === '10'){
                    this.setState({
                        kode_transaksi: '10',
                    });
                }else if(this.state.my_kode_trans === '300' && this.state.jenis_pembayaran === '99'){
                    this.setState({
                        kode_transaksi: '30',
                    });
                }else{
                    this.getMasterData('kode-transaksi-aktiva?search=jenis_pembayaran:'+event.value+'&limit=0','dataKodeJenisTransaksiAktiva','real_id','deskripsi');
                }
            });
        }else if(state === 'id_rekening'){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'rekening-aktiva/'+event.value).then(res => {
                if(res.data){
                    this.setState({
                        dataSource: res.data,
                        deskripsi: res.data.deskripsi_rra,
                        no_rekening: res.data.no_rekening,
                        nominal: (this.state.my_kode_trans === '100') ? res.data.nilai_buku : res.data.nilai_buku,
                        nilai_buku: res.data.nilai_buku
                    }, () => {
                        this.generateKeterangan();
                    });
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }
    };

    submitData = () => {
        this.saveNasabah('POST','aktiva-transaction');
    };

    saveNasabah = (method,endpoint) => {
        let urlencoded = new URLSearchParams();
        urlencoded.append("tgl_trans", this.state.tgl_trans);
        urlencoded.append("nominal", this.state.nominal);
        urlencoded.append("my_kode_trans", this.state.my_kode_trans);
        urlencoded.append("jenis_pembayaran", this.state.jenis_pembayaran);
        urlencoded.append("kode_transaksi", this.state.kode_transaksi);
        urlencoded.append("no_rekening", this.state.no_rekening);
        urlencoded.append("kuitansi", this.state.kuitansi);
        urlencoded.append("keterangan", this.state.keterangan);
        urlencoded.append("no_rek_ob", '');
        urlencoded.append("id_col", '');

        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + endpoint, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            },
            body: urlencoded
        }).then((res) => res.json()).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        if(json.message){
                            toast.error(json.message);
                        }else{
                            toast.success(Helpers.MSG_SUCCESS);

                            setTimeout(function() {
                                window.location.href = './penyusutan-manual-rra';
                            }, 1000);
                        }
                    }
                }
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        const animatedComponents = makeAnimated();

        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Akuntansi</li>
                                        <li className="breadcrumb-item">RRA</li>
                                        <li className="breadcrumb-item">Administrasi</li>
                                        <li className="breadcrumb-item active">Penyusutan Manual</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Penyusutan Manual</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary waves-effect waves-light mr-5" onClick={() => {this.submitData()}}>
                                        <i className="fe-save me-1"></i> Simpan
                                    </a>
                                    <a className="btn btn-danger waves-effect waves-light mr-5" onClick={() => {window.location.reload()}}>
                                        <i className="fe-trash me-1"></i> Bersihkan
                                    </a>
                                    <a className="btn btn-info waves-effect waves-light mr-5" onClick={() => {}}>
                                        <i className="fe-printer me-1"></i> Print Buku Tabungan
                                    </a>
                                    <a className="btn btn-info waves-effect waves-light mr-5" onClick={() => {}}>
                                        <i className="fe-printer me-1"></i> Print Validasi
                                    </a>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-bordered">
                                        <li className="nav-item">
                                            <a href="#form-1" data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                                Penyusutan Manual
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="form-1">
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <div className="mb-1" style={{'display': 'none'}}>
                                                        <label className="form-label">Jenis Transaksi <code>(required)</code></label>
                                                        <Select
                                                            options={Helpers.optionsJenisTransaksiRRA}
                                                            value={Helpers.optionsJenisTransaksiRRA.filter((item) => item.value === this.state.my_kode_trans)}
                                                            onChange={event => this.handleChange(event,'my_kode_trans')} />
                                                    </div>
                                                    <div className="mb-1" style={{'display': 'none'}}>
                                                        <label className="form-label">Jenis Pembayaran <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataKodeJenisPembayaran}
                                                            value={this.state.dataKodeJenisPembayaran.filter((item) => item.value === this.state.jenis_pembayaran)}
                                                            onChange={event => this.handleChange(event,'jenis_pembayaran')} />
                                                    </div>

                                                    {
                                                        this.state.isShowKodeTransaksi && (
                                                            <div className="mb-1">
                                                                <label className="form-label">Kode Transaksi <code>(required)</code></label>
                                                                <Select
                                                                    options={this.state.dataKodeJenisTransaksiAktiva}
                                                                    value={this.state.dataKodeJenisTransaksiAktiva.filter((item) => item.value === this.state.kode_transaksi)}
                                                                    onChange={event => this.handleChange(event,'kode_transaksi')} />
                                                            </div>
                                                        )
                                                    }

                                                    <div className="dropdown-divider mt-3 mb-2" style={{'display': 'none'}}></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Tgl Transaksi <code>(required)</code></label>
                                                        <input type="date" className="form-control" value={this.state.tgl_trans} onChange={e => this.setState({tgl_trans: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">No. Rekening <code>(required)</code></label>
                                                        <Select
                                                            options={this.state.dataRekening}
                                                            value={this.state.dataRekening.filter((item) => item.value === this.state.id_rekening)}
                                                            onChange={event => this.handleChange(event,'id_rekening')} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Deskripsi</label>
                                                        <input type="text" readOnly={true} className="form-control" value={this.state.deskripsi} onChange={e => this.setState({deskripsi: e.target.value})} />
                                                    </div>

                                                    <div className="dropdown-divider mt-3 mb-2"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Kuitansi</label>
                                                        <input type="text" className="form-control" value={this.state.kuitansi} onChange={e => this.setState({kuitansi: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Keterangan</label>
                                                        <textarea className="form-control" rows="3" value={this.state.keterangan} onChange={e => this.setState({keterangan: e.target.value})}></textarea>
                                                    </div>

                                                    <div className="dropdown-divider mt-3 mb-2"></div>
                                                    <div className="mb-1">
                                                        <label className="form-label">Nilai RRA</label>
                                                        <input type="number" className="form-control" value={this.state.nominal} onChange={e => this.setState({nominal: e.target.value})} />
                                                    </div>
                                                    <div className="mb-1" style={{'display': 'none'}}>
                                                        <label className="form-label">Nilai Buku</label>
                                                        <input type="number" className="form-control" value={this.state.nilai_buku} onChange={e => this.setState({nilai_buku: e.target.value})} readOnly={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PenyusutanManual;