import React from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import {ReactSession} from "react-client-session";
import { v4 as uuidv4 } from 'uuid';

import Header from "./Header";
import Footer from "./Footer";
import Login from "./pages/Login";
import Home from "./pages/Home";
import DataNasabah from "./pages/customer_service/DataNasabah";
import DataNasabahForm from "./pages/customer_service/DataNasabahForm";
import Negara from "./pages/customer_service/master_data/Negara";
import Bank from "./pages/customer_service/master_data/Bank";
import Gelar from "./pages/customer_service/master_data/Gelar";
import Kota from "./pages/customer_service/master_data/Kota";
import Pos from "./pages/customer_service/master_data/Pos";
import Lokasi from "./pages/customer_service/master_data/Lokasi";
import Pekerjaan from "./pages/customer_service/master_data/Pekerjaan";
import Pemasok from "./pages/customer_service/master_data/Pemasok";
import Pemilik from "./pages/customer_service/master_data/Pemilik";
import SumberDana from "./pages/customer_service/master_data/SumberDana";
import TujuanPembukaan from "./pages/customer_service/master_data/TujuanPembukaan";
import Valas from "./pages/customer_service/master_data/Valas";
import DataRekening from "./pages/back_office/tabungan/DataRekening";
import DataRekeningForm from "./pages/back_office/tabungan/DataRekeningForm";
import HapusTransaksiTabungan from "./pages/back_office/tabungan/administrasi/HapusTransaksi";
import Perseorangan from "./pages/teller/tabungan/Perseorangan";
import Kolektif from "./pages/teller/tabungan/Kolektif";
import TransaksiDeposito from "./pages/teller/deposito/Transaksi";
import TransaksiKasUmum from "./pages/teller/kas_umum/Transaksi";
import DataRekeningDeposito from "./pages/back_office/deposito/DataRekening";
import DataRekeningFormDeposito from "./pages/back_office/deposito/DataRekeningForm";
import HapusTransaksiDeposito from "./pages/back_office/deposito/administrasi/HapusTransaksi";
import KodeTransaksiTabungan from "./pages/back_office/tabungan/setting/KodeTransaksiTabungan";
import TransaksiTabungan from "./pages/laporan/tabungan/TransaksiTabungan";
import NominatifTabungan from "./pages/laporan/tabungan/NominatifTabungan";
import RekeningKoranTabungan from "./pages/laporan/tabungan/RekeningKoranTabungan";
import BukuBesar from "./pages/laporan/akuntansi/BukuBesar";
import NeracaLabaRugi from "./pages/laporan/akuntansi/NeracaLabaRugi";
import Posting from "./pages/akuntansi/pembukuan/administrasi/Posting";
import JurnalUmum from "./pages/akuntansi/pembukuan/JurnalUmum";
import JurnalUmumDetail from "./pages/akuntansi/pembukuan/JurnalUmumDetail";
import JurnalUmumBaru from "./pages/akuntansi/pembukuan/JurnalUmumBaru";
import ReportTransaksiDeposito from "./pages/laporan/deposito/TransaksiDeposito";
import NominatifDeposito from "./pages/laporan/deposito/NominatifDeposito";
import RekeningKoranDeposito from "./pages/laporan/deposito/RekeningKoranDeposito";
import SyncData from "./pages/customer_service/setting/SyncData";
import ARO from "./pages/back_office/deposito/ARO";
import FeeMarketing from "./pages/back_office/deposito/FeeMarketing";
import JadwalBungaDeposito from "./pages/laporan/deposito/JadwalBungaDeposito";
import DataRekeningInventaris from "./pages/akuntansi/inventaris/DataRekening";
import DataRekeningFormInventaris from "./pages/akuntansi/inventaris/DataRekeningForm";
import JenisInventaris from "./pages/akuntansi/inventaris/Setting/JenisInventaris";
import KodeTransaksiInventaris from "./pages/akuntansi/inventaris/Setting/KodeTransaksiInventaris";
import PenyusutanManualInventaris from "./pages/akuntansi/inventaris/Administrasi/PenyusutanManual";
import TransaksiInventaris from "./pages/teller/inventaris/Transaksi";
import ReportTransaksiInventaris from "./pages/laporan/inventaris/TransaksiInventaris";
import NominatifInventaris from "./pages/laporan/inventaris/NominatifInventaris";
import RekeningKoranInventaris from "./pages/laporan/inventaris/RekeningKoranInventaris";
import HapusTransaksiInventaris from "./pages/akuntansi/inventaris/Administrasi/HapusTransaksi";

import DataRekeningRRA from "./pages/akuntansi/rra/DataRekening";
import DataRekeningFormRRA from "./pages/akuntansi/rra/DataRekeningForm";
import JenisRRA from "./pages/akuntansi/rra/Setting/JenisRRA";
import KodeTransaksiRRA from "./pages/akuntansi/rra/Setting/KodeTransaksiRRA";
import TransaksiRRA from "./pages/teller/rra/Transaksi";
import ReportTransaksiRRA from "./pages/laporan/rra/TransaksiRRA";
import NominatifRRA from "./pages/laporan/rra/NominatifRRA";
import RekeningKoranRRA from "./pages/laporan/rra/RekeningKoranRRA";
import PenyusutanManualRRA from "./pages/akuntansi/rra/Administrasi/PenyusutanManual";
import HapusTransaksiRRA from "./pages/akuntansi/rra/Administrasi/HapusTransaksi";

import PosisiKas from "./pages/laporan/teller/PosisiKas";

class App extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <div id="wrapper">
                    <Header/>
                    <div className="content-page">
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="login" element={<Login />} />
                                <Route path="data-nasabah" element={<DataNasabah />} />
                                <Route path="data-nasabah-form" element={<DataNasabahForm />} />
                                <Route path="master-negara" element={<Negara />} />
                                <Route path="master-bank" element={<Bank />} />
                                <Route path="master-gelar" element={<Gelar />} />
                                <Route path="master-kota" element={<Kota />} />
                                <Route path="master-pos" element={<Pos />} />
                                <Route path="master-lokasi" element={<Lokasi />} />
                                <Route path="master-pekerjaan" element={<Pekerjaan />} />
                                <Route path="master-pemasok" element={<Pemasok />} />
                                <Route path="master-pemilik" element={<Pemilik />} />
                                <Route path="master-sumberdana" element={<SumberDana />} />
                                <Route path="master-tujuanpembukaan" element={<TujuanPembukaan />} />
                                <Route path="master-valas" element={<Valas />} />
                                <Route path="data-rekening" element={<DataRekening />} />
                                <Route path="data-rekening-form" element={<DataRekeningForm />} />
                                <Route path="hapus-transaksi-tabungan" element={<HapusTransaksiTabungan />} />
                                <Route path="teller-tab-perseorangan" element={<Perseorangan />} />
                                <Route path="teller-tab-kolektif" element={<Kolektif />} />
                                <Route path="teller-dep-transaksi" element={<TransaksiDeposito />} />
                                <Route path="teller-kas-transaksi" element={<TransaksiKasUmum />} />
                                <Route path="data-rekening-deposito" element={<DataRekeningDeposito />} />
                                <Route path="data-rekening-form-deposito" element={<DataRekeningFormDeposito />} />
                                <Route path="hapus-transaksi-deposito" element={<HapusTransaksiDeposito />} />
                                <Route path="kode-transaksi-tabungan" element={<KodeTransaksiTabungan />} />
                                <Route path="report-transaksitabungan" element={<TransaksiTabungan />} />
                                <Route path="report-nominatiftabungan" element={<NominatifTabungan />} />
                                <Route path="report-rekeningkorantabungan" element={<RekeningKoranTabungan />} />
                                <Route path="akuntansi-posting" element={<Posting />} />
                                <Route path="akuntansi-jurnalumum" element={<JurnalUmum />} />
                                <Route path="akuntansi-jurnalumum-detail" element={<JurnalUmumDetail />} />
                                <Route path="akuntansi-jurnalumum-baru" element={<JurnalUmumBaru />} />
                                <Route path="report-bukubesar" element={<BukuBesar />} />
                                <Route path="report-neracalabarugi" element={<NeracaLabaRugi />} />
                                <Route path="report-transaksideposito" element={<ReportTransaksiDeposito />} />
                                <Route path="report-nominatifdeposito" element={<NominatifDeposito />} />
                                <Route path="report-rekeningkorandeposito" element={<RekeningKoranDeposito />} />
                                <Route path="syncdata" element={<SyncData />} />
                                <Route path="aro" element={<ARO />} />
                                <Route path="feemarketing" element={<FeeMarketing />} />
                                <Route path="report-jadwalbungadeposito" element={<JadwalBungaDeposito />} />
                                <Route path="data-rekening-inventaris" element={<DataRekeningInventaris />} />
                                <Route path="data-rekening-form-inventaris" element={<DataRekeningFormInventaris />} />
                                <Route path="penyusutan-manual-inventaris" element={<PenyusutanManualInventaris />} />
                                <Route path="jenis-inventaris" element={<JenisInventaris />} />
                                <Route path="kode-transaksi-inventaris" element={<KodeTransaksiInventaris />} />
                                <Route path="teller-transaksi-inventaris" element={<TransaksiInventaris />} />
                                <Route path="report-transaksiinventaris" element={<ReportTransaksiInventaris />} />
                                <Route path="report-nominatifinventaris" element={<NominatifInventaris />} />
                                <Route path="report-rekeningkoraninventaris" element={<RekeningKoranInventaris />} />
                                <Route path="hapus-transaksi-inventaris" element={<HapusTransaksiInventaris />} />

                                <Route path="data-rekening-rra" element={<DataRekeningRRA />} />
                                <Route path="data-rekening-form-rra" element={<DataRekeningFormRRA />} />
                                <Route path="jenis-rra" element={<JenisRRA />} />
                                <Route path="kode-transaksi-rra" element={<KodeTransaksiRRA />} />
                                <Route path="penyusutan-manual-rra" element={<PenyusutanManualRRA />} />
                                <Route path="teller-transaksi-rra" element={<TransaksiRRA />} />
                                <Route path="report-transaksirra" element={<ReportTransaksiRRA />} />
                                <Route path="report-nominatifrra" element={<NominatifRRA />} />
                                <Route path="report-rekeningkoranrra" element={<RekeningKoranRRA />} />
                                <Route path="hapus-transaksi-rra" element={<HapusTransaksiRRA />} />

                                <Route path="report-posisikas" element={<PosisiKas />} />
                            </Routes>
                        </BrowserRouter>

                        <Footer/>
                    </div>
                </div>
            </>
        );
    }
}

export default App;