import React from "react";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import $ from "jquery";
import {Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import {Connection} from "../../../config/Connection";

class FeeMarketing extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: []
        }
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getData();
        }
    }

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(this.state.sessToken,'deposito-funding-fee?limit=0').then(res => {
            if(res.data){
                this.setState({
                    dataSource: res.data
                });
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    selectData = (id) => {
        $(".loader-wrapper").css("display", "");
        fetch(Connection.API_URL + 'deposito-funding-fee/' + id, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': 'id',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer ' + this.state.sessToken
            }
        }).then((res) => res.json()).then((json) => {
            if(json.exception){
                toast.error(json.message);
            }else{
                if(json.errors){
                    alert(Helpers.customError(json.errors));
                }else{
                    if(json.status === 'error'){
                        toast.error(json.message);
                    }else{
                        toast.success(Helpers.MSG_SUCCESS);

                        this.getData();
                    }
                }
            }
        }).catch((e) => {
            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Back Office</li>
                                        <li className="breadcrumb-item">Deposito</li>
                                        <li className="breadcrumb-item active">Fee Marketing</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Fee Marketing</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'No. Rekening',
                                                        field: 'no_rekening',
                                                    },
                                                    {
                                                        label: 'Tgl Registrasi',
                                                        field: 'tgl_registrasi',
                                                    },
                                                    {
                                                        label: 'Tgl Bayar',
                                                        field: 'tgl_bayar',
                                                    },
                                                    {
                                                        label: 'Tgl Jatuh Tempo',
                                                        field: 'tgl_jatuh_tempo',
                                                    },
                                                    {
                                                        label: 'Fee Marketing',
                                                        field: 'fee_marketing_formatted'
                                                    },
                                                    {
                                                        label: 'Pajak',
                                                        field: 'pajak_formatted'
                                                    },
                                                    {
                                                        label: 'Action',
                                                        field: 'action',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    let showButton = (!row.tgl_bayar) ? '' : 'none';
                                                    return {
                                                        ...row,
                                                        action: (
                                                            <button type="button" style={{'display': showButton}} className="btn btn-success btn-rounded btn-sm" onClick={() => {this.selectData(row.trans_id)}}>
                                                                Proses
                                                            </button>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeeMarketing;