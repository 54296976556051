import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from "moment";

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 5
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        //fontFamily: 'Helvetica-Oblique'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    }
});


const BillTo = ({invoice}) => (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>3.3.01/00.Transaksi</Text>
        <View style={styles.invoiceDateContainer}>
            <Text>{moment(invoice.start_date).utc().format('MMMM DD, YYYY')}</Text>
            <Text> s.d </Text>
            <Text>{moment(invoice.end_date).utc().format('MMMM DD, YYYY')}</Text>
        </View >
    </View>
);

export default BillTo