import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#000',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    td: {
        textAlign: 'left',
        paddingLeft: 5,
        paddingRight: 5,
        fontSize: 8
    },
});


const InvoiceTableRow = ({invoice}) => {
    let rows = null;
    if(invoice.report_type === 'transaksi_tabungan'){
        rows = invoice.items.map((item,index) =>
            <View style={styles.row}>
                <Text style={[styles.td, {width: '3%'}]}>{index+1}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.tgl_trans}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.no_rekening}</Text>
                <Text style={[styles.td, {width: '21%'}]}>{item.nama_nasabah}</Text>
                <Text style={[styles.td, {width: '5%', textAlign: 'center'}]}>{item.kode_trans}</Text>
                <Text style={[styles.td, {width: '16%'}]}>{item.kode_trans_label}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.kuitansi}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.user_id}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.user_id}</Text>
                <Text style={[styles.td, {width: '10%', textAlign: 'right'}]}>{(item.type_transaksi === 'K') ? item.saldo_trans_formatted : 0}</Text>
                <Text style={[styles.td, {width: '10%', textAlign: 'right'}]}>{(item.type_transaksi === 'D') ? item.saldo_trans_formatted : 0}</Text>
            </View>
        );
    }else if(invoice.report_type === 'nominatif_tabungan'){
        rows = invoice.items.map((item,index) =>
            <View style={styles.row}>
                <Text style={[styles.td, {width: '3%'}]}>{index+1}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.no_rekening}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.no_alternatif}</Text>
                <Text style={[styles.td, {width: '21%'}]}>{item.nama_nasabah}</Text>
                <Text style={[styles.td, {width: '10%', textAlign: 'center'}]}>{item.kota}</Text>
                <Text style={[styles.td, {width: '16%'}]}>{item.bank_terkait}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.kode_gol_debitur}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.jenis_nasabah}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.status_rekening}</Text>
                <Text style={[styles.td, {width: '15%', textAlign: 'right'}]}>{item.balance_formatted}</Text>
            </View>
        );
    }else if(invoice.report_type === 'rekening_koran_tabungan'){
        rows = invoice.items.map((item,index) =>
            <View style={styles.row}>
                <Text style={[styles.td, {width: '3%'}]}>{index+1}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.tgl_trans}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.no_rekening}</Text>
                <Text style={[styles.td, {width: '21%'}]}>{item.nama_nasabah}</Text>
                <Text style={[styles.td, {width: '5%', textAlign: 'center'}]}>{item.kode_trans}</Text>
                <Text style={[styles.td, {width: '16%'}]}>{item.kode_trans_label}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.kuitansi}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.user_id}</Text>
                <Text style={[styles.td, {width: '7%'}]}>{item.user_id}</Text>
                <Text style={[styles.td, {width: '10%', textAlign: 'right'}]}>{(item.type_transaksi === 'K') ? item.saldo_trans_formatted : 0}</Text>
                <Text style={[styles.td, {width: '10%', textAlign: 'right'}]}>{(item.type_transaksi === 'D') ? item.saldo_trans_formatted : 0}</Text>
            </View>
        );
    }
    return (<Fragment>{rows}</Fragment> )
};

export default InvoiceTableRow