import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import {CONF_HEADERS, Helpers} from "../../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import Select from 'react-select';
import moment from "moment";
import {MDBDataTableV5} from "mdbreact";
import {Connection} from "../../../config/Connection";
import ModalReport from "../../../components/ModalReport";
import ModalKodepos from "../../../components/ModalKodepos";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {PDFViewer} from "@react-pdf/renderer";
import { Document, Page } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import "react-pdf/dist/esm/Page/TextLayer.css";

class NeracaLabaRugi extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessToken: (ReactSession.get("bprsess_access_token")) ? ReactSession.get("bprsess_access_token") : '',
            dataSource: [],
            isModalOpen: false,
            startDate: '',
            jenisLaporan: '',
            reportStream: null,
            pdfWidth: 0
        };
    }

    componentDidMount() {
        if(!this.state.sessToken){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.setState({
                pdfWidth: parseInt(document.getElementById("content").offsetWidth*0.57)
            });
        }
    }

    getData = () => {
        if(this.state.startDate){
            $(".loader-wrapper").css("display", "");
            Helpers.getMasterData(this.state.sessToken,'akuntansi-jurnal/report/neraca?tgl_neraca='+this.state.startDate+'&limit=0').then(res => {
                if(res.data){
                    this.setState({
                        dataSource: res.data
                    });
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }

                $(".loader-wrapper").css("display", "none");
            })
        }else{
            toast.error('Anda belum memilih periode');
        }
    };

    handleChange = (event,state) => {
        this.setState({
            [state]: event.value
        });
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleModalClick = () => {
        if(this.state.startDate && this.state.jenisLaporan){
            $(".loader-wrapper").css("display", "");

            let setUrl = '';
            if(this.state.jenisLaporan === '1'){
                setUrl = 'akuntansi-jurnal/report/neraca/export?format=pdf&tgl_neraca='+this.state.startDate;
            }else if(this.state.jenisLaporan === '2'){
                setUrl = 'akuntansi-jurnal/report/labarugi/export?format=pdf&tgl_neraca='+this.state.startDate;
            }else if(this.state.jenisLaporan === '4'){
                setUrl = 'akuntansi-jurnal/report/neraca/export?format=pdf&tgl_neraca='+this.state.startDate+'&rekap=true';
            }else if(this.state.jenisLaporan === '5'){
                setUrl = 'akuntansi-jurnal/report/labarugi/export?format=pdf&tgl_neraca='+this.state.startDate+'&rekap=true';
            }

            Helpers.getReportPDF(this.state.sessToken,setUrl).then(res => {
                this.setState({
                    reportStream: res
                }, () => {
                    this.handleModal();
                });

                $(".loader-wrapper").css("display", "none");
            })
        }else{
            toast.error('Anda belum memilih periode');
        }
    };

    handleDownload = () => {
        const uid = (new Date().getTime());
        Helpers.download(this.state.reportStream, 'neraca_laba_rugi_'+uid);
    };

    render() {
        return (
            <div className="content" id="content">
                <Modal
                    size={'lg'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}>
                    <ModalBody>
                        <Document file={this.state.reportStream}>
                            <Page
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={this.state.pdfWidth}
                            />
                        </Document>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {this.handleDownload()}}>Download</Button>
                        <Button onClick={() => {this.handleModal()}}>Close</Button>
                    </ModalFooter>
                </Modal>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="./">Home</a></li>
                                        <li className="breadcrumb-item">Laporan</li>
                                        <li className="breadcrumb-item">Akuntansi</li>
                                        <li className="breadcrumb-item active">Neraca Laba Rugi</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Neraca Laba Rugi</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label">Tanggal</label>
                                            <input type="date" className="form-control" value={this.state.startDate} onChange={e => this.setState({startDate: e.target.value})} />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">Jenis Laporan</label>
                                            <Select
                                                options={Helpers.optionsJenisLaporanNeraca}
                                                value={Helpers.optionsJenisLaporanNeraca.filter((item) => item.value === this.state.jenisLaporan)}
                                                onChange={event => this.handleChange(event,'jenisLaporan')} />
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.getData()}}>
                                                Proses
                                            </a>
                                        </div>
                                        <div className="col-1">
                                            <a className="btn btn-primary waves-effect waves-light" style={{'margin-top': '28px'}} onClick={() => {this.handleModalClick()}}>
                                            Cetak
                                            </a>
                                        </div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                </div>
                                <div className="col-12">
                                    <MDBDataTableV5
                                        hover
                                        striped
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'Kode Perkiraan',
                                                        field: 'kode_perk',
                                                    },
                                                    {
                                                        label: 'Nama Perkiraan',
                                                        field: 'perk',
                                                    },
                                                    {
                                                        label: 'Saldo Awal',
                                                        field: 'd1',
                                                    },
                                                    {
                                                        label: 'Debet',
                                                        field: 'd2',
                                                    },
                                                    {
                                                        label: 'Kredit',
                                                        field: 'd3',
                                                    },
                                                    {
                                                        label: 'Saldo Akhir',
                                                        field: 'd4',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    let namaperk = row.nama_perk.replace(/ /g,"\u00A0");
                                                    return {
                                                        ...row,
                                                        perk: (
                                                            <p>{namaperk}</p>
                                                        ),
                                                        d1: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.saldo_awal_formatted}</label>
                                                        ),
                                                        d2: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.debet_formatted}</label>
                                                        ),
                                                        d3: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.kredit_formatted}</label>
                                                        ),
                                                        d4: (
                                                            <label style={{'text-align': 'right', 'width': '100%'}}>{row.saldo_akhir_formatted}</label>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NeracaLabaRugi;