import React from 'react';
import { Page, Document, Text, Image, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:30,
        paddingRight:30,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'left',
        color: 'grey',
        paddingLeft: 30
    },
});

const Invoice = ({invoice}) => (
    <Document>
        <Page size="A4" orientation={"landscape"} style={styles.page}>
            <InvoiceTitle title='BPR Prabu Mitra'/>
            <InvoiceNo invoice={invoice}/>
            <BillTo invoice={invoice}/>
            <InvoiceItemsTable invoice={invoice} />
            <InvoiceThankYouMsg />
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Halaman ${pageNumber} of ${totalPages}`
            )} fixed />
        </Page>
    </Document>
);

export default Invoice